export const APPROVAL_LINK_TYPE = {
  VIEW: 0,
  EDIT: 1,
  DELETE: 2,
  CREATE: 3,
  DUPLICATE: 4
};

export const getApprovalLink = (TYPE, approvalId = null) => {
  switch (TYPE) {
    case APPROVAL_LINK_TYPE.VIEW:
      return `/approvals/${approvalId}/view`;
    case APPROVAL_LINK_TYPE.EDIT:
      return `/approvals/${approvalId}`;
    case APPROVAL_LINK_TYPE.DELETE:
      return `/approvals/${approvalId}/delete`;
    case APPROVAL_LINK_TYPE.CREATE:
      return `/approvals/new`;
    case APPROVAL_LINK_TYPE.DUPLICATE:
      return `/approvals/new/parent/${approvalId}`;
    default:
      return `/404`;
  }
};
