import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "@lodash";
import PeoplePicker from "../components/PeoplePicker";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useParams } from "react-router";
import withReducer from "store/withReducer";
import reducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useForm, useDeepCompareEffect } from "hooks";
import sizeToHuman from "utils/sizeToHuman";
import * as pathToRegexp from "path-to-regexp";
import { useDropzone } from "react-dropzone";
import {
  getApproval,
  newApproval,
  saveApproval,
  resetApproval,
  importApprovalTemplate,
  importApprovalFromParent,
} from "./store/approvalSlice";
import { userHasPermission, PermissionsList } from "config/permissions";
import Redirect404 from "pages/components/Redirect404";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ScalableTooltip from "./components/ScalableTooltip";
import {
  Button,
  Combobox,
  makeStyles,
  Option,
  Radio,
  RadioGroup,
  shorthands,
  Textarea,
  tokens,
} from "@fluentui/react-components";
import { TimelineApprovalEditDragable } from "pages/components/TimelineEdit/TimelineEdit";

const useStyles = makeStyles({
  combo: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    gridGap: tokens.spacingHorizontalXXS,
  },
});

const ApprovalsNewPage = (props) => {
  const btnGuardarRef = useRef();
  const dispatch = useDispatch();
  const styles = useStyles();
  const approval = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalReducer;
  });

  const [noApproval, setNoApproval] = useState(false);
  const { form, setForm } = useForm(null);
  const routeParams = useParams();

  const changePageToView = (id) => {
    const toPath = pathToRegexp.compile("/approvals/:approvalId/view");
    const newPath = toPath({
      ...routeParams,
      approvalId: "" + id,
    });
    if (newPath !== props.match.url) {
      props.history.push(newPath);
    }
  };

  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const LoginUserHasPermission = (param) => {
    if (param === "new") {
      return userHasPermission(
        LoginUser.profile.permissions,
        PermissionsList.APPROVAL_CREATE
      );
    } else {
      return userHasPermission(
        LoginUser.profile.permissions,
        PermissionsList.APPROVAL_EDIT
      );
    }
  };

  useDeepCompareEffect(() => {
    function updateApprovalState() {
      const { approvalId } = routeParams;

      if (approvalId === "new") {
        if (routeParams.templateId) {
          dispatch(resetApproval());
          dispatch(importApprovalTemplate(routeParams.templateId)).then(
            (action) => {
              if (!action.payload) {
                setNoApproval(true);
              }
            }
          );
        } else if (routeParams.parentId) {
          dispatch(resetApproval());
          dispatch(importApprovalFromParent(routeParams.parentId)).then(
            (action) => {
              if (!action.payload) {
                setNoApproval(true);
              }
            }
          );
        } else {
          dispatch(resetApproval());
          dispatch(newApproval());
        }
      } else {
        dispatch(resetApproval());
        dispatch(getApproval(routeParams.approvalId)).then((action) => {
          if (!action.payload) {
            setNoApproval(true);
          }
        });
      }
    }

    updateApprovalState();
  }, [dispatch, routeParams]);

  useEffect(() => {
    if (
      (approval && !form) ||
      (approval && form && approval?.id !== form?.id)
    ) {
      setForm(approval);
    }
    if (form && form.id && routeParams.parentId) {
      setForm({ ...form, id: null });
    }
  }, [form, approval, setForm,routeParams.parentId]);

  useEffect(() => {
    return () => {
      dispatch(resetApproval());
      setNoApproval(false);
    };
  }, [dispatch]);

  function canBeSubmitted() {
    // se for template, pode ser igual
    if(routeParams.parentId)
      return (form.title.length > 0 && form.steps.every(checkStepMinimumOptionalApprovers))

    return (
      form.title.length > 0 &&
      !_.isEqual(approval, form) &&
      form.steps.every(checkStepMinimumOptionalApprovers)
    );
  }

  /* Start Attachments Management */

  const dropBaseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const dropActiveStyle = {
    borderColor: "#2196f3",
  };

  const dropAcceptStyle = {
    borderColor: "#00e676",
  };

  const dropRejectStyle = {
    borderColor: "#ff1744",
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (files) => handleUploadChange(files),
  });
  const dropStyle = useMemo(
    () => ({
      ...dropBaseStyle,
      ...(isDragActive ? dropActiveStyle : {}),
      ...(isDragAccept ? dropAcceptStyle : {}),
      ...(isDragReject ? dropRejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  function handleUploadChange(e) {
    const files = e;
    if (!files) {
      return;
    }

    let attachments = [...form.attachments];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        attachments.push({
          name: file.name,
          data: `data:${file.type};base64,${btoa(reader.result)}`,
          type: file.type.length ? file.type : "application/octet-stream",
          size: file.size,
        });

        attachments = attachments.map((attachment, index) => {
          return { ...attachment, index: index };
        });

        setForm({ ...form, attachments: attachments });
      };

      reader.onerror = () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          icon: "error",
          title: "Ocorreu um erro",
          text: "Desculpe mas não conseguimos ler todos os ficheiros!",
          footer:
            '<a href="#">Se o erro persistir contacte o fornecedor da aplicação</a>',
        });
      };
    });
  }
  const removeAttachment = (index) => {
    let attachments = [...form.attachments];

    attachments = attachments
      .slice(0, index)
      .concat(attachments.slice(index + 1));

    attachments = attachments.map((attachment, index) => {
      return { ...attachment, index: index };
    });

    setForm({ ...form, attachments: attachments });
  };

  /* End Attachments Management */

  const handleSelectedConnoisseurs = (items) => {
    setForm({ ...form, connoisseurs: items });
  };



  /* Start Approvers Management */

  const checkStepMinimumOptionalApprovers = (step) => {
    const count = step.approvers.filter((x) => x.mandatory === "0").length ?? 0;
    return count >= step.neededApprovers;
  };
  /* // End Approvers Management */



  
  /* Start Custom Fields */
  const handleFieldValueChange = (value, index) => {
    let fields = form.fields.map((field, i) => {
      if (index === i) {
        return { ...field, value: value };
      } else {
        return { ...field };
      }
    });

    setForm({ ...form, fields: fields });
  };
  console.log(form?.fields);
  const renderCustomField = (field) => {
    if (field.type === "checkbox") {
      /*return (
        <select className="form-control" value={field.value} onChange={(e) => handleFieldValueChange(e, field.index)}>
          {field.values.split('|').map((value, index) => {
            return <option key={index} value={value}>{value}</option>
          })}
        </select>
      )*/
      return (
        <div className={styles.combo}>
          <label>{field.label}</label>
          <ul className={styles.tagsList}>
            {form.fields[field.index].value!== '' &&
              form.fields[field.index].value.split("; ").map((option, i) => (
                <li key={option}>
                  <Button
                    size="small"
                    shape="circular"
                    appearance="primary"
                    iconPosition="after"
                    //onClick={() => onTagClick(option, i)}
                  >
                    {option}
                  </Button>
                </li>
              ))}
          </ul>
          <Combobox
            placeholder="Selecione uma opção"
            multiselect
            selectedOptions={
              form.fields[field.index].value
                ? form.fields[field.index].value.split("; ")
                : []
            }
            onOptionSelect={(e, data) =>
              handleFieldValueChange(
                data.selectedOptions ? data.selectedOptions.join("; ") : "",
                field.index
              )
            }
          >
            {field.values.split("|").map((value) => {
              return (
                <Option key={value} value={value}>
                  {value}
                </Option>
              );
            })}
          </Combobox>
        </div>
      );
    } else if (field.type === "text") {
      return (
        <input
          className="form-control"
          value={field.value}
          onChange={(e) => handleFieldValueChange(e.target.value, field.index)}
        />
      );
    } else if (field.type === "textarea") {
      return (
        <Textarea
          resize="vertical"
          className="form-control"
          rows={6}
          value={field.value}
          onChange={(e) => handleFieldValueChange(e.target.value, field.index)}
        />
      );
    } else if (field.type === "radio") {
      return (
        <RadioGroup
          layout="horizontal"
          value={field.value}
          className="flex flex-row flex-wrap"
          onChange={(_, data) =>
            handleFieldValueChange(data.value, field.index)
          }
        >
          {field.values.split("|").map((value, index) => {
            return <Radio key={index} label={value} value={value} />;
          })}
        </RadioGroup>
      );
    }
  };

  /* // End Custom Fields // */

  const isObtainingData = () => {
    return (
      ((!approval ||
        (approval && routeParams.approvalId !== approval.id?.toString())) &&
        routeParams.approvalId !== "new") ||
      !form
    );
  };

  const downloadAnexo = (anexo) => {
    window.Dashmix.block("state_loading", `#attachment${anexo.id}`);
    axios({
      url: axios.defaults.baseURL + anexo.downloadUrl,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: anexo.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", anexo.name);
        link.click();
      })
      .finally(() => {
        window.Dashmix.block("state_normal", `#attachment${anexo.id}`);
      });
  };

  /* Render */
  if (noApproval || !LoginUserHasPermission(routeParams.approvalId)) {
    return <Redirect404 />;
  }

  if (isObtainingData()) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h2 className="flex-sm-fill fw-regular mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h2>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn sticky-div">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h2 className="flex-sm-fill fw-normal mt-2 mb-0 mb-sm-2">
              {form.title ? form.title : "Nova Aprovação"}
            </h2>

            {routeParams.approvalId !== "new" && (
              <button
                className="btn btn-alt-warning me-2"
                onClick={() => changePageToView(routeParams.approvalId)}
              >
                Voltar
              </button>
            )}

            <button
              ref={btnGuardarRef}
              disabled={!canBeSubmitted()}
              className="btn btn-primary"
              onClick={() => {
                btnGuardarRef.current.setAttribute("disabled", "disabled");
                btnGuardarRef.current.innerHTML =
                  '<i class="fa fa-spinner fa-spin"></i> A guardar';
                dispatch(saveApproval(form)).then((action) => {
                  if (action.payload) {
                    setForm(action.payload);
                    changePageToView(action.payload.id);
                  }
                });
              }}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
      <div className="content animated fadeIn">
        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Informação básica</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Informação que permite identificar este pedido de aprovação
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <div className="form-group mb-4">
                  <label className="form-label">Título</label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    value={form.title}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Descrição</label>
                  <style>{".ql-container { min-height: 200px }"}</style>
                  <ReactQuill
                    value={form.description}
                    modules={{
                      toolbar: [
                        [{ font: [] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        [("blockquote", "code-block")],

                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],

                        ["link", "image"],

                        ["clean"],
                      ],
                    }}
                    onChange={(e) => {
                      setForm({ ...form, description: e });
                    }}
                  />
                </div>

                <div className="form-check form-switch mb-4">
                  <label className="form-label">Fluxo Escalável</label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) =>
                      setForm({ ...form, scalable: !form.scalable })
                    }
                    checked={form.scalable}
                  />
                  <ScalableTooltip />
                </div>

                <div className="form-group mb-4">
                  <label className="form-label">Anexos</label>
                  <div {...getRootProps({ style: dropStyle })}>
                    <input {...getInputProps()} />
                    <p>Clique aqui ou arraste um ficheiro para adicionar</p>
                  </div>
                </div>
                <div className="row push">
                  {form.attachments.map((x) => {
                    return (
                      <div
                        key={x.index}
                        id={`attachment${x.id}`}
                        className={`block block-mode-loading-download options-container fx-overlay-zoom-out col-xl-4 col-md-6 col-12`}
                      >
                        <div className="options-item block block-rounded bg-body mb-0">
                          <div className="block-content text-center">
                            <p className="mb-2 overflow-hidden">
                              <i className="fa fa-fw fa-4x fa-file-alt text-black"></i>
                            </p>
                            <p className="fw-semibold mb-0 text-break">
                              {x.name}
                            </p>
                            <p className="fs-sm text-muted">
                              {x.size === -1
                                ? "O Anexo expirou"
                                : sizeToHuman(x.size, true)}
                            </p>
                          </div>
                        </div>
                        <div className="options-overlay rounded-lg bg-white-50">
                          <div className="options-overlay-content">
                            <div className="btn-group">
                              {x.id && x.size !== -1 && (
                                <button
                                  className="btn btn-sm btn-dark"
                                  onClick={() => downloadAnexo(x)}
                                >
                                  <i className="fa fa-download text-white me-1"></i>
                                  Descarregar
                                </button>
                              )}
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  removeAttachment(x.index);
                                }}
                              >
                                <i className="fa fa-trash text-white me-1"></i>
                                Apagar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {form.fields.length > 0 && (
          <div className="block block-rounded">
            <div className="block-content">
              <h2 className="content-heading pt-0">Informação Adicional</h2>
              <div className="row push">
                <div className="col-lg-4">
                  <p className="text-muted">
                    Complemente a informação adicional solicitada que é
                    pertinente para o pedido de aprovação
                  </p>
                </div>
                <div className="col-lg-8 col-xl-5">
                  {form.fields.map((field, index) => {
                    return (
                      <div key={`field${index}`} className="form-group mb-4">
                        <label className="form-label">{field.name}</label>
                        <div className="input-group bg-white">
                          {renderCustomField(field)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Conhecimento do Pedido</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Mencione as pessoas que devem ter conhecimento do pedido de
                  aprovação, mas que não tem ação na tomada de decisão
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <PeoplePicker
                  handleSelectedItems={(items) => {
                    handleSelectedConnoisseurs(items);
                  }}
                  selectedItems={form.connoisseurs}
                  allowGuest={true}
                />
              </div>
            </div>
          </div>
        </div>

        <h2 className="content-heading m-0">Fluxo de Aprovação</h2>
        <TimelineApprovalEditDragable form={form} setForm={setForm} />
      </div>
    </React.Fragment>
  );
};

export default withReducer("approvalsModule", reducer)(ApprovalsNewPage);

