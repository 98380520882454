import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { userLogin, userLoginAzure } from "store/userSlice";
import { loginRequest, AzureEnabled, DemoVersion } from "environmentConfig";
import { Link } from "react-router-dom";
import moment from "moment";

const LoginPage = () => {
  const dispatch = useDispatch();
  const { instance } = useMsal();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginUser = () => {
    dispatch(userLogin({ username, password })).then((e) => {
      if (e.payload && e.payload.access_token) {
        window.location.reload();
      }
    });
  };

  const loginUserOffice365 = () => {
    instance.loginPopup(loginRequest).then((e) => {
      if (e.account) {
        instance.setActiveAccount(e.account);
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: e.account,
          })
          .then((response) => {
            dispatch(userLoginAzure({ token: response.idToken })).then((e) => {
              if (e.payload && e.payload.access_token) {
                window.location.reload();
              }
            });
          });
      }
    });
  };

  const demoLogin = (username, password) => {
    setUsername(username);
    setPassword(password);
    dispatch(userLogin({ username, password })).then((e) => {
      if (e.payload && e.payload.access_token) {
        window.location.reload();
      }
    });
  };

  return (
    <main id="main-container">
      <div
        class="bg-image"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "0 0",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url('/assets/media/various/background_login.jpg')`,
        }}
      >
        <div class="row g-0 bg-primary-op">
          <div class="hero-static col-md-6 d-flex align-items-center bg-body-extra-light">
            <div class="p-3 w-100">
              <div class="row g-0 justify-content-center">
                <div class="col-sm-8 col-xl-6">
                  <div class="mb-3 text-center">
                    <img
                      className="w-100"
                      src="/assets/media/logos/logo_colorful@4x.png"
                      alt="logo"
                    />
                    <p class="text-uppercase fw-bold fs-sm text-muted">
                      Autenticar
                    </p>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control form-control-alt"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Endereço de Email"
                      />
                    </div>
                    <div className="form-group mb-4">
                      <input
                        type="password"
                        className="form-control form-control-alt"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Palavra-Passe"
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="w-100 btn btn-lg btn-success mb-2"
                        onClick={() => {
                          loginUser();
                        }}
                      >
                        <i className="fa fa-fw fa-sign-in-alt me-1"></i>{" "}
                        Autenticar
                      </button>

                      {AzureEnabled && (
                        <button
                          type="button"
                          className="w-100 btn btn-lg btn-primary"
                          onClick={() => {
                            loginUserOffice365();
                          }}
                        >
                          <i className="fab fa-fw fa-microsoft me-1"></i>{" "}
                          Autenticar com Microsoft 365
                        </button>
                      )}

                      {DemoVersion && (
                        <div className="mt-4">
                          <p className="text-uppercase font-w700 font-size-sm text-muted mb-2">
                            Iniciar como:
                          </p>

                          <button
                            type="button"
                            className="btn btn-block btn-sm btn-hero btn-secondary"
                            onClick={() => {
                              demoLogin(
                                "demo-admin@emptytroubles.pt",
                                "demo-admin"
                              );
                            }}
                          >
                            demo-admin
                          </button>

                          <button
                            type="button"
                            className="btn btn-block btn-sm btn-hero btn-secondary"
                            onClick={() => {
                              demoLogin(
                                "demo-user@emptytroubles.pt",
                                "demo-user"
                              );
                            }}
                          >
                            demo-user
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-12 my-3">
                  <Link to={`/resetPassword`}>
                    Esqueceu-se da sua password?
                  </Link>
                </div>
              </div>

              {/* <div className="row text-center">
                  <div className="col-12">
                    <img
                      src={`${process.env.PUBLIC_URL}assets/media/logos/emptytroubles.png`}
                      alt=""
                      width="100px"
                    />
                  </div>
                </div> */}
            </div>
          </div>

          <div class="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
            <div class="p-3">
              <h4 className="text-white-75 fw-semibold">Flows made simple.</h4>
              <h3 className="text-white fw-semibold m-1"></h3>
              <p class="display-6 fw-bold text-white mb-3">
                Plataforma para Pedidos de Aprovação
              </p>
              <p class="fs-lg fw-semibold text-white-75 mb-0">
                Copyright &copy; {moment().year()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
