import React from "react";
import {
  Avatar,
  Menu,
  MenuTrigger,
  MenuList,
  MenuPopover,
  MenuItem,
  MenuDivider,
  Button,
  makeStyles,
  Persona,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { userLogout } from "store/userSlice";

import {
  ArrowExitFilled,
  PersonAccountsFilled,
  PersonFilled
} from "@fluentui/react-icons";

import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px",
  },
});

const UserSwitcher = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((root) => {
    return root.userReducer;
  });
  const { instance, accounts } = useMsal();


  const history = useHistory();
  const location = useLocation();

  const gotoProfile = () => {
    const profilePath = '/profile';
    if (location.pathname !== profilePath) {
      history.push(profilePath);
    }
  }

  return (
    <div className="d-flex">

      <button type="button" class="btn btn-dual" onClick={()=>window.location.reload()}>
        <i class="fa fa-fw fa-refresh"></i>
      </button>

      
      <Menu>
        <MenuTrigger>
          <Button appearance="subtle">
            <Persona
              textAlignment={"center"}
              name={user?.user?.name}
              size={"medium"}
              avatar={{
                image: {
                  src: user?.user?.profilePhoto,
                },
              }}
              secondaryText={user?.user?.email}
            />
          </Button>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>

            <MenuItem
              icon={<PersonFilled />}
              onClick={gotoProfile}
            >
              Meu Perfil
            </MenuItem>

            <MenuDivider />

            <MenuItem

              icon={<ArrowExitFilled />}
              onClick={() => {
                dispatch(userLogout()).then((x) => {
                  if (accounts.length) {
                    instance.logoutRedirect();
                  }
                });
              }}
            >
              Terminar Sessão
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>

  );
};

export default UserSwitcher;
