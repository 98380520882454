import { combineReducers } from '@reduxjs/toolkit';
import approvalReducer from './approvalSlice';
import approvalsReducer from './approvalsSlice';
import approvalTreeReducer from './approvalTreeSlice';

const reducer = combineReducers({
	approvalReducer,
	approvalsReducer,
	approvalTreeReducer,
});

export default reducer;