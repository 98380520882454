import React from 'react';
import DetailStep from "./DetailStep";


// TIMELINE of View Approval 
export default function ApprovalDetailStepList({ approval, user, guestToken }) {

  /* RENDER SECTION */

  return (
    <ul className="timeline">
      {approval.steps.map((step, index) => {
        let mandatories = step.approvers.filter(
          // eslint-disable-next-line
          (x) => x.mandatory == true
        );
        let optionals = step.approvers.filter(
          // eslint-disable-next-line
          (x) => x.mandatory == false
        );

        return (
          <DetailStep key={step.id} index={index} user={user} approval={approval} step={step} mandatories={mandatories} optionals={optionals} guestToken={guestToken} />
        );
      })}
    </ul>
  );
}
