import React, { useEffect, useState, useRef } from "react";
import _ from "@lodash";
import PeoplePicker from "../components/PeoplePicker";
import { useParams } from "react-router";
import withReducer from "store/withReducer";
import reducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useDeepCompareEffect } from "hooks";
import * as pathToRegexp from "path-to-regexp";
import {
  deleteApprovalTemplate,
  getApprovalTemplate,
  newApprovalTemplate,
  resetApprovalTemplate,
  saveApprovalTemplate,
} from "./store/approvalTemplateSlice";
import { MultiSelect } from "pages/components/MultiSelect";
import Redirect404 from "pages/components/Redirect404";
import FieldModal from "./components/FieldModal";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import fieldTypeToHuman from "utils/typesToString";

import axios from "axios";


import {TimelineTemplateEditDragable} from "../components/TimelineEdit/TimelineEdit"

const ApprovalTemplatesNewPage = (props) => {
  const btnGuardarRef = useRef();
  const dispatch = useDispatch();
  const approvalTemplate = useSelector(({ approvalTemplatesModule }) => {
    return approvalTemplatesModule.approvalTemplateReducer;
  });

  const loginUser = useSelector((root) => {
    return root.userReducer;
  });

  const [noApprovalTemplate, setNoApprovalTemplate] = useState(false);
  const { form, setForm } = useForm(null);
  const routeParams = useParams();

  // const companiesList = loginUser.user.companies;
  const [companiesList, setCompaniesList] = useState([])

  const fetchCompanyList = async () => {
    // console.log("[fetchCompanyList] called")
    const response = await axios.get(`/approval-template/companyList`);
    if (response.status === 200) {
      setCompaniesList(response.data)
    }
  }

  useEffect(() => {
    fetchCompanyList()
  }, [])



  const changePageToView = (id) => {
    const toPath = pathToRegexp.compile("/approvals/templates/:templateId");
    const newPath = toPath({
      ...routeParams,
      templateId: "" + id,
    });
    if (newPath !== props.match.url) {
      props.history.push(newPath);
    }
  };

  useDeepCompareEffect(() => {
    function updateApprovalTemplateState() {
      const { templateId } = routeParams;
      if (templateId === "new") {
        dispatch(newApprovalTemplate());
      } else {
        dispatch(getApprovalTemplate(routeParams.templateId)).then((action) => {
          if (!action.payload) {
            setNoApprovalTemplate(true);
          }
        });
      }
    }

    updateApprovalTemplateState();
  }, [dispatch, routeParams]);

  useEffect(() => {
    if (
      (approvalTemplate && !form) ||
      (approvalTemplate && form && approvalTemplate.id !== form.id)
    ) {
      setForm({...approvalTemplate});
    }
  }, [form, approvalTemplate, setForm]);

  useEffect(() => {
    return () => {
      dispatch(resetApprovalTemplate());
      setNoApprovalTemplate(false);
    };
  }, [dispatch]);

  function canBeSubmitted() {
    return form.templateTitle.length > 0 && !_.isEqual(approvalTemplate, form);
  }

  const handleSelectedSharedUsers = (items) => {
    setForm({ ...form, sharedUsers: items });
  };

  const handleSelectedCompanies = (items) => {
    setForm({ ...form, companies: items });
  };

  const handleSelectedConnoisseurs = (items) => {
    setForm({ ...form, templateConnoisseurs: items });
  };




  /* Start Custom Fields */

  const [isModalOpen, setIsModalOpen] = useState(false);
  const fieldModalRef = useRef(null);
  const openModal = (field, index) => {
    if (fieldModalRef.current) {
      if (field) {
        fieldModalRef.current.openModalEdit(
          index,
          field.name,
          field.type,
          field.values
        );
      } else {
        fieldModalRef.current.openModal();
      }
      setIsModalOpen(true);
    }
  };

  const addCustomField = async (index, fieldName, fieldType, fieldValues) => {
    if (fieldName) {
      if (index != null) {
        let templateFields = form.templateFields.map((field, i) => {
          if (index === i) {
            return {
              ...field,
              name: fieldName,
              type: fieldType,
              values: fieldValues,
            };
          } else {
            return { ...field };
          }
        });

        setForm({ ...form, templateFields: templateFields });
      } else {
        let templateFields = [...form.templateFields];

        templateFields.push({
          name: fieldName,
          type: fieldType,
          values: fieldValues,
          index: 0,
        });

        templateFields = templateFields.map((field, index) => {
          return { ...field, index: index };
        });

        setForm({ ...form, templateFields: templateFields });
      }
      closeModal();
    }
  };

  // Method to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const removeCustomField = (field, index) => {
    let templateFields = [...form.templateFields];

    templateFields = templateFields
      .slice(0, index)
      .concat(templateFields.slice(index + 1));

    templateFields = templateFields.map((field, index) => {
      return { ...field, index: index };
    });

    setForm({ ...form, templateFields: templateFields });
  };

  const moveCustomField = (oldIndex, newIndex) => {
    let templateFields = [...form.templateFields];

    const element = templateFields[oldIndex];
    templateFields = templateFields
      .slice(0, oldIndex)
      .concat(templateFields.slice(oldIndex + 1));

    templateFields = templateFields
      .slice(0, newIndex)
      .concat([element])
      .concat(templateFields.slice(newIndex));

    templateFields = templateFields.map((field, index) => {
      return { ...field, index: index };
    });
    setForm({ ...form, templateFields: templateFields });
  };

  /* // End Custom Fields // */

  const isObtainingData = () => {
    return (
      ((!approvalTemplate ||
        (approvalTemplate &&
          routeParams.templateId !== approvalTemplate.id?.toString())) &&
        routeParams.templateId !== "new") ||
      !form
    );
  };

  /* Save Button */
  const saveButtonClick = () => {
    const oldText = btnGuardarRef.current.innerHTML;
    btnGuardarRef.current.setAttribute("disabled", "disabled");
    btnGuardarRef.current.innerHTML =
      '<i class="fa fa-spinner fa-spin"></i> A guardar';

    dispatch(saveApprovalTemplate(form)).then((action) => {
      if (action.payload) {
        setForm(action.payload);
        changePageToView(action.payload.id);
        window.location.reload()
      } else {
        btnGuardarRef.current.innerHTML = oldText;
        btnGuardarRef.current.removeAttribute("disabled");
      }
    });
  };
  /* Use Model Button */
  const useModelButtonClick = () => {
    props.history.push(`/approvals/new/template/${routeParams.templateId}`);
  };
  /* delete model button */
  const deleteModelButtonClick = () => {
    dispatch(deleteApprovalTemplate(routeParams.templateId)).then(() => {
      props.history.push("/approvals/templates/");
    });
  };

  const isOwner = () => {
    return approvalTemplate.user.id === loginUser.user.id;
  };

  /* Render */
  if (
    noApprovalTemplate ||
    (approvalTemplate && !isOwner() && routeParams.templateId !== "new")
  ) {
    return <Redirect404 />;
  }

  if (isObtainingData()) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h2 className="flex-sm-fill fw-normal mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h2>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn sticky-div">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h2 className="flex-sm-fill fw-normal mt-2 mb-0 mb-sm-2">
              {form.templateTitle ? form.templateTitle : "Novo Modelo"}
            </h2>
            <button
              style={{
                display:
                  canBeSubmitted() || routeParams.templateId === "new"
                    ? "none"
                    : "inline",
              }}
              onClick={deleteModelButtonClick}
              className="btn btn-alt-danger me-2"
            >
              Apagar modelo
            </button>

            <button
              style={{
                display:
                  canBeSubmitted() || routeParams.templateId === "new"
                    ? "none"
                    : "inline",
              }}
              disabled={canBeSubmitted() || routeParams.templateId === "new"}
              onClick={useModelButtonClick}
              className="btn btn-alt-primary me-2"
            >
              Usar modelo
            </button>

            <button
              style={{ display: !canBeSubmitted() ? "none" : "inline" }}
              disabled={!canBeSubmitted()}
              className="btn btn-primary"
              ref={btnGuardarRef}
              onClick={saveButtonClick}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
      <div className="content animated fadeIn">
        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Informação sobre o Modelo</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Informação que permite identificar este modelo de aprovação
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <div className="form-group mb-4">
                  <label className="form-label">Título</label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setForm({ ...form, templateTitle: e.target.value })
                    }
                    value={form.templateTitle}
                  />
                </div>

                <div className="form-check form-switch mb-4">
                  <label className="form-label">Modelo é Público</label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) => setForm({ ...form, public: !form.public })}
                    checked={form.public}
                  />
                </div>

                <div
                  className="form-group mb-4"
                  style={{ display: form.public ? "none" : "block" }}
                >
                  <label className="form-label">Compartilhar com Pessoas</label>
                  <PeoplePicker
                    handleSelectedItems={(items) => {
                      handleSelectedSharedUsers(items);
                    }}
                    selectedItems={form.sharedUsers}
                    exclude={[loginUser]}
                  />
                </div>

                <div
                  className="form-group form-label"
                  style={{ display: form.public ? "none" : "block" }}
                >
                  <MultiSelect
                    title="Compartilhar com Empresas"
                    data={companiesList}
                    idColumn="id"
                    valueColumn="name"
                    handleSelectedItems={handleSelectedCompanies}
                    selectedItems={form.companies}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block block-rounded shadow-64">
          <div className="block-content">
            <h2 className="content-heading pt-0">Informação Adicional</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Adicione campos de preenchimento adicional que sejam
                  pertinentes para os pedidos com base neste modelo
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                {form.templateFields.length > 0 && (
                  <Table noNativeElements>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>Nome</TableHeaderCell>
                        <TableHeaderCell>Tipo</TableHeaderCell>
                        <TableHeaderCell>Valores</TableHeaderCell>
                        <TableHeaderCell>Ações</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {form.templateFields.map((item, index) => (
                        <TableRow
                          key={item.name}
                          onDragStart={(e) => {
                            console.log("dragStart", item.index);
                            e.dataTransfer.setData("text/plain", item.index);
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          onDrop={(e) => {
                            const oldIndex = Number(
                              e.dataTransfer.getData("text/plain")
                            );
                            moveCustomField(oldIndex, item.index);
                          }}
                          draggable
                        >
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            <TableCellLayout>
                              {fieldTypeToHuman(item.type)}
                            </TableCellLayout>
                          </TableCell>
                          <TableCell>{item.values}</TableCell>
                          <TableCell>
                            <button
                              type="button"
                              className="btn btn-warning me-1"
                              onClick={(e) => openModal(item, index)}
                            >
                              <i className="fa fa-fw fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger me-1"
                              onClick={(e) => removeCustomField(item, index)}
                            >
                              <i className="fa fa-fw fa-trash"></i>
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                <Button
                  className="mt-2"
                  appearance="primary"
                  onClick={() => openModal(null, null)}
                >
                  Adicionar Campo
                </Button>
                <FieldModal
                  ref={fieldModalRef}
                  onAddField={addCustomField}
                  isOpen={isModalOpen}
                  closeModal={closeModal}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Conhecimento do Pedido</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">
                  Mencione as pessoas que devem ter conhecimento do pedido de
                  aprovação resultante deste modelo, mas que não tem ação na
                  tomada de decisão
                </p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <PeoplePicker
                  handleSelectedItems={(items) => {
                    handleSelectedConnoisseurs(items);
                  }}
                  selectedItems={form.templateConnoisseurs}
                />
              </div>
            </div>
          </div>
        </div>

        <h2 className="content-heading m-0">Fluxo de Aprovação</h2>
        <TimelineTemplateEditDragable form={form} setForm={setForm} />

      </div>
    </React.Fragment>
  );
};

export default withReducer(
  "approvalTemplatesModule",
  reducer
)(ApprovalTemplatesNewPage);
