import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppWrapper from "./AppWrapper";
import reportWebVitals from "./reportWebVitals";
import Provider from "react-redux/es/components/Provider";
import store from "./store";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./environmentConfig";
import "./custom.css";
import {
  createDarkTheme,
  createLightTheme,
  FluentProvider,
  webLightTheme,
} from "@fluentui/react-components";
import { initializeIcons } from "@fluentui/react/lib/Icons";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const msalInstance = new PublicClientApplication(msalConfig);

const theme = {
  10: "#020306",
  20: "#111726",
  30: "#172544",
  40: "#19305E",
  50: "#193C78",
  60: "#164993",
  70: "#0C56B0",
  80: "#2963BF",
  90: "#4770C5",
  100: "#5F7ECC",
  110: "#748CD2",
  120: "#879AD8",
  130: "#9AA9DE",
  140: "#ACB8E4",
  150: "#BFC7EA",
  160: "#D1D6F0",
};

const lightTheme = {
  ...createLightTheme(theme),
};

const darkTheme = {
  ...createDarkTheme(theme),
};

darkTheme.colorBrandForeground1 = theme[110]; // use brand[110] instead of brand[100]
darkTheme.colorBrandForeground2 = theme[120]; // use brand[120] instead of brand[110]

initializeIcons();
const container = document.getElementById("root");

const root = ReactDOMClient.createRoot(container);

// Initial render: Render an element to the root.
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <FluentProvider theme={lightTheme}>
          <AppWrapper />
        </FluentProvider>
      </MsalProvider>
    </Provider>
  </BrowserRouter>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
