import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "@lodash";
import { getApprovalTemplates } from "./store/approvalTemplatesSlice";
import reducer from "./store";
import withReducer from "store/withReducer";

const ApprovalTemplatesPage = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((root) => {
    return root.userReducer;
  });

  const approvalTemplates = useSelector(({ approvalTemplatesModule }) => {
    return approvalTemplatesModule.approvalTemplatesReducer.approvalTemplates;
  });

  const searchText = useSelector(({ approvalTemplatesModule }) => {
    return approvalTemplatesModule.approvalTemplatesReducer.searchText;
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(approvalTemplates);

  useEffect(() => {
    dispatch(getApprovalTemplates()).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(
        _.filter(approvalTemplates, (item) =>
          item.templateTitle.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setData(approvalTemplates);
    }
  }, [approvalTemplates, searchText]);


  const renderList = (type="") => {
    let listData = data;

    if(type === "user"){
      listData = data.filter((x) => x.user.id === user.user.id)
    }
    else if (type === "shared") {
      listData = data.filter((x) => x.user.id !== user.user.id && x.public === false && 
        x.companies.length===0);
    }
    else if (type === "company"){
      listData = data.filter( (x) => x.user.id !== user.user.id  && x.public === false &&
        x.companies.length>0);   
    }
    else if (type === "public"){
      listData = data.filter((x) => x.user.id !== user.user.id  && x.public === true);
    }



    return (
      <React.Fragment>
      {listData.map( x => {
        return (
          <div className="col-md-6 col-xl-4" key={x.id}>
          <Link 
            to={type==="user" ?  `/approvals/templates/${x.id}`:`/approvals/new/template/${x.id}`} 
            className="block block-rounded block-link-shadow"
            >
            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
              <div>
                <p className="fs-lg fw-semibold mb-0 text-break">
                  {x.templateTitle}
                </p>
                <p className="text-muted mb-0">
                  <span className="fw-bold">
                    
                  </span>
                  {type === "user" && (
                    <span className="fw-bold">
                      {x.public ? "Público" : "Privado"}
                    </span>
                  )}
                  {type !== "user" && (
                    <span>
                      Criado por:{" "}
                      <span className="fw-bold">
                        {x.createdBy}
                      </span>
                    </span>
                  )}
                  {/*  */}
  
                </p>
              </div>
              <div className="ms-3 item">
                <Link
                  to={`/approvals/new/template/${x.id}`}
                  className="btn btn-primary"
                >
                  Usar
                </Link>
              </div>
            </div>
          </Link>
        </div>
        )
      })}
      </React.Fragment>
    );
  }


  if (loading) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h2 className="flex-sm-fill fw-normal mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h2>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (!data || (data && data.length === 0)) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h2 className="flex-sm-fill mt-2 mb-0 mb-sm-2">
                Modelos de Aprovação
              </h2>
              <nav className="flex-sm-00-auto ms-sm-3 mt-3 mt-sm-0">
                <Link to="/approvals/templates/new" className="btn btn-primary">
                  Criar novo modelo
                </Link>
              </nav>
            </div>
          </div>
        </div>
        <div className="content animated fadeIn">
          <h2 className="content-heading">Sem resultados</h2>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h2 className="flex-sm-fill mt-2 mb-0 mb-sm-2">
                Modelos de Aprovação
              </h2>
              <nav className="flex-sm-00-auto ms-sm-3 mt-3 mt-sm-0">
                <Link to="/approvals/templates/new" className="btn btn-primary">
                  Criar novo modelo
                </Link>
              </nav>
            </div>
          </div>
        </div>

        <div className="content animated fadeIn">
          <h2 className="content-heading">Modelos criados por si</h2>

          <div className="row row-deck">
            {renderList("user")}
          </div>

          <h2 className="content-heading">
            Modelos partilhados consigo
          </h2>

          <div className="row row-deck">
            {renderList("shared")}
          </div>
          
          <h2 className="content-heading">
            Modelos de empresa
          </h2>

          <div className="row row-deck">
          {renderList("company")}
          </div>

          <h2 className="content-heading">
            Modelos públicos de outros utilizadores
          </h2>

          <div className="row row-deck">
            {renderList("public")}
          </div>
        
        </div>
      </React.Fragment>
    );
  }
};

export default withReducer(
  "approvalTemplatesModule",
  reducer
)(ApprovalTemplatesPage);
