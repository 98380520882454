import * as React from 'react';

import { TagPicker } from '@fluentui/react/lib/Pickers';
import { useId } from '@fluentui/react-hooks';
import { multiSelectStyle, multiSelectStyleSmall } from 'config/templateTheme';

export const MultiSelect = (props) => {
	const pickerId = useId('inline-picker');

	//from props
	let data = props.data ?? [];
	const title = props.title ?? ""
	const idColumn = props.idColumn ?? "id"
	const valueColumn = props.valueColumn ?? "name"
	const handleSelectedItems = props.handleSelectedItems ?? undefined

	const handleCreateGenericItem = props.handleCreateGenericItem ?? undefined
	const handleOnValidateInput = props.handleOnValidateInput ?? undefined
	
	const doNotLayer = props.doNotLayer ?? true;

	const style = props.styleSizeSmall ? multiSelectStyleSmall : multiSelectStyle

	//consts
	const pickerSuggestionsProps = {
		suggestionsHeaderText: 'Resultados',
		noResultsFoundText: 'Nenhum resultado encontrado',
	};
	
	
	const listContainsTagList = (tag, tagList = []) => {
		if (!tagList || !tagList.length || tagList.length === 0) {
			return false;
		}
		return tagList.some(compareTag => compareTag[idColumn] === tag[idColumn]);
	};
	
	const filterSuggestedTags = (filterText, tagList) => {
		return filterText ? data.filter(
			tag => tag[valueColumn].toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
		)
		: [];
	};
		
	const getTextFromItem = (item) => item[valueColumn];

	const onItemsChange = (items) => {
		handleSelectedItems(items);
	};

	return (
		<div>
			{title && 
				<label htmlFor={pickerId}>{title}</label>
			}
			<TagPicker
				removeButtonAriaLabel="Remover"
				selectionAriaLabel="Item Selecionado"
				onResolveSuggestions={filterSuggestedTags}
				getTextFromItem={getTextFromItem}
				pickerSuggestionsProps={pickerSuggestionsProps}
				onChange={onItemsChange}
				//itemLimit={1}
				// this option tells the picker's callout to render inline instead of in a new layer
				pickerCalloutProps={{ doNotLayer: doNotLayer }}				

				inputProps={{
					id: pickerId,
				}}
				
				className='form-control'
				
				styles={style}


				selectedItems={props.selectedItems}
				
				createGenericItem={handleCreateGenericItem}
				onValidateInput={handleOnValidateInput}
			/>
		</div>
		);
};
		