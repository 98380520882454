import React, { useEffect, useState } from "react";
import { Persona } from "@fluentui/react-components";
import { Link, useParams } from "react-router-dom";
import _ from "@lodash";
import withReducer from "store/withReducer";
import reducer from "./store";
import moment from "moment";
import "moment/locale/pt";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovalsByTag,
  setApprovalsSearchText,
} from "./store/approvalsSlice";
import { userHasPermission, PermissionsList } from "config/permissions";
import { deleteApproval } from "./store/approvalSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ApprovalStatusBadge from "utils/components/ApprovalStatusBadge";
import { APPROVAL_LINK_TYPE, getApprovalLink } from "utils/link-generator";

const ApprovalsSentPage = () => {
  const dispatch = useDispatch();
  const approvals = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalsReducer.approvalsByTag;
  });
  const searchText = useSelector(
    ({ approvalsModule }) => approvalsModule.approvalsReducer.searchText
  );

  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(approvals);

  const routeParams = useParams();
  const { tagId } = routeParams;

  const tag = LoginUser.tags.find(
    (tag) => tag.id.toString() === tagId.toString()
  );

  useEffect(() => {
    dispatch(getApprovalsByTag(tagId)).then(() => setLoading(false));
  }, [dispatch, tagId]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(
        _.filter(approvals, (item) =>
          item.title.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setData(approvals);
    }
  }, [approvals, searchText]);

  const renderTableBody = () => {
    if (loading) {
      return (
        <React.Fragment>
          <div className="bg-body-light animated fadeIn">
            <div className="content content-full">
              <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                  <i className="fa fa-circle-notch fa-spin text-primary"></i>A
                  obter dados do servidor...
                </h1>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (!data || (data && data.length === 0)) {
      return (
        <React.Fragment>
          <td colSpan={6} className="p-0">
            <div className="bg-body-light animated fadeIn">
              <div className="content content-full">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                  <h4 className="flex-sm-fill font-size-h4 font-w400 mt-2 mb-0 mb-sm-2">
                    Sem resultados
                  </h4>
                </div>
              </div>
            </div>
          </td>
        </React.Fragment>
      );
    } else {
      return data.map((approval) => {
        return (
          <tr key={approval.id}>
            <td className="text-center">{approval.id}</td>
            <td className="fw-semibold">
              <Link to={getApprovalLink(APPROVAL_LINK_TYPE.VIEW, approval.id)}>
                {approval.title}
              </Link>
            </td>
            <td>
              <ApprovalStatusBadge status={approval.status} />
            </td>
            <td className="text-center">
              {moment(approval.createdAt).calendar()}
            </td>
            <td>
              <Persona
                textAlignment={"center"}
                avatar={{
                  image: {
                    src: approval?.user?.profilePhoto,
                  },
                }}
                secondaryText={approval?.user?.email}
                name={approval?.user?.name}
                size={"medium"}
              />
            </td>
            <td className="d-flex flex-column flex-md-row">
              {userHasPermission(
                LoginUser.profile.permissions,
                PermissionsList.APPROVAL_VIEW
              ) && (
                <Link
                  className="btn btn-sm btn-primary m-1"
                  to={getApprovalLink(APPROVAL_LINK_TYPE.VIEW, approval.id)}
                >
                  <i className="fa fa-eye"></i> Ver
                </Link>
              )}

              {approval.status === "DRAFT" &&
                userHasPermission(
                  LoginUser.profile.permissions,
                  PermissionsList.APPROVAL_EDIT
                ) && (
                  <Link
                    className="btn btn-sm btn-warning m-1"
                    to={getApprovalLink(APPROVAL_LINK_TYPE.EDIT, approval.id)}
                  >
                    <i className="fa fa-edit"></i> Editar
                  </Link>
                )}

              {approval.status === "DRAFT" &&
                userHasPermission(
                  LoginUser.profile.permissions,
                  PermissionsList.APPROVAL_DELETE
                ) && (
                  <button
                    className="btn btn-sm btn-danger m-1"
                    onClick={() => {
                      confirmDelete(approval.id);
                    }}
                  >
                    <i className="fa fa-fw fa-trash"></i> Eliminar
                  </button>
                )}
            </td>
          </tr>
        );
      });
    }
  };

  const confirmDelete = (id) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "info",
      title: "Tem a certeza que pertende eliminar esta Aprovação?",
      confirmButtonText: "Eliminar",
      denyButtonText: "Cancelar",
      showDenyButton: true,
      width: "800px",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteApproval(id)).then((res) => {
          if (!res.error) {
            dispatch(getApprovalsByTag(tagId));
          }
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              {tag?.name}
            </h1>
            <nav className="flex-sm-00-auto ml-sm-3 mt-3 mt-sm-0">
              {userHasPermission(
                LoginUser.profile.permissions,
                PermissionsList.APPROVAL_CREATE
              ) && (
                <Link to="/approvals/new" className="btn btn-primary">
                  Criar nova aprovação
                </Link>
              )}
            </nav>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        <h2 className="content-heading">Todos os pedidos</h2>

        <div className="form-group row items-push mb-0">
          <div className="col-sm-6 col-xl-3">
            <div className="input-group">
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
              <input
                type="text"
                className="form-control border-start-0"
                onChange={(e) => {
                  dispatch(setApprovalsSearchText(e));
                }}
                value={searchText}
                placeholder="Procure pelo um título..."
              />
            </div>
          </div>
        </div>
        <div className="block block-rounded">
          <div className="block-content p-0">
            <div className="table-responsive">
              <table className="table table-striped table-vcenter mb-0">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Título</th>
                    <th>Estado</th>
                    <th className="text-center">Data</th>
                    <th>Pedido por</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>{renderTableBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withReducer("approvalsModule", reducer)(ApprovalsSentPage);
