import React, { useState, useEffect } from 'react'
import { Badge, makeStyles, Text } from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";


import axios from "axios";
import { apiUrl } from "environmentConfig";

import { Persona } from "@fluentui/react-persona";


import Redirect404 from "pages/components/Redirect404";
import moment from "moment";
import "moment/locale/pt";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import sizeToHuman from "utils/sizeToHuman";
import Tree from "pages/components/Tree/Tree";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    DirectionalHint,
    TooltipDelay,
    TooltipHost,
} from "@fluentui/react";

import ApprovalDetailStepList from "./components/DetailStepList";
import { getApprovalGuest, resetApproval } from './store/approvalSlice';
import Footer from 'layout/Footer';




// dup from ApprovalsDetailPage
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "auto",
        height: "auto",
        boxSizing: "border-box",
        "> *": {
            textOverflow: "ellipsis",
        },
        "> :not(:first-child)": {
            marginTop: "0px",
        },
        "> *:not(.ms-StackItem)": {
            flexShrink: 1,
        },
        "> :not(:last-child)": {
            marginRight: "10px",
        },
    },
    item: {
        height: "auto",
        width: "auto",
        flexShrink: 1,
    },
});



function GuestApprovalDetailPage({ id, token }) {
    axios.defaults.baseURL = apiUrl;
    axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

    const dispatch = useDispatch();


    const approval = useSelector(({ approvalsModule }) => {
        return approvalsModule.approvalReducer;
    });

    const [noApproval, setNoApproval] = useState(false);

    async function updateApprovalState() {
        let action = await dispatch(getApprovalGuest({
            approvalId: id,
            guestToken: token
        }));

        if (action.payload == undefined) {
            setNoApproval(true);
        }
    }

    useEffect(() => {
        updateApprovalState();

        return () => {
            dispatch(resetApproval());
            setNoApproval(false);
        }
    }, [dispatch]);



    const styles = useStyles();


    if (!approval && noApproval === false) {
        return (
            <React.Fragment>
                <div className="bg-body-light animated fadeIn">
                    <div className="content content-full">
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                            <h2 className="flex-sm-fill mt-2 mb-0 mb-sm-2">
                                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                                obter dados do servidor...
                            </h2>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    if (noApproval) {
        return <Redirect404 />;
    }


    const getApprovalStatusObject = (status) => {
        switch (status) {
            case "DRAFT":
                return {
                    title: "Rascunho",
                    backgroundClass: "bg-secondary",
                    textClass: "text-secondary",
                    alertClass: "alert-secondary",
                    icon: "fa-2x me-1 fa fa-clock",
                };
            case "RUNNING":
                return {
                    title: "A Decorrer",
                    backgroundClass: "bg-warning",
                    textClass: "text-warning",
                    alertClass: "alert-warning",
                    icon: "fa-2x me-1 fa fa-spinner fa-spin",
                };
            case "APPROVED":
                return {
                    title: "Aprovada",
                    backgroundClass: "bg-success",
                    textClass: "text-success",
                    alertClass: "alert-success",
                    icon: "fa-2x me-1 fa fa-check",
                };
            case "REJECTED":
                return {
                    title: "Rejeitada",
                    backgroundClass: "bg-danger",
                    textClass: "text-danger",
                    alertClass: "alert-danger",
                    icon: "fa-2x me-1 fa fa-times",
                };
            case "CANCELED":
                return {
                    title: "Cancelado",
                    backgroundClass: "bg-danger",
                    textClass: "text-danger",
                    alertClass: "alert-danger",
                    icon: "fa-2x me-1 fa fa-times",
                };
            default:
                return {
                    title: "Outro",
                    backgroundClass: "bg-secondary",
                    textClass: "text-secondary",
                    alertClass: "alert-secondary",
                    icon: "fa-2x me-1 fa fa-clock",
                };
        }
    };

    const checkApprovalConcluded = () => {
        return ["APPROVED", "REJECTED", "CANCELED"].includes(approval.status);
    };

    const downloadAnexo = async (anexo) => {
        // TODO : pode ser mais genrico com a pagina "normal" , pois o codigo é quase igual.
        window.Dashmix.block("state_loading", `#attachment${anexo.id}`);
        
        const downloadUrl = `${axios.defaults.baseURL}/guest/${id}${anexo.downloadUrl}?token=${token}`

        try {

            if (anexo.externalId) {
                const response = await axios({
                    url: downloadUrl,
                    method: "GET",
                    responseType: "json",
                  }).catch((err) => {
                    throw err;
                  });
          
                  const { accessToken, downloadLink } = response.data;
                  const downloadFile = await axios({
                    url: downloadLink,
                    method: "POST",
                    headers: { Authorization: "Bearer " + accessToken },
                    responseType: "blob",
                  }).catch((err) => {
                    throw err;
                  });
          
                  const url = window.URL.createObjectURL(
                    new Blob([downloadFile.data], { type: anexo.type })
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", anexo.name);
                  link.click();

            }
            else {
                const response = await axios({
                    url: downloadUrl,
                    method: "GET",
                    responseType: "blob",
                }).catch((err) => {
                    throw err;
                });

                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: anexo.type })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", anexo.name);
                link.click();
            }

        } catch (error) {
            console.error(error);
        }
        finally {
            window.Dashmix.block("state_normal", `#attachment${anexo.id}`);
        }

    }

    // const approvalTree = []

    // const approvalTreeItemClick = (item) => {
    //     if (!item.link) {
    //         const MySwal = withReactContent(Swal);
    //         MySwal.fire({
    //             icon: "error",
    //             title: "Não tem permissão para aceder a esta aprovação",
    //             width: "800px",
    //         });
    //         return;
    //     }

    //     if (item.id === approval.id) {
    //         return;
    //     }

    //     //props.history.push(`/approvals/${item.id}/view`);
    // };

    
    // TODO : ver a questsão dos downloads, Apenas esta a funcionar para Users
    // verificar se convidados podem ser conhecedores. caso sim, fazer alterações a API.
    
    // AQUI:
    // ver apenas etap que está / ou as varias, pensar!

    //se conhecedor, apenas vé estado geral


    return (
        <div id="page-container" className="side-scroll page-header main-content-narrow">
            <GuestHeader />

            <main id="main-container">

                <div className={`bg-body-light shadow animated fadeIn ${getApprovalStatusObject(approval.status).backgroundClass}`}>
                    <div className={`content content-full`}>
                        <div className={`d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center`} >
                            <h2 className="flex-sm-fill mt-2 mb-md-4 mb-sm-2">
                                <Badge
                                    appearance="filled"
                                    shape="rounded"
                                    size="extra-large"
                                    color="brand"
                                >
                                    <Text size={700}>#{approval.id}</Text>
                                </Badge>
                                <span className="badge badge-lg badge-primary"></span>{" "}
                                {approval.title}
                            </h2>

                        </div>
                        <div className="d-flex flex-row flex-wrap">
                            <div className="pt-2 pe-5 d-flex flex-column flex-wrap align-items-start">
                                <label>Autor</label>
                                <Persona
                                    textAlignment={"center"}
                                    name={approval.user?.name}
                                    size={"medium"}
                                    avatar={{
                                        image: {
                                            src: approval.user?.profilePhoto,
                                        },
                                    }}
                                    secondaryText={approval.user?.email}
                                />
                            </div>
                            <div className="pt-2 pe-5 d-flex flex-column flex-wrap align-items-start">
                                <label>Data de Início</label>
                                {moment(approval.createdAt).calendar()}
                            </div>
                        </div>
                    </div>
                </div> {/* end of approval header  */}


                <div className="content animated fadeIn mt-2">
                    <div className={`alert ${getApprovalStatusObject(approval.status).backgroundClass} d-flex align-items-center`}
                        role="alert">
                        <div className="text-white">
                            <i className={`${getApprovalStatusObject(approval.status).icon}`}></i>
                        </div>
                        <div className="ms-1">
                            <p className="mb-0 text-white fw-semibold fs-lg">
                                {getApprovalStatusObject(approval.status).title}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-7 order-4 order-lg-0 ">
                            <div className="block block-rounded block-bordered">
                                <div className="block-header block-header-default">
                                    <h3 className="block-title">Informação</h3>
                                </div>
                                <div className="block-content">
                                    <style>
                                        {
                                            ".ql-container.ql-snow{ border: none !important;} .ql-editor{ padding:0; padding-bottom: 20px }"
                                        }
                                    </style>
                                    <ReactQuill
                                        value={approval.description}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="snow"
                                        style={{ padding: 0, border: 0 }}
                                    />

                                    {approval.fields.map((field) => {
                                        return (
                                            <React.Fragment>
                                                <label>{field.name}</label>
                                                <p>{field.value}</p>
                                            </React.Fragment>
                                        );
                                    })}

                                    {approval.attachments.length > 0 && (
                                        <React.Fragment>
                                            <h4 className="content-heading p-0">Anexos</h4>
                                            <div className="row push">
                                                {approval.attachments.map((x) => {
                                                    return (
                                                        <div
                                                            key={x.index}
                                                            id={`attachment${x.id}`}
                                                            className={`block block-mode-loading-download options-container fx-overlay-zoom-out col-xl-4 col-md-6 col-12`}
                                                        >
                                                            <div className="options-item block block-rounded bg-body mb-0">
                                                                <div className="block-content text-center">
                                                                    <p className="mb-2 overflow-hidden">
                                                                        <i className="fa fa-fw fa-4x fa-file-alt text-black"></i>
                                                                    </p>
                                                                    <p className="fw-semibold mb-0 text-break">
                                                                        {x.name}
                                                                    </p>
                                                                    <p className="fs-sm text-muted">
                                                                        {x.size === -1
                                                                            ? "O Anexo expirou"
                                                                            : sizeToHuman(x.size, true)}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {x.size !== -1 && (
                                                                <div className="options-overlay rounded-lg bg-white-50">
                                                                    <div className="options-overlay-content">
                                                                        <div className="btn-group">
                                                                            <button
                                                                                className="btn btn-sm btn-dark"
                                                                                onClick={() => downloadAnexo(x)}
                                                                            >
                                                                                <i className="fa fa-download text-white me-1"></i>
                                                                                Descarregar
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>


                                            {approval.attachments.length > 0 && checkApprovalConcluded() && (
                                                <div className="alert bg-warning d-flex align-items-center flex-fill text-white">
                                                    Anexos ficam indisponíveis 30 dias após a conclusão da
                                                    aprovação
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                            {/* <div className="block block-rounded">
                            <div className="block-content">
                                <h2 className="content-heading p-0">Aprovações Relacionadas</h2>
                                <Tree
                                    data={approvalTree}
                                    id={approval.id}
                                    itemClick={approvalTreeItemClick}
                                />
                            </div>
                        </div> */}
                        </div>

                        <div className="col-12 col-lg-5">
                            {approval.connoisseurs.length !== 0 && (
                                <div className="block block-rounded block-bordered">
                                    <div className="block-header block-header-default">
                                        <h3 className="block-title">Tomadas de Conhecimento</h3>
                                    </div>
                                    <div className="block-content">
                                        <div className={styles.root}>
                                            {/*TODO: PASSAR AS NOVAS TOLTIPS E A UM COMPONENTE*/}
                                            {approval.connoisseurs.map((connoisseur) => (
                                                <div className={styles.item}>
                                                    <TooltipHost
                                                        tooltipProps={{
                                                            calloutProps: {
                                                                styles: () => {
                                                                    if (connoisseur.knowledgeDate === null) {
                                                                        return {
                                                                            beak: { background: "#e69f17" },
                                                                            beakCurtain: {
                                                                                background: "#e69f17",
                                                                            },
                                                                            calloutMain: {
                                                                                background: "#e69f17",
                                                                            },
                                                                        };
                                                                    } else if (connoisseur.knowledgeDate) {
                                                                        return {
                                                                            beak: { background: "#6f9c40" },
                                                                            beakCurtain: {
                                                                                background: "#6f9c40",
                                                                            },
                                                                            calloutMain: {
                                                                                background: "#6f9c40",
                                                                            },
                                                                        };
                                                                    }
                                                                },
                                                            },
                                                            onRenderContent: () => {
                                                                if (connoisseur.knowledgeDate === null) {
                                                                    return (
                                                                        <div
                                                                            className={"bg-warning"}
                                                                            style={{
                                                                                margin: 0,
                                                                                padding: 10,
                                                                            }}
                                                                        >
                                                                            <span className="h5 text-white">
                                                                                <i className="fa fa-clock me-1"></i> Por
                                                                                tomar conhecimento
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                } else if (connoisseur.knowledgeDate) {
                                                                    return (
                                                                        <div
                                                                            className={"bg-success"}
                                                                            style={{
                                                                                margin: 0,
                                                                                padding: 10,
                                                                            }}
                                                                        >
                                                                            <span className="h5 text-white">
                                                                                <i className="fa fa-check me-1"></i> Tomou
                                                                                conhecimento
                                                                            </span>
                                                                            <span className="d-block text-white">
                                                                                {moment(connoisseur.knowledgeDate).format(
                                                                                    "LLL"
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                            },
                                                        }}
                                                        delay={TooltipDelay.zero}
                                                        directionalHint={DirectionalHint.bottomCenter}
                                                    >
                                                        <Persona
                                                            className="mb-2"
                                                            textAlignment={"center"}
                                                            name={connoisseur?.user?.name ?? connoisseur?.guest?.email}
                                                            size={"medium"}
                                                            secondaryText={
                                                                connoisseur.knowledgeDate !== null
                                                                    ? "Visto"
                                                                    : "Não visto"
                                                            }
                                                            avatar={{
                                                                image: {
                                                                    src: connoisseur?.user?.profilePhoto,
                                                                },
                                                            }}
                                                            presence={
                                                                connoisseur.knowledgeDate !== null
                                                                    ? { status: "available" }
                                                                    : { status: "unknown" }
                                                            }
                                                        />
                                                    </TooltipHost>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {approval.steps.length > 0 && (
                                <div className="block block-rounded block-bordered">
                                    <div className="block-header block-header-default">
                                        <h3 className="block-title">Fluxo de Aprovação</h3>
                                    </div>
                                    <div className="block-content py-0">
                                        <ApprovalDetailStepList approval={approval} guestToken={token} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    )

}

export default GuestApprovalDetailPage


function GuestHeader() {

    return (
        <header id="page-header" className='bg-header-dark'>
            <div className='bg-white-10'>
                <div className="content-header d-flex justify-content-center align-items-center">
                    <span className='h2 mb-0 fw-semibold text-white tracking-wide'>Flow
                        <span className='opacity-75'>Consent</span>
                    </span>
                </div>
            </div>
        </header>
    )
}