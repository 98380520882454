
export var PermissionsList;
(function (PermissionsList) {
    PermissionsList["USER_VIEW"] = "USER_VIEW";
    PermissionsList["USER_CREATE"] = "USER_CREATE";
    PermissionsList["USER_EDIT"] = "USER_EDIT";
    PermissionsList["USER_DELETE"] = "USER_DELETE";

    PermissionsList["APPROVAL_VIEW"] = "APPROVAL_VIEW";
    PermissionsList["APPROVAL_CREATE"] = "APPROVAL_CREATE";
    PermissionsList["APPROVAL_EDIT"] = "APPROVAL_EDIT";
    PermissionsList["APPROVAL_DELETE"] = "APPROVAL_DELETE";
    PermissionsList["APPROVAL_RESPONDE"] = "APPROVAL_RESPONDE";
    PermissionsList["APPROVAL_TEMPLATE"] = "APPROVAL_TEMPLATE";
    PermissionsList["OUTLOOK_ADDIN"] = "OUTLOOK_ADDIN";

    PermissionsList["COMPANY_VIEW"] = "COMPANY_VIEW";
    PermissionsList["COMPANY_CREATE"] = "COMPANY_CREATE";
    PermissionsList["COMPANY_EDIT"] = "COMPANY_EDIT";
    PermissionsList["COMPANY_DELETE"] = "COMPANY_DELETE";
})(PermissionsList || (PermissionsList = {}));


export var PermissionRoutes
(function (PermissionRoutes) {
    PermissionRoutes["/approvals/sent"] = [PermissionsList.APPROVAL_VIEW];
    PermissionRoutes["/approvals/received"] = [PermissionsList.APPROVAL_VIEW];
    PermissionRoutes["/approvals/connoisseur"] = [PermissionsList.APPROVAL_VIEW];
    PermissionRoutes["/approvals/templates"] = [PermissionsList.APPROVAL_TEMPLATE];
    PermissionRoutes["/approvals/templates/:templateId"] = [PermissionsList.APPROVAL_TEMPLATE];
    PermissionRoutes["/approvals/:approvalId"] = [PermissionsList.APPROVAL_CREATE,PermissionsList.APPROVAL_EDIT];
    PermissionRoutes["/approvals/:approvalId/template/:templateId"] = [PermissionsList.APPROVAL_TEMPLATE,PermissionsList.APPROVAL_CREATE];
    PermissionRoutes["/approvals/:approvalId/parent/:parentId"] = [PermissionsList.APPROVAL_CREATE];
    PermissionRoutes["/approvals/:approvalId/view"] = [PermissionsList.APPROVAL_VIEW];

    PermissionRoutes["/users"] = [PermissionsList.USER_VIEW];
    PermissionRoutes["/users/:userId"] = [PermissionsList.USER_CREATE,PermissionsList.USER_EDIT];
    PermissionRoutes["/users/:userId/view"] = [PermissionsList.USER_VIEW];

    PermissionRoutes["/company"] = [PermissionsList.COMPANY_VIEW];
    PermissionRoutes["/company/:companyId"] = [PermissionsList.COMPANY_CREATE,PermissionsList.COMPANY_EDIT];
    PermissionRoutes["/company/:companyId/view"] = [PermissionsList.COMPANY_VIEW];

})(PermissionRoutes || (PermissionRoutes = {}));


//might be usefull later
export const userHasPermission = (userPermissions, permission) => {
    userPermissions = userPermissions.map((perm) => perm.name);

    if(userPermissions.length === 0 ) {
        return false;
    }
    if(permission === null || permission === undefined) {
        return true;
    }

    return userPermissions.includes(permission);
}

/*

import { matchPath } from 'react-router-dom';
import _ from '../@lodash';


const getPathnameToRoutename = (pathname) => {
    let routename = null;
    _.forEach(PermissionRoutes, (value,key) => {
        if(!!matchPath(pathname,key)){
            routename = key;
        }
    });

    return routename;
}

 export const checkPermissions = (user, location) => {
    console.group("checking permition for locations:")
    console.log(location.pathname);
    console.log(user.profile.permissions);
    console.groupEnd();
    
    const routename = getPathnameToRoutename(location.pathname);
    console.log(routename);
    const requiredPermissions = PermissionRoutes[routename] ?? [];

    if (requiredPermissions.length === 0) {
        console.log("no permissions");
        return true;
    }

    console.log(requiredPermissions);


    const userPermissions = user.profile.permissions.map((perm) => perm.name) ?? null;

    const authorized = requiredPermissions.some((requiredPermission) => userPermissions.includes(requiredPermission))     
    console.log("pass:",authorized);
    
    return authorized;
}; */
