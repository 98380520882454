import moment from "moment";

export const filterArchived = (approvals) => {
    const ArchivedStatus = ["APPROVED", "REJECTED", "CANCELED"]
    const thirtyDaysAgo = moment().subtract(30, 'days');


    const active = [];
    const archived = [];


    approvals?.forEach(obj => {
      if (moment(obj.createdAt).isBefore(thirtyDaysAgo) && ArchivedStatus.includes(obj.status)) {
        archived.push(obj);
      } else {
        active.push(obj);
      }
    });


    return {
        active,
        archived
    }
  }
