import React from "react";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router";

function Redirect404() {
  const location = useLocation();
  const redirect = { pathname: "/404", state: { prevURL: location.pathname } };
  return <Redirect to={redirect} />;
}

export default Redirect404;
