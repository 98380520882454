import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from "react-redux";

import { toggleLoading } from "layout/templateFunctions"
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { deviceDetect, getUA } from 'react-device-detect';
import { sleep } from 'utils/sleep';
import _ from "@lodash";



export default function ProfilePage() {

    const user = useSelector((root) => {
        return root.userReducer.user;
    });


    return (
        <div>

            {/* Hero */}
            <div className="bg-image" style={{ backgroundImage: `url('/assets/media/photos/photo17@2x.jpg')` }}>
                <div className="bg-black-75">
                    <div className="content content-full">
                        <div className="py-3 text-center">

                            <img className="img-avatar img-avatar96 img-avatar-thumb" src={user?.profilePhoto ?? "/assets/media/avatars/avatar10.jpg"} alt="" />

                            {/* <h1 className="fw-bold my-2 text-white">Edit Account</h1> */}
                            <h2 className="h4 fw-bold text-white-75 mb-0 mt-2">
                                {user.name}
                            </h2>

                        </div>
                    </div>
                </div>
            </div>
            {/* END Hero */}



            <div className="content content-full content-boxed">
                <div className="block block-rounded">
                    <div className="block-content">
                        <h2 className="content-heading pt-0 mb-3">
                            <i className="fa fa-fw fa-user-circle text-muted me-1"></i> Informações
                        </h2>

                        <label>Nome</label>
                        <p>{user.name}</p>

                        <label>Email</label>
                        <p>{user.email}</p>

                        {user.companies && user.companies.length > 0 && (
                            <div>
                                <label>Empresas</label>
                                <p>
                                    {user.companies.map((company) => {
                                        return (
                                            <p className="m-0 text-primary-dark">
                                                {company.name}
                                            </p>
                                        );
                                    })}
                                </p>
                            </div>
                        )}

                    </div>
                </div>

                <NotificationsForm />



            </div>

        </div>
    )
}




const LOCALSTORAGEKEY = "APP_PUSH_UUID"

const NotificationsForm = () => {
    const [applicationServerKey, setApplicationServerKey] = useState("")

    async function getKey() {
        if (applicationServerKey && applicationServerKey.length > 0)
            return applicationServerKey;

        const keyRes = await axios.get("/notifications/key")
            .catch(err => { console.error(err); throw err })

        if (keyRes.status === 200) {
            setApplicationServerKey(keyRes.data)
            return keyRes.data
        }
        else
            throw "no key error"
    }

    const [serverSettings, setServerSettings] = useState({
        active: false,
        start: false,
        end: false,
        chat: false
    })

    const [form, setForm] = useState({
        active: false,
        start: false,
        end: false,
        chat: false
    })

    const setFormIn = (key, value) => {
        setForm(prev => ({ ...prev, [key]: value }))
    }

    const activate = async () => {
        const newVal = !form.active
        setFormIn("active", newVal);

        const checkOk = await check()
        if (checkOk == false) {
            setFormIn("active", false);
        }
        else if (newVal) {
            setFormIn("start", true)
            setFormIn("end", true)
            setFormIn("chat", true)
        }
        else {
            setForm({
                active: false,
                start: false,
                end: false,
                chat: false,
            })
        }
    }


    async function getInitState() {
        const res = await axios.get(`/notifications/settings/${checkUniqueId()}`).catch(err => console.error(err))
        if (res?.status === 200)
            setServerSettings(res.data)
    }

    async function saveSettings() {
        const res = await axios.post(`/notifications/settings/${checkUniqueId()}`, form).catch(err => console.error(err))
        if (res.status === 201) {
            setServerSettings(res.data)
            setForm(res.data)
        }
    }

    useEffect(() => {
        setForm(serverSettings ?? form)
    }, [serverSettings])



    // flags to check everything is OK for notifications
    const [flags, setFlags] = useState({
        workerActive: false,
        notificationsActive: false,
        subscriptionActive: false,
        error: ""
    })

    const setFlagIn = (key, value) => {
        setFlags(prev => ({ ...prev, [key]: value }))
    }



    async function check() {
        toggleLoading("state_loading", "notificationSettings")
        await sleep(200)

        try {
            checkUniqueId()

            const notificationsRes = await notificationPermission(setFlagIn)
            setFlagIn("notificationsActive", notificationsRes)

            const key = await getKey()

            const worker = await navigator.serviceWorker.getRegistration()
            if (worker == undefined) {
                throw "no_worker"
            }
            else {
                setFlagIn("workerActive", true)
                const subscribeRes = await subscribeUser(worker, key)
                setFlagIn("subscriptionActive", subscribeRes)
            }

            return true

        } catch (error) {

            if (error == "notification_denied") {
                // alert("Necessita de aceitar a permissão nas definições do browser")
                setFlagIn("error", "Necessita de aceitar a permissão de Notificações nas definições do browser")
            }
            else if (error == "notification_undefined") {
                // alert("Permissão de Notificações não foi confirmada")
                setFlagIn("error", "Permissão de Notificações não foi confirmada")

            }

            else if (error == "no_worker") {
                setFlagIn("error", "Notificações não estão disponíveis")
            }

            else {
                setFlagIn("error", "Serviço em manutenção")
            }


            return false
        }
        finally {
            toggleLoading("state_normal", "notificationSettings")
        }
    }

    useEffect(() => {
        check()
        getInitState()
    }, [])

    const hasErrors = (flags.error && flags.error.length > 0)

    return (
        <div className="block block-rounded" id='notificationSettings'>
            <div className="block-content">
                <h2 className="content-heading pt-0 mb-3">
                    <i className="fa-solid fa-fw fa-bell text-muted me-1"></i> Notificações
                </h2>

                <div className="mb-4">

                    {hasErrors && <>
                        <small className='d-block text-danger'>{flags.error}</small>
                    </>}

                    <div className="form-check form-switch mb-1">
                        <input className="form-check-input" type="checkbox" value="" id="switch-active" name="switch-active"
                            checked={hasErrors ? false : form.active}
                            onChange={activate}
                            disabled={hasErrors}
                        />
                        <label className="form-check-label" htmlFor="switch-active">Ativas</label>
                    </div>

                    <div className='mx-3'>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" value="" id="switch-approval-start" name="switch-approval-start"
                                disabled={!form.active || hasErrors}
                                checked={form.start}
                                onChange={() => setFormIn("start", !form.start)}
                            />
                            <label className="form-check-label" htmlFor="switch-approval-start">Início de Fluxo</label>
                        </div>

                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" value="" id="switch-approval-end" name="switch-approval-end"
                                disabled={!form.active || hasErrors}
                                checked={form.end}
                                onChange={() => setFormIn("end", !form.end)}
                            />
                            <label className="form-check-label" htmlFor="switch-approval-end">Fim de Fluxo</label>
                        </div>

                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" value="" id="switch-approval-chat" name="switch-approval-chat"
                                disabled={!form.active || hasErrors}
                                checked={form.chat}
                                onChange={() => setFormIn("chat", !form.chat)}
                            />
                            <label className="form-check-label" htmlFor="switch-approval-chat">Comentários de Fluxo</label>
                        </div>
                    </div>

                    {_.isEqual(serverSettings, form) == false &&
                        <div className='mt-3'>
                            <button className='btn btn-primary btn-sm' onClick={saveSettings}>Guardar Alterações</button>
                        </div>
                    }

                    {/* <pre>{JSON.stringify(form)}</pre> */}

                    {/* 
                    <hr />

                    <p>Notifications : {flags.notificationsActive ? "true" : "false"}</p>
                    <p>worker Active : {flags.workerActive ? "true" : "false"}</p>
                    <p>subscription Active : {flags.subscriptionActive ? "true" : "false"}</p>

                    <p>uuid : {localStorage.getItem(LOCALSTORAGEKEY)}</p>

                    <hr />
                    <p>{JSON.stringify(flags.error)}</p> 
                    */}

                </div>

            </div>
        </div>
    )
}



function checkUniqueId() {
    const uuid = localStorage.getItem(LOCALSTORAGEKEY);
    if (!uuid || uuid.length <= 0) {
        localStorage.setItem(LOCALSTORAGEKEY, uuidv4())
    }
    return localStorage.getItem(LOCALSTORAGEKEY)
}

async function notificationPermission() {

    if (Notification.permission == "denied") {
        throw "notification_denied"
    }
    else if (Notification.permission == "default") {
        // pode pedir
        const perm = await Notification.requestPermission()
        if (perm === "default") {
            throw "notification_undefined"
        }
        else if (perm == "denied") {
            throw "notification_denied"
        }
        else {
            return true
        }
    }
    else {
        return true
    }

}




const subscribeUser = async (swReg, applicationServerKey) => {
    try {

        const subscription = await swReg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(applicationServerKey)
        });

        // console.log(subscription)

        const deviceInfo = getDeviceInfo();
        const deviceUuid = checkUniqueId()

        // Send the subscription to your server
        const response = await axios.post(`/notifications/subscribe`, { subscription, deviceInfo, deviceUuid })
            .catch(err => {
                console.log(err)
                throw err
            })

        if (response.status === 200 || response.status === 201) {
            return true
        }
        else {
            console.log(response)
            throw response.data
        }


    } catch (err) {
        console.error('Failed to subscribe the user: ', err);
        throw err
    }
};



/* UTILS */
function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    // @ts-expect-error
    return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}


function getDeviceInfo() {
    return deviceDetect(getUA)

    // return { 
    //   userAgent: navigator.userAgent, 
    //   platform: navigator.platform,
    // };
}
