// import React from 'react'

// Dashmix.layout('side_overlay_toggle');
// Dashmix.layout('side_overlay_open');
// Dashmix.layout('side_overlay_close');




// export default function SideOverlay() {
//   return (
//     <>
//     <div id="page-overlay" onClick={()=>window.Dashmix.layout('side_overlay_close')}></div>
//     <aside id="side-overlay">
//       <div className="bg-image" style={{backgroundImage: `url('/assets/media/various/bg_side_overlay_header.jpg')`}}>
//         <div className="bg-primary-op">
//           <div className="content-header">

//           </div>
//         </div>
//       </div>

//       <div className="content-side">
//         <div className="block block-transparent pull-x pull-t mb-0">

//           <div className="block-content tab-content overflow-hidden">
//             <div className="tab-pane pull-x fade fade-up show active" id="so-settings" role="tabpanel" aria-labelledby="so-settings-tab" tabIndex="0">
//               <div className="block mb-0">

//                 <div className="block-content block-content-sm block-content-full bg-body">
//                   <span className="text-uppercase fs-sm fw-bold">Color Themes</span>
//                 </div>
//                 <div className="block-content block-content-full">
//                   <div className="row g-sm text-center">
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-default" data-toggle="theme" data-theme="default" href="#">
//                         Default
//                       </a>
//                     </div>
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-xwork" data-toggle="theme" data-theme="/assets/css/themes/xwork.min.css" href="#">
//                         xWork
//                       </a>
//                     </div>
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-xmodern" data-toggle="theme" data-theme="/assets/css/themes/xmodern.min.css" href="#">
//                         xModern
//                       </a>
//                     </div>
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-xeco" data-toggle="theme" data-theme="/assets/css/themes/xeco.min.css" href="#">
//                         xEco
//                       </a>
//                     </div>
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-xsmooth" data-toggle="theme" data-theme="/assets/css/themes/xsmooth.min.css" href="#">
//                         xSmooth
//                       </a>
//                     </div>
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-xinspire" data-toggle="theme" data-theme="/assets/css/themes/xinspire.min.css" href="#">
//                         xInspire
//                       </a>
//                     </div>
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-xdream" data-toggle="theme" data-theme="/assets/css/themes/xdream.min.css" href="#">
//                         xDream
//                       </a>
//                     </div>
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-xpro" data-toggle="theme" data-theme="/assets/css/themes/xpro.min.css" href="#">
//                         xPro
//                       </a>
//                     </div>
//                     <div className="col-4 mb-1">
//                       <a className="d-block py-3 text-white fs-sm fw-semibold bg-xplay" data-toggle="theme" data-theme="/assets/css/themes/xplay.min.css" href="#">
//                         xPlay
//                       </a>
//                     </div>
//                     <div className="col-12">
//                       <a className="d-block py-3 bg-body-dark fw-semibold text-dark" href="be_ui_color_themes.html">All Color Themes</a>
//                     </div>
//                   </div>
//                 </div>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </aside>
//     </>

//   )
// }






import React, { createContext, useState, useContext, useEffect } from 'react';

// Create Context
const SideOverlayContext = createContext();

// Create Provider Component
export const SideOverlayProvider = ({ children }) => {
  const [content, setContent] = useState(null);
  const [header, setHeader] = useState(<></>);

  const openOverlay = (content, header) => {
    setContent(content);
    if (header)
      setHeader(header)
    window.Dashmix.layout('side_overlay_open');
  };

  const closeOverlay = () => {
    window.Dashmix.layout('side_overlay_close');
    setContent(null);
    setHeader(<></>)
  };

  return (
    <SideOverlayContext.Provider value={{ content, header, openOverlay, closeOverlay }}>
      <SideOverlay />
      {children}
    </SideOverlayContext.Provider>
  );
};

// Custom hook to use the SideOverlayContext
export const useSideOverlay = () => useContext(SideOverlayContext);


const SideOverlay = () => {
  const { content, header, closeOverlay } = useSideOverlay();

  return (
    <>
      <div id="page-overlay" style={{ backgroundColor: "transparent" }} onClick={closeOverlay}></div>
      <aside id="side-overlay" className='pwa-ios-bottom'>
        <div className="bg-image" style={{ backgroundImage: `url('/assets/media/various/bg_side_overlay_header.jpg')` }}>
          <div className="bg-primary-op">
            <div className="content-header">
              {header}

              {/* <button type="button" className="btn btn-light" onClick={closeOverlay}>
                <i className="fa fa-times"></i>
              </button> */}
              <a className="ms-auto text-white" href="#" onClick={closeOverlay}>
                <i className="fa fa-xl fa-times-circle"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="content-side">
          <div className="block block-transparent pull-x pull-t mb-0">
            {content}
          </div>
        </div>
      </aside>
    </>
  );
};

export default SideOverlay;



/*
  const { openOverlay } = useSideOverlay();




    <button onClick={() => {
       openOverlay(
        <div style={{width: "500px"}}>
          <h2>Dynamic Content</h2>
          <p>This content was passed from a deeply nested component.</p>

          <input className="form-control" />
        </div>
      );

    }}> ABRIR </button>
  */