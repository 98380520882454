import React from "react";
import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import _ from "@lodash";

function TableBodyLoading({ rows, columns }) {
  return (
    <React.Fragment>
      {_.range(rows).map((k) => (
        <tr key={k}>
          {_.range(columns).map((x) => (
            <td key={x}>
              <Skeleton>
                <SkeletonItem size={16} />
              </Skeleton>
            </td>
          ))}
        </tr>
      ))}
    </React.Fragment>
  );
}

export default TableBodyLoading;
