import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import axios from "axios";
  
  import Swal from "sweetalert2";
  import withReactContent from "sweetalert2-react-content";

  export const getApprovalTree = createAsyncThunk(
    "approvals/getApprovalTree",
    async (approvalId) => {
      const response = await axios.get(`/approval/${approvalId}/getApprovalTree`);
      const data = await response.data;
  
      return data === undefined ? null : data;
    }
  );

  
  const approvalTreeSlice = createSlice({
    name: "approvalTree",
    initialState: null,
    reducers: {},
    extraReducers: {
        [getApprovalTree.fulfilled]: (state, action) => action.payload,
        [getApprovalTree.rejected]: (state, action) => error(action.error.message),
    },
  });
    
  export default approvalTreeSlice.reducer;
  

  const error = (message) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: message,
        width: "800px",
    })
  }