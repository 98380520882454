import React, { useState, useEffect } from 'react'
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from 'react-router-dom'


const Footer = () => {
  const history = useHistory();

  const MySwal = withReactContent(Swal);


  const [licenseData, setLicenseData] = useState(null)

  const getData = async () => {
    const response = await axios.get(`/license`)
      .catch(err => {
        MySwal.fire({
          icon: "error",
          title: "Ocorreram um ou mais erros",
          html: "<p>Não foi possível obter os dados</p>",
          width: "800px",
        });
        throw err
      })

    if (response.status == 200) {
      setLicenseData(response.data.LICENSE)
    }
  }

  useEffect(() => {
    getData()
    return () => setLicenseData(null)
  }, [])


  const goToInfo = () => {
    history.push(`/info`);
  }

  return (
    <footer id="page-footer" className="bg-body-light pwa-ios-bottom">
      <div className="content  py-0">
        <div className="row fs-sm">
          <div className="col-sm-4 order-sm-2 mb-1 mb-sm-0 text-center text-sm-end">
            Crafted by{" "}
            <a
              className="fw-semibold"
              href="https://www.empty.pt"
              target="_blank"
              rel="noreferrer"
            >
              EMPTY
            </a>
          </div>

          <div className="col-sm-4 order-sm-1 text-center text-sm-start">
            <a
              className="fw-semibold"
              href="https://www.empty.pt"
              target="_blank"
              rel="noreferrer"
            >
              {/* FlowConsent 1.2 */}
              FlowConsent {process.env.REACT_APP_VERSION}
            </a>{" "}
            &copy; <span data-toggle="year-copy"></span>

            {/* Licensed to {licenseData?.LICENSE_EMITED_TO} */}
          </div>

          <div className="col-sm-4 order-sm-1 text-center" onClick={goToInfo}>
            Licensed to {" "}
            <span className='fw-semibold text-primary'>
              {licenseData?.LICENSE_EMITED_TO}
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;