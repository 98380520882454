import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


export const getApproval = createAsyncThunk(
  "approvals/getApproval",
  async (approvalId) => {
    const response = await axios.get(`/approval/${approvalId}`);
    const data = await response.data.approval;

    return data === undefined ? null : data;
  }
);


export const importApprovalTemplate = createAsyncThunk(
  "approvals/importApprovalTemplate",
  async (approvalTemplateId) => {
    const response = await axios.get(
      `/approval-template/${approvalTemplateId}`
    );
    const data = await response.data.approvalTemplate;

    return data === undefined ? null : {...data, templateId: +approvalTemplateId};
  }
);


export const importApprovalFromParent = createAsyncThunk(
  "approvals/importApprovalFromParent",
  async (parentId) => {
    const response = await axios.get(`/approval/${parentId}`);


    const data = await response.data.approval;

    return data === undefined ? null : data;
  }
);



export const saveApproval = createAsyncThunk(
  "approvals/saveApproval",
  async (approvalData) => {
    try {
      if (!approvalData.id) {
        const response = await axios.post(`/approval`, approvalData);
        const data = await response.data;

        return data;
      } else {
        const response = await axios.put(
          `/approval/${approvalData.id}`,
          approvalData
        );
        const data = await response.data;

        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const cancelApproval = createAsyncThunk("approvals/cancelApproval",
  async (id, thunkAPI) => {

    const request = await axios.post(`/approval/${id}/cancel`)
    if(request.status !== 201){
      thunkAPI.rejectWithValue()
    }

    return request.data;
  }
)

export const deleteApproval = createAsyncThunk("approvals/deleteApproval",
    async (id) => {
        await axios.delete(`/approval/${id}`);
    }
)

export const publishApproval = createAsyncThunk(
  "approvals/publishApproval",
  async (approvalId) => {
    const response = await axios.put(`/approval/${approvalId}/publish`);
    const data = await response.data.approval;

    if (response.data.errors.length > 0) {
      const errors = response.data.errors
        .map((x) => {
          return x.message;
        })
        .join("<br/>");
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: errors,
        width: "800px",
      });
    }

    return data === undefined ? null : data;
  }
);

export const sendStepResponse = createAsyncThunk(
  "approvals/sendStepResponse",
  async ({ stepId, responseValue, responseText, responseFields }) => {
    await axios.put(`/approval-step/${stepId}/response`, {
      response: responseValue,
      responseText,
      responseFields

    });
  }
);

export const acknowledgeApproval = createAsyncThunk(
  "approvals/acknowledgeApproval",
  async (approvalId) => {
    const response = await axios.put(`/approval/${approvalId}/acknowledge`);
    const data = await response.data.approval;

    return data === undefined ? null : data;
  }
);


export const getApprovalGuest = createAsyncThunk(
  "approvals/getApprovalGuest",
  async ({approvalId, guestToken}) => {
    const response = await axios.get(`/guest/${approvalId}?token=${guestToken}`);
    const data = await response.data.approval;

    return data === undefined ? null : data;
  }
);

export const sendStepResponseGuest = createAsyncThunk(
  "approvals/sendStepResponseGuest",
  async ({ approval ,stepId, responseValue, responseText, responseFields, guestToken}) => {
    await axios.put(`/guest/${approval.id}/step/${stepId}/response?token=${guestToken}`, {
      response: responseValue,
      responseText,
      responseFields
    });
  }
);



const approvalSlice = createSlice({
  name: "approval",
  initialState: null,
  reducers: {
    resetApproval: () => null,
    newApproval: {
      reducer: (state, action) => action.payload,
      prepare: (event) => ({
        payload: {
          title: "",
          description: "",
          attachments: [],
          steps: [],
          fields: [],
          connoisseurs: [],
        },
      }),
    },
  },
  extraReducers: {
    [getApproval.fulfilled]: (state, action) => action.payload,
    [getApproval.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
    [importApprovalTemplate.fulfilled]: (state, action) => {
      let connoisseurs = action.payload.templateConnoisseurs.map((x) => {
        return { user: x.user };
      });

      let fields = action.payload.templateFields.map((x) => {
        return {
          name: x.name,
          value: "",
          type: x.type,
          values: x.values,
          index: x.index
        };
      });

      let steps = action.payload.templateSteps.map((x) => {
        if(x.approvalTemplateStepField && Array.isArray(x.approvalTemplateStepField)){
          // x.approvalTemplateStepField = x.approvalTemplateStepField.forEach(element => delete element.id)
          x.approvalStepField = x.approvalTemplateStepField;
          x.approvalTemplateStepField = x.approvalTemplateStepField.forEach(element => delete element.id)
        }
        return x
      });

      let title = action.payload.templateTitle ?? "";

      return {
        title: title,
        description: "",
        attachments: [],
        steps: steps,
        fields: fields,
        connoisseurs: connoisseurs,
        templateId: action.payload.templateId
      };
    },
    [importApprovalTemplate.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },

    //
    [importApprovalFromParent.fulfilled]: (state, action) => {

      if(action == null){
        return newApproval;
      }

      let connoisseurs = action.payload.connoisseurs.map((x) => {
        return { user: x.user };
      });

      let fields = action.payload.fields.map((x) => {
        return {
          name: x.name,
          value: x.value,
          type: x.type,
          values: x.values,
          index: x.index
        };
      });

      let steps = action.payload.steps.map((x) => {

        const approvers = x.approvers.map((x) => {
          return {
            mandatory: x.mandatory,
            user: x.user ?? null,
            guest: x.guest ? { email: x.guest.email} : null , 
          }
        })

        if(x.approvalStepField && Array.isArray(x.approvalStepField)){
          x.approvalStepField = x.approvalStepField.map( ({index, type, name, values}) => ({index, type, name, values}) )
        }

        return{
          "index": x.index,
          "name": x.name,
          "approvers": approvers, 
          "neededApprovers": x.neededApprovers,
          "type": x.type,
          "approvalStepField": x.approvalStepField,
        }
      });

      let attachments = action.payload.attachments.map(x => {
        return {
          "name": x.name,
          "data": x.data,
          "type": x.type,
          "size": x.size,
          "index": x.index,
          "id" : x.id,
          "downloadUrl": x.downloadUrl
        }
      });

      return {
        title: action.payload.title ?? "",
        description: action.payload.description ?? "",
        attachments: attachments ?? [],
        steps: steps ?? [],
        fields: fields ?? [],
        connoisseurs: connoisseurs ?? [],
        parent : action.payload.id ?? null,

        templateUsed: action.payload?.templateUsed ? {id :  action.payload.templateUsed.id} :  null
      };
    },
    [importApprovalFromParent.rejected]: (state, action) => error(action.error.message),

    [saveApproval.fulfilled]: (state, action) => action.payload,
    [saveApproval.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
    [publishApproval.fulfilled]: (state, action) => action.payload,
    [publishApproval.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },

    [cancelApproval.fulfilled]: (state,action) => action.payload,
    [cancelApproval.rejected]: (state,action) => error(action.error.message),
    [deleteApproval.fulfilled]: (state, action) => { },
    [deleteApproval.rejected]: (state, action) => error(action.error.message),
    [acknowledgeApproval.fulfilled]: (state,action) => state = action.payload,
    [acknowledgeApproval.rejected]: (state, action) => error(action.error.message),


    // guest
    [getApprovalGuest.fulfilled]: (state, action) => action.payload,
    [getApprovalGuest.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
  },
});

export const { newApproval, resetApproval } = approvalSlice.actions;

export default approvalSlice.reducer;


const error = (message) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
      icon: "error",
      title: "Ocorreram um ou mais erros",
      html: message,
      width: "800px",
  })
}