export default function fieldTypeToHuman(fieldType) {
  switch (fieldType) {
    case "text":
      return "Texto Curto";
    case "textarea":
      return "Texto Longo";
    case "radio":
      return "Seleção";
    case "checkbox":
      return "Seleção Multipla";
    default:
      return fieldType;
  }
}
