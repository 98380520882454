import { useLocation } from "react-router-dom";


const Page404 = (props) => {

  //props.history.goBack()
  const location = useLocation();
  const prevURL = location?.state?.prevURL ?? null

  return (
    <main id="main-container">
      <div
        className="bg-image"
        style={{ backgroundImage: "url('assets/media/photos/photo19@2x.jpg')" }}
      >
        <div className="hero bg-white-75">
          <div className="hero-inner">
            <div className="content content-full">
              <div className="px-3 py-5 text-center">
                <div className="row">
                  <div className="col-sm-6 text-center text-sm-end">
                    <div className="display-1 text-danger fw-bold">404</div>
                  </div>
                  <div className="col-sm-6 text-center d-sm-flex align-items-sm-center">
                    <div className="display-1 text-muted fw-regular">Error</div>
                  </div>
                </div>
                <h1 className="h2 fw-bold mt-5 mb-3">
                  Oops... alguma coisa se passou
                </h1>
                <h2 className="h3 fw-regular text-muted mb-5">
                  Pedimos desculpa mas a página que tentou aceder não existe...
                </h2>
                <div>
                  <button
                    className="btn btn-hero btn-secondary"
                    onClick={() =>{ //props.history.goBack() 
                      if(prevURL){
                        props.history.goBack() 
                      }
                      else{
                        props.history.push("/")
                      }
                    }}
                  >
                    <i className="fa fa-arrow-left me-1"></i> Voltar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Page404;
