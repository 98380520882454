import React from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

const Sidebar = (props) => {
  let mobile = window.matchMedia("only screen and (max-width: 992px)").matches;
  let mobileProps = {};
  if (mobile) {
    mobileProps = { "data-toggle": "layout", "data-action": "sidebar_close" };
  }

  const routesAvailable = (url) => {
    return props.routes.some((route) => route.path === url);
  };

  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const isUser = () => {
    return (
      routesAvailable("/approvals/connoisseur") ||
      routesAvailable("/approvals/received") ||
      routesAvailable("/approvals/sent")
    );
  };

  const isAdmin = () => {
    return routesAvailable("/users") || routesAvailable("/company");
  };

  const SidebarItem = ({ url, name, icon }) => {
    return (
      <div>
        <li className="nav-main-item">
          <Link className="nav-main-link" to={url} {...mobileProps}>
            <i className={`nav-main-link-icon fa ${icon}`}></i>
            <span className="nav-main-link-name">{name}</span>
          </Link>
        </li>
      </div>
    );
  };

  return (
    <nav id="sidebar" aria-label="Main Navigation">
      <div className="bg-header-dark">
        <div className="content-header bg-white-10">
          <Link className="fw-semibold text-white tracking-wide" to={"/"}>
            <span className="smini-visible">
              F<span className="opacity-75">C</span>
            </span>
            <span className="smini-hidden">
              Flow<span className="opacity-75">Consent</span>
            </span>
          </Link>

          <div>
            <span
              className="d-lg-none text-white ms-2"
              style={{ cursor: "pointer" }}
              data-toggle="layout"
              data-action="sidebar_close"
            >
              <i className="fa fa-xl fa-times-circle"></i>
            </span>
          </div>
        </div>
      </div>

      <div className="js-sidebar-scroll">
        <div className="content-side">
          <ul className="nav-main">
            <li className="nav-main-item">
              <Link className="nav-main-link" to="/" {...mobileProps}>
                <i className="nav-main-link-icon fa fa-location-arrow"></i>
                <span className="nav-main-link-name">Dashboard</span>
              </Link>
              <Link className="nav-main-link" to="/suporte" {...mobileProps}>
                <i className="nav-main-link-icon fa fa-life-ring"></i>
                <span className="nav-main-link-name">Preciso de Ajuda!</span>
              </Link>
            </li>

            {isUser() && (
              <React.Fragment>
                <li className="nav-main-heading">Aprovações</li>
                <SidebarItem
                  url="/approvals/connoisseur"
                  icon="fa-inbox"
                  name="Recebidas para conhecimento"
                />
                <SidebarItem
                  url="/approvals/received"
                  icon="fa-inbox"
                  name="Recebidas para aprovação"
                />
                <SidebarItem
                  url="/approvals/sent"
                  icon="fa-paper-plane"
                  name="Enviadas"
                />

                {routesAvailable("/approvals/templates") && (
                  <React.Fragment>
                    <li className="nav-main-heading">Modelos</li>
                    <SidebarItem
                      url="/approvals/templates"
                      icon="fa-list"
                      name="Modelos Predefinidos"
                    />
                  </React.Fragment>
                )}

                {LoginUser?.tags?.length > 0 &&
                  Array.isArray(LoginUser?.tags) &&
                  LoginUser.tags.filter((tag) => tag.approvalsCount > 0)
                    .length > 0 && (
                    <React.Fragment>
                      <li className="nav-main-heading">
                        Aprovações por Identificador
                      </li>
                      {LoginUser.tags
                        .filter((tag) => tag.approvalsCount > 0)
                        .map((tag, index) => {
                          return (
                            <SidebarItem
                              key={index}
                              url={`/approvals/tags/${tag.id}`}
                              icon="fa-bookmark"
                              name={tag.name}
                            />
                          );
                        })}
                    </React.Fragment>
                  )}
              </React.Fragment>
            )}

            {isAdmin() && (
              <React.Fragment>
                <li className="nav-main-heading">Administração</li>
                <SidebarItem url="/users" icon="fa-users" name="Utilizadores" />
                <SidebarItem
                  url="/company"
                  icon="fa-building"
                  name="Empresas"
                />
              </React.Fragment>
            )}


            {process.env.NODE_ENV == "development" && <>
              <li className="nav-main-heading">DEV</li>

              <Link className="nav-main-link" to="/worker" {...mobileProps}>
                <i className="nav-main-link-icon fa-solid fa-code"></i>
                <span className="nav-main-link-name">Worker</span>
              </Link>
            </>}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Sidebar);
