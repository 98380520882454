import DashboardPage from "../pages/DashboardPage";

import ApprovalsSentPage from "../pages/approvals/ApprovalsSentPage";
import ApprovalsReceivedPage from "../pages/approvals/ApprovalsReceivedPage";
import ApprovalsConnoisseurPage from "pages/approvals/ApprovalsConnoisseurPage";
import ApprovalsDetailPage from "../pages/approvals/ApprovalsDetailPage";
import ApprovalsNewPage from "../pages/approvals/ApprovalsNewPage";

import ApprovalTemplatesPage from "pages/approval-templates/ApprovalTemplatesPage";
import ApprovalTemplatesNewPage from "pages/approval-templates/ApprovalTemplatesNewPage";

import UsersPage from "pages/users/UsersPage";
import UsersDetailsPage from "pages/users/UsersDetailsPage";
import UsersNewPage from "pages/users/UsersNewPage";

import CompanyPage from "pages/company/CompanyPage";
import CompanyDetailsPage from "pages/company/CompanyDetailPage";
import CompanyNewPage from "pages/company/CompanyNewPage";

import ApprovalsTagsPage from "pages/approvals/ApprovalsTagsPage";

import InfoPage from "pages/InfoPage";

import { PermissionRoutes } from "./permissions";
import SupportPage from "pages/SupportPage";
import WorkerPage from "pages/__DEV/WorkerPage";
import ProfilePage from "pages/ProfilePage";

const routes = [
  { path: "/", component: DashboardPage, exact: true },
  { path: "/approvals/sent", component: ApprovalsSentPage, exact: true },
  { path: "/approvals/received", component: ApprovalsReceivedPage, exact: true },
  { path: "/approvals/connoisseur", component: ApprovalsConnoisseurPage, exact: true },
  { path: "/approvals/templates", component: ApprovalTemplatesPage, exact: true },
  { path: "/approvals/templates/:templateId", component: ApprovalTemplatesNewPage, exact: true },
  { path: "/approvals/tags/:tagId", component: ApprovalsTagsPage, exact: true },
  { path: "/approvals/:approvalId", component: ApprovalsNewPage, exact: true },
  { path: "/approvals/:approvalId/template/:templateId", component: ApprovalsNewPage, exact: true },
  { path: "/approvals/:approvalId/parent/:parentId", component: ApprovalsNewPage, exact: true },
  { path: "/approvals/:approvalId/view", component: ApprovalsDetailPage, exact: true },
  
  { path: "/users", component: UsersPage, exact: true },
  { path: "/users/:userId", component: UsersNewPage, exact: true },
  { path: "/users/:userId/view", component: UsersDetailsPage, exact: true },

  { path: "/company", component: CompanyPage, exact: true },
  { path: "/company/:companyId", component: CompanyNewPage, exact: true },
  { path: "/company/:companyId/view", component: CompanyDetailsPage, exact: true },

  { path: "/info", component: InfoPage, exact: true },
  { path: "/suporte", component: SupportPage, exact: true },


  { path : "/profile", component: ProfilePage, exact: true },

  // dev
  { path: "/worker", component: WorkerPage, exact: true },
];


export default routes;

export const generateRoutes = ({user}) => {
  if(user == null || user.profile == null || user.profile.permissions == null){
    return [];
  }

  const userPermissions = user.profile.permissions.map((perm) => perm.name) ?? null;
  

  let newRoutes = routes;
  newRoutes = newRoutes.filter((route) => {

    const requiredPermissions = PermissionRoutes[route.path] ?? [] ;

    const authorized = requiredPermissions.some((requiredPermission) => userPermissions.includes(requiredPermission))     
    if(authorized || requiredPermissions.length === 0){
      return route;
    }
    return false;
  })

  return newRoutes;
}