import { Persona } from "@fluentui/react-components";
import {
  getApprovalLink,
  APPROVAL_LINK_TYPE,
} from "../../utils/link-generator";
import React from "react";
import { Link } from "react-router-dom";

function CardApprovalActionNeed({ approval }) {
  return (
    <Link
      to={getApprovalLink(APPROVAL_LINK_TYPE.VIEW, approval.id)}
      className="block block-rounded block-link-shadow"
    >
      <div className="block-header block-header-default bg-warning">
        <h3 className="block-title d-flex flex-row align-items-center justify-content-between text-white">
          {approval.title} <i className="fa fa-clock"></i>
        </h3>
      </div>

      <div className="block-content py-3">
        <div className="d-flex flex-column text-muted">
          <span className="font-weight-bold mb-2">Pedido por:</span>
          <Persona
            textAlignment={"center"}
            avatar={{
              image: {
                src: approval.user?.profilePhoto,
              },
            }}
            secondaryText={approval.user?.email}
            name={approval.user?.name}
            size={"medium"}
          />
        </div>
      </div>
      <div className="p-0 progress" style={{ height: 2 }}>
        <div
          className="progress-bar bg-warning"
          role="progressbar"
          style={{ width: "75%" }}
          aria-valuenow="30"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </Link>
  );
}

export default CardApprovalActionNeed;

/*
{/* <div className="p-0 progress" style={{ height: 2 }}>
        <div
          className="progress-bar bg-warning"
          role="progressbar"
          style={{ width: "75%" }}
          aria-valuenow="30"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="block-content block-content-full d-flex flex-column align-items-start justify-content-between">
        <p className="font-size-lg font-w600 m-0 p-2 bg-body-light">
          {approval.title}
        </p>
        <div>
          
        </div>
       
      </div>
    </Link> */
