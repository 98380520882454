import * as React from "react";

import {
  NormalPeoplePicker,
  PeoplePickerItem,
  PeoplePickerItemSuggestion
} from "@fluentui/react"

import axios from "axios";
import { peoplepickerStyle } from "config/templateTheme";

const suggestionProps = {
  suggestionsHeaderText: "Resultados",
  mostRecentlyUsedHeaderText: "Utilizadores Recentes",
  noResultsFoundText: "Nenhum resultado encontrado",
  loadingText: "A carregar...",
  showRemoveButtons: false,
};

const validEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

const PeoplePicker = ({ handleSelectedItems, selectedItems, exclude = [], allowGuest = false, }) => {
  const picker = React.useRef(null);

  const onItemsChange = (items) => {
    handleSelectedItems(items);
  };

  const onFilterChanged = async (filterText, currentPersonas, limitResults) => {
    if (filterText) {
      filterText = filterText.trim().toLowerCase();
      let res = await axios.get(`/user/peoplepicker?search=${filterText}`);
      const personasList = res.data?.users?.map((user) => {
        return { user: user, key: user.email, };
      });

      let filteredPersonas = removeDuplicates(personasList, exclude)
      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;

      if (allowGuest) {
        if (filterText && validEmailRegex.test(filterText) && !personasList.some((p) => p?.user?.email === filterText || (p?.guest && p?.guest?.email === filterText))) {
          filteredPersonas.push({ guest: { email: filterText, }, key: filterText }); // colocar o guest
        }
      }


      return filteredPersonas;
    } else {
      return [];
    }
  };

  const onRenderPrimaryText = (persona) => {
    return persona?.user?.name ?? persona?.guest?.email;
  };

  const onRenderSecondaryText = (persona) => {
    // return persona?.user?.email ?? persona?.guest?.email;
    return persona?.user?.email ?? "Convidar";
  };


  const onRenderSuggestionsItem = (personaProps, suggestionsProps) => (
    <PeoplePickerItemSuggestion
      personaProps={{
        ...personaProps,
        imageUrl: personaProps?.user?.profilePhoto ?? null,
        imageInitials: personaProps?.user?.name?.split(/\s/).reduce((response, word) => response += word.slice(0, 1), ''),
        onRenderPrimaryText: onRenderPrimaryText,
        onRenderSecondaryText: onRenderSecondaryText,
      }}
      suggestionsProps={suggestionsProps}
      styles={{ personaWrapper: { width: "100%" } }}
    />
  );

  // input rendered item
  const onRenderItemPrimaryText = (e) => {
    // if(e?.guest?.name){
    //   return (
    //     <div>
    //     {e?.guest?.name}
    //     {<b>+Guest</b>}
    //   </div>
    //   )
    // }

    // return e?.user?.name ?? "";
    return e?.user?.name ?? e?.guest?.email;
  }

  const onRenderItemSecondaryText = (e) => {
    return e?.user?.email ?? e?.guest?.email;
  }


  const onRenderItem = (pickerItemProps) => (
    <PeoplePickerItem componentRef={pickerItemProps.componentRef}
      item={{
        ...pickerItemProps.item,
        imageUrl: pickerItemProps?.item?.user?.profilePhoto ?? null,
        onRenderPrimaryText: onRenderItemPrimaryText,
        onRenderSecondaryText: onRenderItemSecondaryText
      }}
      index={pickerItemProps.index}
      selected={pickerItemProps.selected}
      onRemoveItem={pickerItemProps.onRemoveItem}
      onItemChange={pickerItemProps.onItemChange}
      key={pickerItemProps.key}
      removeButtonAriaLabel={pickerItemProps.removeButtonAriaLabel} />
  )



  return (
    <div>
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        onRenderItem={onRenderItem}
        className={"form-control"}
        styles={peoplepickerStyle}
        key={"controlled"}
        selectedItems={selectedItems}
        onChange={onItemsChange}
        componentRef={picker}
        resolveDelay={300}
      />
    </div>
  );
};

function removeDuplicates(personas, possibleDupes) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(persona, personas) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item?.user?.email === persona?.user?.email || (item?.guest && item?.guest?.email === persona?.guest?.email)).length > 0;
}

function getTextFromItem(persona) {
  return persona?.user?.name ?? persona?.guest?.email;
}

export default PeoplePicker;
