import axios from "axios";
import { useDeepCompareEffect } from "hooks";
import { DirectionalHint, TooltipDelay, TooltipHost } from "@fluentui/react";

import { Persona } from "@fluentui/react-persona";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import withReducer from "store/withReducer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import sizeToHuman from "utils/sizeToHuman";
import reducer from "./store";
import {
  acknowledgeApproval,
  getApproval,
  publishApproval,
  resetApproval,
  cancelApproval,
  deleteApproval,
} from "./store/approvalSlice";
import { userHasPermission, PermissionsList } from "config/permissions";

import Tree from "pages/components/Tree/Tree";
import { getApprovalTree } from "./store/approvalTreeSlice";

import moment from "moment";
import "moment/locale/pt";
import Redirect404 from "pages/components/Redirect404";
import { MultiSelect } from "pages/components/MultiSelect";
import { addTagsToApproval } from "./store/approvalsSlice";

import { updateUserTags } from "store/userSlice";
import { ValidationState } from "@fluentui/react";
import ApprovalDetailStepList from "./components/DetailStepList";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Avatar,
  Badge,
  Button,
  makeStyles,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import { APPROVAL_LINK_TYPE, getApprovalLink } from "utils/link-generator";
import ScalableTooltip from "./components/ScalableTooltip";
import Chat from "./components/Chat";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
    "> :not(:last-child)": {
      marginRight: "10px",
    },
  },
  item: {
    height: "auto",
    width: "auto",
    flexShrink: 1,
  },
});

const ApprovalsDetailPage = (props) => {
  const styles = useStyles();

  let btnPublishRef = useRef();

  const dispatch = useDispatch();
  const approval = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalReducer;
  });

  const [approvalTree, setApprovalTree] = useState([]);

  const user = useSelector((root) => {
    return root.userReducer;
  });

  const [noApproval, setNoApproval] = useState(false);
  const routeParams = useParams();

  const [tags, setTags] = useState([]);

  useDeepCompareEffect(() => {
    async function updateApprovalState() {
      const { approvalId } = routeParams;

      let action = await dispatch(getApproval(approvalId));
      if (!action.payload) {
        setNoApproval(true);
      }
    }

    updateApprovalState();
  }, [dispatch, routeParams]);

  useEffect(() => {
    if (approval) {
      fetchApprovalTree();
      setTags(approval.tags);
    }
  }, [approval]);

  useEffect(() => {
    return () => {
      dispatch(resetApproval());
      setNoApproval(false);
    };
  }, [dispatch]);

  const getApprovalStatusObject = (status) => {
    switch (status) {
      case "DRAFT":
        return {
          title: "Rascunho",
          backgroundClass: "bg-secondary",
          textClass: "text-secondary",
          alertClass: "alert-secondary",
          icon: "fa-2x me-1 fa fa-clock",
          intent: "info",
        };
      case "RUNNING":
        return {
          title: "A Decorrer",
          backgroundClass: "bg-warning",
          textClass: "text-warning",
          alertClass: "alert-warning",
          icon: "fa-2x me-1 fa fa-spinner fa-spin",
          intent: "warning",
        };
      case "APPROVED":
        return {
          title: "Aprovada",
          backgroundClass: "bg-success",
          textClass: "text-success",
          alertClass: "alert-success",
          icon: "fa-2x me-1 fa fa-check",
          intent: "success",
        };
      case "REJECTED":
        return {
          title: "Rejeitada",
          backgroundClass: "bg-danger",
          textClass: "text-danger",
          alertClass: "alert-danger",
          icon: "fa-2x me-1 fa fa-times",
          intent: "danger",
        };
      case "CANCELED":
        return {
          title: "Cancelado",
          backgroundClass: "bg-danger",
          textClass: "text-danger",
          alertClass: "alert-danger",
          icon: "fa-2x me-1 fa fa-times",
        };
      default:
        return {
          title: "Outro",
          backgroundClass: "bg-secondary",
          textClass: "text-secondary",
          alertClass: "alert-secondary",
          icon: "fa-2x me-1 fa fa-clock",
        };
    }
  };

  const checkApprovalConcluded = () => {
    return ["APPROVED", "REJECTED", "CANCELED"].includes(approval.status);
  };

  const deleteApprovalClick = (id) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "info",
      title: "Tem a certeza que pertende eliminar esta Aprovação?",
      confirmButtonText: "Eliminar",
      denyButtonText: "Cancelar",
      showDenyButton: true,
      width: "800px",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteApproval(id)).then((res) => {
          if (!res.error) {
            props.history.push(`/approvals/sent`);
          }
        });
      }
    });
  };

  const cancelApprovalClick = (id) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "info",
      title: "Tem a certeza que pertende cancelar esta Aprovação?",
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      showDenyButton: true,
      width: "800px",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(cancelApproval(id))
      }
    });
  };

  const downloadAnexo = async (anexo) => {
    window.Dashmix.block("state_loading", `#attachment${anexo.id}`);
    try {
      if (anexo.externalId) {
        const response = await axios({
          url: axios.defaults.baseURL + anexo.downloadUrl,
          method: "GET",
          responseType: "json",
        }).catch((err) => {
          throw err;
        });

        const { accessToken, downloadLink } = response.data;
        const downloadFile = await axios({
          url: downloadLink,
          method: "POST",
          headers: { Authorization: "Bearer " + accessToken },
          responseType: "blob",
        }).catch((err) => {
          throw err;
        });

        const url = window.URL.createObjectURL(
          new Blob([downloadFile.data], { type: anexo.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", anexo.name);
        link.click();
      } else {
        const response = await axios({
          url: axios.defaults.baseURL + anexo.downloadUrl,
          method: "GET",
          responseType: "blob",
        }).catch((err) => {
          throw err;
        });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: anexo.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", anexo.name);
        link.click();
      }
    } catch (error) {
      console.error(error);
    } finally {
      window.Dashmix.block("state_normal", `#attachment${anexo.id}`);
    }
  };
  const fetchApprovalTree = async () => {
    const { approvalId } = routeParams;
    let treeAction = await dispatch(getApprovalTree(approvalId));
    setApprovalTree(treeAction.payload);
  };

  const approvalTreeItemClick = (item) => {
    if (!item.link) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Não tem permissão para aceder a esta aprovação",
        width: "800px",
      });
      return;
    }

    if (item.id === approval.id) {
      return;
    }

    props.history.push(`/approvals/${item.id}/view`);
  };

  const changeTags2 = async (items) => {
    setTags(items);
    await dispatch(addTagsToApproval({ id: approval.id, tags: items }));
    await dispatch(updateUserTags());
  };

  //renders
  if (noApproval) {
    return <Redirect404 />;
  }

  if (
    !approval ||
    (approval && routeParams.approvalId !== approval.id.toString())
  ) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h2 className="flex-sm-fill mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h2>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div
        className={`bg-body-light shadow animated fadeIn ${
          getApprovalStatusObject(approval.status).backgroundClass
        }`}
      >
        <div className={`content content-full`}>
          <div
            className={`d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center`}
          >
            <h2 className="flex-sm-fill mt-2 mb-md-4 mb-sm-2">
              <Badge
                appearance="filled"
                shape="rounded"
                size="extra-large"
                color="brand"
              >
                <Text size={700}>#{approval.id}</Text>
              </Badge>
              <span className="badge badge-lg badge-primary"></span>{" "}
              {approval.title}
            </h2>

            {approval.status !== "DRAFT" && (
              <React.Fragment>
                {userHasPermission(
                  user.user.profile.permissions,
                  PermissionsList.APPROVAL_CREATE
                ) && (
                  <Link
                    className="btn btn-alt-secondary me-2"
                    to={getApprovalLink(
                      APPROVAL_LINK_TYPE.DUPLICATE,
                      approval.id
                    )}
                  >
                    Duplicar
                  </Link>
                )}
              </React.Fragment>
            )}

            {approval.status === "DRAFT" && (
              <React.Fragment>
                {userHasPermission(
                  user.user.profile.permissions,
                  PermissionsList.APPROVAL_EDIT
                ) && (
                  <Link
                    className="btn btn-alt-warning me-2 my-1"
                    to={`/approvals/${approval.id}`}
                  >
                    Editar Fluxo
                  </Link>
                )}

                {userHasPermission(
                  user.user.profile.permissions,
                  PermissionsList.APPROVAL_DELETE
                ) && (
                  <button
                    onClick={() => {
                      deleteApprovalClick(approval.id);
                    }}
                    className="btn btn-alt-danger me-2  my-1"
                  >
                    Apagar Fluxo
                  </button>
                )}

                <button
                  ref={btnPublishRef}
                  className="btn btn-danger me-2  my-1"
                  onClick={() => {
                    if (btnPublishRef.current) {
                      btnPublishRef.current.setAttribute(
                        "disabled",
                        "disabled"
                      );
                    }
                    dispatch(publishApproval(approval.id));
                  }}
                >
                  Iniciar Fluxo
                </button>
              </React.Fragment>
            )}

            {approval.status === "RUNNING" && (
              <React.Fragment>
                {userHasPermission(
                  user.user.profile.permissions,
                  PermissionsList.APPROVAL_DELETE
                ) &&
                  user.user.name === approval.createdBy && (
                    <button
                      onClick={() => {
                        cancelApprovalClick(approval.id);
                      }}
                      className="btn btn-alt-danger me-2  my-1"
                    >
                      Cancelar Fluxo
                    </button>
                  )}
              </React.Fragment>
            )}
          </div>
          <div className="d-flex flex-row flex-wrap">
            <div className="pt-2 pe-5 d-flex flex-column flex-wrap align-items-start">
              <label>Autor</label>
              <Persona
                textAlignment={"center"}
                name={approval?.user?.name}
                size={"medium"}
                avatar={{
                  image: {
                    src: approval?.user?.profilePhoto,
                  },
                }}
                secondaryText={approval?.user?.email}
              />
            </div>
            <div className="pt-2 pe-5 d-flex flex-column flex-wrap align-items-start">
              <label>Data de Início</label>
              <p className="pt-1">{moment(approval.createdAt).calendar()} </p>
            </div>

            <div className="pt-2 pe-5 w-50">
              <MultiSelect
                data={user.user.tags}
                title="Identificadores"
                idColumn="id"
                valueColumn="name"
                handleSelectedItems={(items) => {
                  changeTags2(items);
                }}
                selectedItems={tags}
                doNotLayer={false}
                handleCreateGenericItem={(input) => {
                  return { id: "", name: input };
                }}
                handleOnValidateInput={(input) => {
                  return input.length > 0
                    ? ValidationState.valid
                    : ValidationState.invalid;
                }}
                styleSizeSmall={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn mt-2">
        {approval.status !== "DRAFT" &&
        approval.connoisseurs.some(
          (x) => x?.user?.id === user.user.id && !x.knowledgeDate
        ) ? (
          <>
            <div className="px-3 py-5 text-center">
              <h1 className="fw-bold mt-5 mb-3">
                Ao abrir estará a tomar conhecimento da aprovação
              </h1>
              <h2 className="h3 fw-normal text-muted mb-5">
                É necessária a tomada de conhecimento para ver o conteúdo
              </h2>
              {/* tem erro nao está a atualizar a pagina */}
              <Button
                onClick={() => dispatch(acknowledgeApproval(approval.id))}
                size="large"
                appearance="primary"
                className="mb-3"
              >
                <i className="opacity-50 me-1"></i> Tomar conhecimento
              </Button>
              <br />
              <Button
                onClick={() => props.history.goBack()}
                size="large"
                appearance="transparent"
                className="mb-3"
              >
                <i className="fa fa-arrow-left opacity-50 me-1"></i> Voltar
                atrás
              </Button>
            </div>
          </>
        ) : (
          /*};
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "info",
      title: "Ao abrir esta aprovação estará a tomar conhecimento da mesma",
      confirmButtonText: "Abrir aprovação",
      denyButtonText: "Voltar para trás",
      showDenyButton: true,
      width: "800px",
      backdrop: "rgba(111,111,111,1)",
    }).then((result) => {
      if (result.isConfirmed) {
      } else if (result.isDismissed || result.isDenied) {
      }
    });
  }}*/ <>
            <div
              className={`alert ${
                getApprovalStatusObject(approval.status).backgroundClass
              } d-flex align-items-center`}
              role="alert"
            >
              <div className="text-white">
                <i
                  className={`${getApprovalStatusObject(approval.status).icon}`}
                ></i>
              </div>
              <div className="ms-1">
                <p className="mb-0 text-white fw-semibold fs-lg">
                  {getApprovalStatusObject(approval.status).title}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-7 order-4 order-lg-0 ">
                <div className="block block-rounded block-bordered">
                  <div className="block-header block-header-default">
                    <h3 className="block-title">Informação</h3>
                  </div>
                  <div className="block-content">
                    <style>
                      {
                        ".ql-container.ql-snow{ border: none !important;} .ql-editor{ padding:0; padding-bottom: 20px }"
                      }
                    </style>
                    <label className="fs-md fw-semibold">Descrição</label>

                    <ReactQuill
                      value={
                        approval.description ? approval.description : "N/D"
                      }
                      modules={{ toolbar: false }}
                      readOnly={true}
                      theme="snow"
                      style={{ padding: 0, border: 0 }}
                    />
                    {approval.fields.map((field, index) => {
                      return (
                        <React.Fragment key={index}>
                          <label className="fs-md fw-semibold">
                            {field.name}
                          </label>
                          <p>{field.value ? field.value : "N/D"}</p>
                        </React.Fragment>
                      );
                    })}
                    {approval.attachments.length > 0 && (
                      <React.Fragment>
                        <h4 className="content-heading p-0">Anexos</h4>
                        <div className="row push">
                          {approval.attachments.map((x) => {
                            return (
                              <div
                                key={x.index}
                                id={`attachment${x.id}`}
                                className={`block block-mode-loading-download options-container fx-overlay-zoom-out col-xl-4 col-md-6 col-12`}
                              >
                                <div className="options-item block block-rounded bg-body mb-0">
                                  <div className="block-content text-center">
                                    <p className="mb-2 overflow-hidden">
                                      <i className="fa fa-fw fa-4x fa-file-alt text-black"></i>
                                    </p>
                                    <p className="fw-semibold mb-0 text-break">
                                      {x.name}
                                    </p>
                                    <p className="fs-sm text-muted">
                                      {x.size === -1
                                        ? "O Anexo expirou"
                                        : sizeToHuman(x.size, true)}
                                    </p>
                                  </div>
                                </div>
                                {x.size !== -1 && (
                                  <div className="options-overlay rounded-lg bg-white-50">
                                    <div className="options-overlay-content">
                                      <div className="btn-group">
                                        <button
                                          className="btn btn-sm btn-dark"
                                          onClick={() => downloadAnexo(x)}
                                        >
                                          <i className="fa fa-download text-white me-1"></i>
                                          Descarregar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        {approval.attachments.length > 0 &&
                          checkApprovalConcluded() && (
                            <div className="alert bg-warning d-flex align-items-center flex-fill text-white">
                              Anexos ficam indisponíveis 30 dias após a
                              conclusão da aprovação
                            </div>
                          )}
                      </React.Fragment>
                    )}
                  </div>
                </div>

                {/* approval tree */}
                <div className="block block-rounded block-bordered">
                  <div className="block-header block-header-default">
                    <h3 className="block-title">Aprovações Relacionadas</h3>
                  </div>
                  <div className="block-content">
                    <Tree
                      data={approvalTree}
                      id={approval.id}
                      itemClick={approvalTreeItemClick}
                    />
                  </div>
                </div>

                <Chat approvalId={approval.id} approvalStatus={approval.status}/>

              </div>
              <div className="col-12 col-lg-5">
                {approval.connoisseurs.length !== 0 && (
                  <div className="block block-rounded block-bordered">
                    <div className="block-header block-header-default">
                      <h3 className="block-title">Tomadas de Conhecimento</h3>
                    </div>
                    <div className="block-content">
                      <div className={styles.root}>
                        {/*TODO: PASSAR AS NOVAS TOLTIPS E A UM COMPONENTE*/}
                        {approval.connoisseurs.map((connoisseur, index) => (
                          <div className={styles.item} key={index}>
                            <TooltipHost
                              tooltipProps={{
                                calloutProps: {
                                  styles: () => {
                                    if (connoisseur.knowledgeDate === null) {
                                      return {
                                        beak: { background: "#e69f17" },
                                        beakCurtain: {
                                          background: "#e69f17",
                                        },
                                        calloutMain: {
                                          background: "#e69f17",
                                        },
                                      };
                                    } else if (connoisseur.knowledgeDate) {
                                      return {
                                        beak: { background: "#6f9c40" },
                                        beakCurtain: {
                                          background: "#6f9c40",
                                        },
                                        calloutMain: {
                                          background: "#6f9c40",
                                        },
                                      };
                                    }
                                  },
                                },
                                onRenderContent: () => {
                                  if (connoisseur.knowledgeDate === null) {
                                    return (
                                      <div
                                        className={"bg-warning"}
                                        style={{
                                          margin: 0,
                                          padding: 10,
                                        }}
                                      >
                                        <span className="h5 text-white">
                                          <i className="fa fa-clock me-1"></i>{" "}
                                          Por tomar conhecimento
                                        </span>
                                      </div>
                                    );
                                  } else if (connoisseur.knowledgeDate) {
                                    return (
                                      <div
                                        className={"bg-success"}
                                        style={{
                                          margin: 0,
                                          padding: 10,
                                        }}
                                      >
                                        <span className="h5 text-white">
                                          <i className="fa fa-check me-1"></i>{" "}
                                          Tomou conhecimento
                                        </span>
                                        <span className="d-block text-white">
                                          {moment(
                                            connoisseur.knowledgeDate
                                          ).format("LLL")}
                                        </span>
                                      </div>
                                    );
                                  }
                                },
                              }}
                              delay={TooltipDelay.zero}
                              directionalHint={DirectionalHint.bottomCenter}
                            >
                              <Persona
                                className="mb-2"
                                textAlignment={"center"}
                                name={
                                  connoisseur?.user?.name ??
                                  connoisseur?.guest?.email
                                }
                                size={"medium"}
                                secondaryText={
                                  connoisseur.knowledgeDate !== null
                                    ? "Visto"
                                    : "Não visto"
                                }
                                avatar={{
                                  image: {
                                    src: connoisseur?.user?.profilePhoto,
                                  },
                                }}
                                presence={
                                  connoisseur.knowledgeDate !== null
                                    ? { status: "available" }
                                    : { status: "unknown" }
                                }
                              />
                            </TooltipHost>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {approval.steps.length > 0 && (
                  <div className="block block-rounded block-bordered">
                    <div className="block-header block-header-default">
                      {approval?.scalable ? (
                        <h3 className="block-title">
                          Fluxo Escalável de Aprovação
                          <ScalableTooltip />
                        </h3>
                      ) : (
                        <h3 className="block-title">Fluxo de Aprovação</h3>
                      )}
                    </div>
                    <div className="block-content py-0">
                      <ApprovalDetailStepList approval={approval} user={user} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default withReducer("approvalsModule", reducer)(ApprovalsDetailPage);
