export const peoplepickerStyle= {
    root:{
      minHeight: "calc(1.5em + 1.25rem) !important",
      paddingTop: "0rem !important",
      paddingBottom: "0rem !important",
      paddingLeft: "0rem !important",
      paddingRight: "0rem !important",
	  height: "auto !important",
    },
    
    text: {
      border: 0, 
      padding: ".375rem .75rem",
      ':after' : {
        border: "1px solid #5CA6FA !important",
        borderRadius: ".25rem",	
        borderColor:"#5ba6fa",
        backgroundColor:"#fff",
        boxShadow: "0 0 0 .2rem rgba(6,101,208,.25)",
        zIndex:3,
      },
    },
    input: { zIndex:4},
    itemsWrapper:{ zIndex:4}
}

export const multiSelectStyle = {
    root:{
        minHeight: "calc(1.5em + 1.25rem) !important",
        paddingTop: "0rem !important",
        paddingBottom: "0rem !important",
        paddingLeft: "0rem !important",
        paddingRight: "0rem !important",
		height: "auto !important",
    },
    
    text: {
        border: 0, 
        padding: ".375rem .75rem",
        ':after' : {
            border: "1px solid #5CA6FA !important",
            borderRadius: ".25rem",	
            borderColor:"#5ba6fa",
            backgroundColor:"#fff",
            boxShadow: "0 0 0 .2rem rgba(6,101,208,.25)",
            zIndex:3,
        },
    },
    input: { zIndex:4},
    itemsWrapper:{ zIndex:4}
}


export const multiSelectStyleSmall = {
  root:{
      minHeight: "calc(1.5em + 0.75rem) !important",
      paddingTop: "0rem !important",
      paddingBottom: "0rem !important",
      paddingLeft: "0rem !important",
      paddingRight: "0rem !important",
      height: "auto !important",
  },

  text: {
      border: 0, 
      padding: ".15rem .5rem",
      ':after' : {
          border: "1px solid #5CA6FA !important",
          borderRadius: ".25rem",	
          borderColor:"#5ba6fa",
          backgroundColor:"#fff",
          boxShadow: "0 0 0 .2rem rgba(6,101,208,.25)",
          zIndex:3,
      },
  },
  input: { zIndex:4},
  itemsWrapper:{ zIndex:4}
}


export const spinButtonStyle = {
    root:{
      height: "calc(1.5em + 1.25rem) !important",
      paddingTop: "0rem !important",
      paddingBottom: "0rem !important",
      paddingLeft: "0rem !important",
      paddingRight: "0rem !important",
      
      ':focus-within': {
        border: "1px solid #5CA6FA !important",
        borderRadius: ".25rem",	
        borderColor:"#5ba6fa",
        backgroundColor:"#fff",
        boxShadow: "0 0 0 .2rem rgba(6,101,208,.25)",
      }
      
    },
    
    spinButtonWrapper: {
      border: 0, 
      height: "100%",
      padding: ".375rem .75rem",

      ':after' : {
        border: "0px !important",
        zIndex:3,
      },      
    },
    input: { zIndex:4},
    arrowButtonsContainer : { zIndex:4}
}

