import React, { useState, useEffect } from 'react'

import axios from "axios";
import { deviceDetect, getUA } from 'react-device-detect';

import { v4 as uuidv4 } from 'uuid';

const LOCALSTORAGEKEY = "APP_PUSH_UUID"

function WorkerPage() {

  const [applicationServerKey, setApplicationServerKey] = useState("")

  const [workerActive, setWorkerActive] = useState(false)
  const [notificationsActive, SetNotificationsActive] = useState(false)
  const [subscriptionActive, setSubscriptionActive] = useState(false)
  const [error, setError] = useState("")



  useEffect(() => {
    checkUniqueId()
    check()
  }, [])

  

  function checkUniqueId() {
    const uuid = localStorage.getItem(LOCALSTORAGEKEY);
    if (!uuid || uuid.length <= 0) {
      localStorage.setItem(LOCALSTORAGEKEY, uuidv4())
    }

    console.log(localStorage.getItem(LOCALSTORAGEKEY))
  }

  async function getKey () {
    if(applicationServerKey && applicationServerKey.length>0)
      return;

    const keyRes = await axios.get("/notifications/key")
    .catch(err => {console.error(err); throw err})

    if(keyRes.status === 200){
      setApplicationServerKey(keyRes.data)
      return keyRes.data
    }
    else 
      throw "no key error"
  }

  async function check() {
    if (Notification.permission === "granted") {
      SetNotificationsActive(true)
    }

    const key = await getKey()

    const worker = await navigator.serviceWorker.getRegistration()
    if (worker == undefined) {
      console.error("!worker not running")
      setError("!worker not running")
    }
    else {
      setWorkerActive(true)
      subscribeUser(worker, key, setSubscriptionActive, setError)
    }

  }

  const sendNotify = async() => {
    const response = await axios.get(`/notifications/send`)

  }

  return (
    <div className='block block-content block-transparent'>
      <h1>worker page</h1>


      <p>Notifications : {notificationsActive ? "true" : "false"}</p>
      <p>worker Active : {workerActive ? "true" : "false"}</p>
      <p>subscription Active : {subscriptionActive ? "true" : "false"}</p>

      <p>uuid : {localStorage.getItem(LOCALSTORAGEKEY)}</p>

      <hr />
      <p>{JSON.stringify(error)}</p>


      <hr />
      <InstallPWA />
      <button className='btn btn-alt-warning' onClick={sendNotify}>Enviar Notificações</button>
    </div>
  )
}

export default WorkerPage



const subscribeUser = async (swReg,applicationServerKey ,callbackSub, callbackError) => {
  try {
    console.log("init")

    const subscription = await swReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(applicationServerKey)
    });

    // console.log(subscription)

    const deviceInfo = getDeviceInfo();
    const deviceUuid = localStorage.getItem(LOCALSTORAGEKEY)

    // Send the subscription to your server
    const response = await axios.post(`/notifications/subscribe`, { subscription, deviceInfo, deviceUuid })
      .catch(err => {
        console.log(err)
        callbackError(err)
      })

    if (response.status === 200 || response.status === 201) {
      console.log("POST sent")
      callbackSub(true)
    }
    else {
      console.log(response)
      callbackError(response.data)
    }


  } catch (err) {
    console.error('Failed to subscribe the user: ', err);
    callbackError(err)
  }
};



/* UTILS */
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  // @ts-expect-error
  return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}


function getDeviceInfo() {
  return deviceDetect(getUA)

  // return { 
  //   userAgent: navigator.userAgent, 
  //   platform: navigator.platform,
  // };
}








const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <button
      className="btn btn-primary"
      id="setup_button"
      aria-label="Install app"
      title="Install app"
      onClick={onClick}
    >
      Install
    </button>
  );
};
