import React, { useEffect, useState } from "react";

import _ from "@lodash";
import { useParams } from "react-router";
import PeoplePicker from "../components/PeoplePicker";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "hooks";

import { userHasPermission, PermissionsList } from "config/permissions";
import {
  draftCompany,
  getCompanyById,
  saveCompany,
} from "./redux/companySlice";
import Redirect404 from "pages/components/Redirect404";

function CompanyNewPage(props) {
  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const LoginUserHasPermission = (param) => {
    if (param === "new") {
      return userHasPermission(
        LoginUser.profile.permissions,
        PermissionsList.USER_CREATE
      );
    } else {
      return userHasPermission(
        LoginUser.profile.permissions,
        PermissionsList.USER_CREATE
      );
    }
  };

  const dispatch = useDispatch();
  const { company } = useSelector(({ companyModule }) => {
    return companyModule.companyReducer;
  });

  const { form, setForm } = useForm(null);
  const [companyNotFound, setCompanyNotFound] = useState(false);
  const routeParams = useParams();

  useEffect(() => {
    const { companyId } = routeParams;

    if (companyId === "new") {
      dispatch(draftCompany());
    } else {
      dispatch(getCompanyById(companyId)).then((action) => {
        if (!action.payload) {
          setCompanyNotFound(true);
        }
      });
    }
  }, [dispatch, routeParams]);

  useEffect(() => {
    if ((company && !form) || (company && form && company.id !== form.id)) {
      setForm(company);
    }
  }, [form, company, setForm]);

  /* Save Button */
  const saveButtonClick = () => {
    dispatch(saveCompany(form)).then((action) => {
      if (action.payload && action.payload.id) {
        setForm(action.payload);
        props.history.push(`/company/${action.payload.id}/view`);
      }
    });
  };

  const handleSelectedUsers = (items) => {
    setForm({ ...form, users: items });
  };

  function canBeSubmitted() {
    return (
      form.name.length > 0 &&
      form.emailExtension.length > 0 &&
      !_.isEqual(company, form)
    );
  }

  const isObtainingData = () => {
    return (
      ((!company ||
        (company && routeParams.companyId !== company.id?.toString())) &&
        routeParams.companyId !== "new") ||
      !form
    );
  };

  //render
  if (companyNotFound || !LoginUserHasPermission(routeParams.companyId)) {
    return <Redirect404 />;
  }

  if (isObtainingData()) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h1>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn sticky-div">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              {company?.name ? company.name : "Novo Utilizador"}
            </h1>

            <button
              style={{ display: !canBeSubmitted() ? "none" : "inline" }}
              disabled={!canBeSubmitted()}
              className="btn btn-primary"
              onClick={saveButtonClick}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Informação básica</h2>
            <div className="row push">
              <div className="col-lg-4">
                <p className="text-muted">Informação da empresa</p>
              </div>
              <div className="col-lg-8 col-xl-5">
                <div className="form-group">
                  <label>Nome</label>
                  <input
                    className="form-control"
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    value={form.name}
                  />
                </div>

                <div className="form-group">
                  <label>Extensão de Email</label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setForm({ ...form, emailExtension: e.target.value })
                    }
                    value={form.emailExtension}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block block-rounded">
          <div className="block-content">
            <h2 className="content-heading pt-0">Utilizadores</h2>
            <div className="row push">
              <div className="col-12">
                <div className="form-group">
                  <label>Utilizadores</label>
                  <PeoplePicker
                    handleSelectedItems={(items) => {
                      handleSelectedUsers(items);
                    }}
                    selectedItems={form.users}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompanyNewPage;
