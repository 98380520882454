import IframeResizer from "iframe-resizer-react";
import React, { useState, useEffect } from "react";

function SupportPage() {
  const [iframeHeight, setIframeHeight] = useState(0);

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              Suporte e Sugestões de Melhoria
            </h1>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn mt-2">
        <div className="row">
          <div className="col-12">
            <div className="block block-rounded block-bordered p-0">
              <div className="block-content p-0">
                <IframeResizer
                  height={1100}
                  src="https://forms.clickup.com/24478153/f/qb0e9-3900/80KHIGZNAC1XOVK60B"
                  style={{ width: "1px", minWidth: "100%", border: 0, padding:0, margin:0 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SupportPage;
