import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt";

import { userHasPermission, PermissionsList } from "config/permissions";
import { getCompanyById } from "./redux/companySlice";
import Redirect404 from "pages/components/Redirect404";

function CompanyDetailsPage() {
  const dispatch = useDispatch();

  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const company = useSelector(({ companyModule }) => {
    return companyModule.companyReducer.company;
  });

  const [companyNotFound, setCompanyNotFound] = useState(false);
  const routeParams = useParams();

  useEffect(() => {
    const { companyId } = routeParams;
    dispatch(getCompanyById(companyId)).then((action) => {
      if (!action.payload) {
        setCompanyNotFound(true);
      }
    });
  }, [dispatch, routeParams]);

  if (companyNotFound) {
    return <Redirect404 />;
  }

  if (!company || routeParams.companyId !== company.id.toString()) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h1>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              {company.name}
            </h1>

            {userHasPermission(
              LoginUser.profile.permissions,
              PermissionsList.USER_EDIT
            ) && (
              <Link
                to={`/company/${company.id}`}
                className="btn btn-alt-warning"
              >
                Editar
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="block block-rounded">
              <div className="block-content">
                <h2 className="content-heading p-0">Informação</h2>

                <label>Nome</label>
                <p>{company.name}</p>

                <label>Extensão de Email</label>
                <p>{company.emailExtension}</p>

                <label>Criado por</label>
                <p>{company.createdBy}</p>

                <label>Criado em</label>
                <p>{moment(company.createdAt).calendar()}</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="block block-rounded">
              <div className="block-content">
                <h2 className="content-heading p-0">Lista de Utilizadores</h2>
                {company.users &&
                  company.users.map(({ user }) => {
                    return (
                      <p key={user.id}>
                        <Link to={`/users/${user.id}/view`}>{user.name}</Link>
                      </p>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompanyDetailsPage;
