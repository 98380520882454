import React, { useState, useEffect, useCallback } from 'react'

import TreeMenu, { ItemComponent } from 'react-simple-tree-menu';
import './Tree.css'
import { Badge } from '@fluentui/react-components';

function Tree(props) {

    const [data] = [props.data ?? [] ];
    //const [data, setData] = useState();
    const [activeElementOpenNodes,setActiveElementOpenNodes] = useState([])
    const [canRenderTree, setCanRenderTree] = useState(false);

    const nodeID = props.id ?? null;
    const itemClick = props.itemClick ?? ((item) => console.log(item));


    const renderTree = (data,OpenNodes) =>{
        return (
            /*
            <TreeMenu 
                data={data}
                hasSearch={false} 
                disableKeyboard= {true}
                onClickItem={itemClick}
                initialOpenNodes={OpenNodes}
            />
            */
            
            <TreeMenu 
                data={data}
                hasSearch={false} 
                disableKeyboard= {true}
                onClickItem={itemClick}
                initialOpenNodes={OpenNodes}>

                {({ items }) => (
                    <ul className="rstm-tree-item-group">
                        {items.map(
                            ({key, ...props}) => {
                                return (
                                    <div key={key}>
                                        <ItemComponent key={key} {...props} />
                                        {props.id === nodeID  && 
                                            (<Badge className='ms-2' appearance="filled" shape="rounded" color="success">Atual</Badge>)
                                        }
                                    </div>
                                )
                            }
                        )}
                    </ul>
                )}
            </TreeMenu>
            
        )
    }

    /*
    const searchTree = (element, id, key=[]) => {
        if(element.id === id){
             return key;
        }
        else if (element.nodes.length !== 0){
            
            if(key.length === 0){
                key.push(`${element.id}`)
            }
            else{
                const lastKey = key.at(-1);
                key.push(`${lastKey}/${element.id}`)
            }

            var result = null;
            element.nodes.forEach(element => {
                result = searchTree(element,id,key);
            });

            return result;
        }
        return null;
    }
    */

    const searchTree = useCallback( (element, id, key=[]) => {
        if(element.id === id){
            return key;
        }
        else if (element.nodes.length !== 0){
            
            if(key.length === 0){
                key.push(`${element.id}`)
            }
            else{
                const lastKey = key.at(-1);
                key.push(`${lastKey}/${element.id}`)
            }

            var result = null;
            element.nodes.forEach(element => {
                result = searchTree(element,id,key);
            });

            return result;
        }
        return null;
    }, [])

    useEffect(() => {
        if(data.length !== 0){
            const path = searchTree(data[0] ,nodeID);
            //console.log("path",path);
            setActiveElementOpenNodes(path)
            setCanRenderTree(true)
        }
        
    }, [data,nodeID,searchTree])


    return (
        <div>
            <div className="block block-rounded">
                <div className="block-content p-0">
                    { canRenderTree &&
                        renderTree(data,activeElementOpenNodes)
                    }
                </div>

            </div>
        </div>
    )
}

export default Tree