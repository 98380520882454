import { Badge } from "@fluentui/react-components";
import React from "react";

function ApprovalStatusBadge({ status }) {
  switch (status) {
    case "DRAFT":
      return (
        <Badge appearance="filled" shape="rounded" color="informative">
          Rascunho
        </Badge>
      );
    case "RUNNING":
      return (
        <Badge appearance="filled" shape="rounded" color="warning">
          A Decorrer
        </Badge>
      );
    case "APPROVED":
      return (
        <Badge appearance="filled" shape="rounded" color="success">
          Aprovada
        </Badge>
      );
    case "REJECTED":
      return (
        <Badge appearance="filled" shape="rounded" color="danger">
          Rejeitada
        </Badge>
      );
    case "CANCELED":
      return (
        <Badge appearance="filled" shape="rounded" color="important">
          Cancelada
        </Badge>
      );
    default:
      return (
        <Badge appearance="filled" shape="rounded" color="subtle">
          Outro
        </Badge>
      );
  }
}

export default ApprovalStatusBadge;
