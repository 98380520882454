import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const endpoint = "/company"

export const getCompanies = createAsyncThunk("company/getCompanies",
    async () => {
        const response = await axios.get(`${endpoint}`);
        return await response.data ?? null;
    }
);

export const getCompanyById = createAsyncThunk("company/getCompanyById",
    async (id) => {
        const response = await axios.get(`${endpoint}/${id}`);
        return await response.data ?? null;
    }
)

export const draftCompany = createAsyncThunk("company/draftCompany",
    async () => {
        return { 
            name : "",
            emailExtension : "",
            users: []
        }
    }
)

export const saveCompany = createAsyncThunk("company/saveCompany",
    async (companyData) =>  {

        //destructure for api
        if(companyData.users){
            companyData.users = companyData.users.map(user => user.user)
        }

        try {
            const response = !companyData.id 
            ? await axios.post(`${endpoint}`, companyData)
            : await axios.put(`${endpoint}/${companyData.id}`,companyData);
            
            return await response.data;
        } catch (error) {
            throw error
        }
    }
)


export const deleteCompany = createAsyncThunk("company/deleteCompany",
async (id) => {
    await axios.delete(`${endpoint}/${id}`);
});


const companySlice = createSlice({
    name: "company",
    initialState: {
        companies: [],
        company: null,
    },
    reducers: {
    },
    extraReducers: {
        [getCompanies.fulfilled]: (state, action) => { state.companies = action.payload },
        [getCompanies.rejected]: (state, action) => error(action.error.message),

        [getCompanyById.fulfilled]: (state, action) => {
            state.company = action.payload
            
            //structure for poeplepicker
            
            state.company.users = state.company.users.map(user => {
                return {user: user}
            })
        },
        [getCompanyById.rejected]: (state, action) => error(action.payload.message),

        [draftCompany.fulfilled]: (state, action) => {state.company = action.payload},
        [draftCompany.rejected]: (state, action) => error("Não foi possivel inicar a criação de uma empresa"),
        
        [saveCompany.fulfilled]: (state, action) => action.payload,
        [saveCompany.rejected]: (state, action) => error(action.payload.message),


        [deleteCompany.fulfilled]: (state, action) => { },
        [deleteCompany.rejected]: (state, action) => error(action.error.message),

    }
});

export default companySlice.reducer;


const error = (message) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: message,
        width: "800px",
    })
}