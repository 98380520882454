import React, { useEffect, useState } from 'react'
import ApprovalStatusBadge from 'utils/components/ApprovalStatusBadge';




{/* PLACEHOLDER

<div className="carousel-item-content">
    <h5>Update Title 2</h5>
    <p>This is the second update with some detailed text.</p>
    <img
        src="https://via.placeholder.com/800x400"
        className="d-block w-100"
        alt="Second update"
    />
    <p>Additional text for the second update.</p>
</div>, 

*/}
const slides_1_6_7 = [
    <div className="carousel-item-content d-flex flex-column">
        <h5>Reordenação de Etapas</h5>
        <p>Durante a criação e edição de uma <b>Aprovação</b> ou um <b>Modelo</b> pode reordenar as etapas, ao arrastar uma etapa para a posição desejada. </p>
        <img
            src="/assets/version/reorder.gif"
            className="mx-auto w-75"
            alt="Reordenação de Etapas"
        />
    </div>,

    <div className="carousel-item-content d-flex flex-column">
        <h5>Filtrar por Estado</h5>
        <p>Nas listagens as Aprovações recebidas/enviadas/conhecimentos, é possível filtrar a lista pelo <b>Estados</b> das aprovações</p>
        <img
            src="/assets/version/filter.gif"
            className="mx-auto w-75"
            alt="Filtrar por Estado"
        />
    </div>,

    <div className="carousel-item-content d-flex flex-column">
        <h5>Aprovações Arquivadas</h5>
        <p>Todas as aprovações com mais de <b>30 Dias</b> e com o estado {ApprovalStatusBadge({ status: "APPROVED" })} , {ApprovalStatusBadge({ status: "REJECTED" })} ou  {ApprovalStatusBadge({ status: "CANCELED" })} são automaticamente arquivadas.
        </p>
        <img
            src="/assets/version/30plus.gif"
            className="mx-auto w-75"
            alt="Aprovações Arquivadas"
        />
    </div>,



    <div className="carousel-item-content d-flex flex-column">
        <h5>Campos Preenchíveis por Etapa</h5>
        <p>Durante a criação de <b>Modelos</b> é possível criar etapas com campos que serão preenchíveis durante o processo de aprovação.
        </p>
        <img
            src="/assets/version/StepField.gif"
            className="mx-auto w-75"
            alt="Campos Preenchíveis por Etapa"
            loading='lazy'
        />

        <p className='mt-5'> Durante uma <b>Etapa Preenchível</b> apenas é necessário 1 aprovavação para conclusão da etapa e é possível preencher os campos indicados anteriormente </p>
        <img
            src="/assets/version/approvalStepField.gif"
            className="mx-auto w-75"
            alt="Campos Preenchíveis por Etapa2"
            loading='lazy'
        />

    </div>,


    <div className="carousel-item-content d-flex flex-column">
        <h5>Recuprar Etapas Apagados</h5>
        <p>Durante a <b>Criação / Edição</b> de uma aprovação com origem a partir de um modelo, é possível restaurar etapas predefinidas no mesmo.</p>
        <img
            src="/assets/version/importTemplateStep.gif"
            className="mx-auto w-75"
            alt="Recuprar Etapas Apagados"
        />

    </div>,
];


const slides_1_7_1 = [
    <div className="carousel-item-content d-flex flex-column">
        <h5>Comentários de Aprovação</h5>
        <p>Agora é possível adicionar e ver comentários em cada aprovação, permitindo uma comunicação fácil e direta entre utilizadores.
            <br />
            Ao selecionar a mensagem antes de enviar, pode formatá-la para destacar informações.
        </p>

        <img
            src="/assets/version/1.7.1/chat.gif"
            className="mx-auto w-75"
            alt="Chat"
        />

    </div>,

    <div className="carousel-item-content d-flex flex-column">
        <h5>Notificações</h5>
        <p>A nova funcionalidade de Notificações é a forma mais fácil manter-se atualizado sobre o que acontece numa aprovação do início ao fim.</p>

        <img
            src="/assets/version/1.7.1/notification.gif"
            className="mx-auto w-75"
            alt="Chat"
        />

        <p></p>

        <details>
            <summary style={{ fontSize: 18, fontWeight: "bold" }}>Como Ativar ?</summary>
            <p></p>
            <p>Para ativar as Notificações, Clique no ícone do Utilizador no canto superior direto da Aplicação. Em seguida, Clique em <b>Meu Perfil</b>. Na secção de <b>Notificações</b>, ative as notificações e selecione as ações que pretende ser notificado. Por fim, clique em <b>Guardar Alterações.</b></p>

            <img
                src="/assets/version/1.7.1/profile.gif"
                className="mx-auto d-block w-75"
                alt="Chat"
            />

        </details>
        <p></p>


    </div>,

];









const LOCALSTORAGEKEY = "APP_VERSION_NEWS"
const LAST_VERSION = "1.7.1" // Atualziar isto quando necessário aparacer a modal

const VERSIONS = [
    ["1.6.7", slides_1_6_7,],
    ["1.7.1", slides_1_7_1,]
]

function WhatsNew() {
    const [showModal, setShowModal] = useState(false);

    const [slides, setSlides] = useState([])
    const [canClose, setCanClose] = useState(false); // impede fechar acidentalmente

    const close = () => {
        if (canClose === false)
            return

        localStorage.setItem(LOCALSTORAGEKEY, LAST_VERSION);
        setShowModal(false)
    }

    useEffect(() => {
        const storedVersion = localStorage.getItem(LOCALSTORAGEKEY);
        if (storedVersion !== LAST_VERSION) {

            let found = false;
            let toAdd = [];
            for (const i of VERSIONS) {

                if (found || storedVersion == null) {
                    i[1].forEach(slide => toAdd.push(slide))
                }
                else if (i[0] === storedVersion) {
                    found = true;
                }
            }

            console.log(toAdd)
            setSlides(toAdd)

            setShowModal(true);
            setTimeout(() => {
                setCanClose(true);
            }, 1000);
        }
    }, []);



    const [currentSlide, setCurrentSlide] = useState(0);
    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
    };



    return (
        <div>
            {showModal &&
                <div className="modal" id="modal-default-vcenter" tabIndex="-1" aria-labelledby="modal-default-vcenter"
                    style={{ display: "block" }}
                    onClick={close}
                >
                    <div className="modal-dialog modal-fullscreen-md-down modal-lg  modal-dialog-popin" role="document"
                        onClick={(e) => e.stopPropagation() /* Prevent closing when clicking inside modal */}
                    >
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h2 className="modal-title">Novidades</h2>

                                <div>
                                    {currentSlide != 0 && <button className="btn btn-sm btn-secondary mx-2" onClick={prevSlide}>Anterior</button>}

                                    <button className="btn btn-sm btn-primary"
                                        onClick={currentSlide < slides.length - 1 ? nextSlide : close}
                                    >{currentSlide < slides.length - 1 ? "Seguinte" : "Continuar"}</button>
                                </div>

                            </div>
                            <div className="modal-body">


                                {slides.map((slide, index) => (
                                    <div className={`carousel-item ${index === currentSlide ? 'active' : ''}`} key={index}>
                                        {slide}
                                    </div>
                                ))}

                            </div>

                        </div>
                    </div>
                </div>
            }


            {showModal && <div className="modal-backdrop fade show" onClick={close}></div>}
        </div>
    )
}

export default WhatsNew

