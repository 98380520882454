import React from "react";
import moment from "moment";
import "moment/locale/pt";
import { Icon } from "@fluentui/react"
import { Persona } from "@fluentui/react-persona";
import {
  Popover,
  PopoverSurface,
  PopoverTrigger,
} from "@fluentui/react-components";

function DetailStepUser({ approver, status }) {
  const getPopOverBackgroundColor = () => {
    if (approver.decision === null) {
      return "#e69f17";
    } else if (approver.decision === 1) {
      return "#6f9c40";
    } else if (approver.decision === 0) {
      return "#e04f1a";
    }
  };

  const renderPopOver = () => {
    if (approver.decision === null) {
      return (
        <div
          className={"bg-warning"}
          style={{
            margin: 0,
            padding: 10,
          }}
        >
          <span className="h5 text-white">
            <i className="fa fa-clock me-1"></i> Por responder
          </span>
          <span className="d-block text-white">
            {approver.knowledgeDate && (
              <div className="mt-1">
                Visto em: {moment(approver.knowledgeDate).format("LLL")}
              </div>
            )}
          </span>
        </div>
      );
    } else if (approver.decision === 1) {
      return (
        <div
          className={"bg-success"}
          style={{
            margin: 0,
            padding: 10,
          }}
        >
          <span className="h5 text-white">
            <i className="fa fa-check me-1"></i> Aprovou
          </span>
          <span className="d-block text-white">
            {moment(approver.decisionDate).format("LLL")}
            {approver.decisionText && (
              <div>
                <br />
                <span className="h6 text-white">
                  <i className="fa fa-comment me-1"></i>
                  Observação
                </span>
                <br />
                {approver.decisionText}
              </div>
            )}
            {approver.knowledgeDate && (
              <div className="mt-1">
                Visto em: {moment(approver.knowledgeDate).format("LLL")}
              </div>
            )}
          </span>
        </div>
      );
    } else if (approver.decision === 0) {
      return (
        <div
          className={"bg-danger"}
          style={{
            margin: 0,
            padding: 10,
          }}
        >
          <span className="h5 text-white">
            <i className="fa fa-times me-1"></i> Rejeitou
          </span>
          <span className="d-block text-white">
            {moment(approver.decisionDate).format("LLL")}
            {approver.decisionText && (
              <div>
                <br />
                <span className="h6 text-white">
                  <i className="fa fa-comment me-1"></i>
                  Observação
                </span>
                <br />
                {approver.decisionText}
              </div>
            )}
            {approver.knowledgeDate && (
              <div className="mt-1">
                Visto em: {moment(approver.knowledgeDate).format("LLL")}
              </div>
            )}
          </span>
        </div>
      );
    }
  };

  const isDraft = () => status === "DRAFT"


  const renderDecisionStatus = () => {
    if (isDraft())
      return "";

    switch (approver.decision) {
      case 0: return "Recusou";
      case 1: return "Aprovou";
      default: return "Por responder";
    }
  }

  const renderDecisionStatuspresence = () => {
    if (isDraft())
      return null;
    
    switch (approver.decision) {
      case 0: return { status: "busy" };
      case 1: return { status: "available" };
      default: return { status: "unknown" };
    }
  }

  return (
    <Popover withArrow openOnHover={!isDraft()} mouseLeaveDelay={0} size="small" positioning="below">
      <PopoverTrigger>
        <Persona
          className="mt-2"
          onRenderPrimaryText={approver?.user?.name ?? approver?.guest?.email}
          tertiaryText={approver.decisionText && <Icon iconName="message" />}
          textAlignment={"center"}
          name={approver?.user?.name || approver?.guest?.email}
          avatar={{
            image: {
              src: approver?.user?.profilePhoto,
            },
          }}
          size={"large"}
          secondaryText={renderDecisionStatus()}
          presence={renderDecisionStatuspresence()}
        />
      </PopoverTrigger>



      <PopoverSurface
        style={{
          backgroundColor: getPopOverBackgroundColor(),
        }}
      >
        {renderPopOver()}
      </PopoverSurface>
    </Popover>
  );
}

export default DetailStepUser;
