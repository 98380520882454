import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "@lodash";
import moment from "moment";
import "moment/locale/pt";

import { deleteCompany, getCompanies } from "./redux/companySlice";
import withReducer from "store/withReducer";
import reducer from "./redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { userHasPermission, PermissionsList } from "config/permissions";
import Table from "pages/components/Table/Table";

function CompanyPage() {
  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const dispatch = useDispatch();

  const companies = useSelector(({ companyModule }) => {
    return companyModule.companyReducer.companies;
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(companies);
  const [search, setSearch] = useState("");

  const header = [
    { name: "#", style: "text-center" },
    { name: "Nome" },
    { name: "Extensão de Email" },
    { name: "Criado", style: "text-center" },
    { name: "Ações", style: "text-center" },
  ];

  const renderTableBodyData = () => {
    return data.map((company) => {
      return (
        <tr key={company.id}>
          <td className="text-center">{company.id}</td>
          <td>{company.name ?? ""}</td>
          <td>{company.emailExtension ?? ""}</td>
          <td className="text-center">
            {moment(company.createdAt).calendar()}
          </td>
          <td className="text-center d-flex flex-column flex-md-row">
            <Link
              to={`/company/${company.id}/view`}
              className="btn btn-sm btn-primary m-1"
            >
              <i className="fa fa-eye"></i> Ver
            </Link>

            {userHasPermission(
              LoginUser.profile.permissions,
              PermissionsList.USER_EDIT
            ) && (
              <Link
                to={`/company/${company.id}`}
                className="btn btn-sm btn-warning m-1"
              >
                <i className="fa fa-edit"></i> Editar
              </Link>
            )}

            {userHasPermission(
              LoginUser.profile.permissions,
              PermissionsList.USER_DELETE
            ) && (
              <button
                className="btn btn-sm btn-danger m-1"
                onClick={() => {
                  confirmDelete(company.id);
                }}
              >
                <i className="fa fa-fw fa-trash"></i> Eliminar
              </button>
            )}
          </td>
        </tr>
      );
    });
  };

  const confirmDelete = (userId) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "info",
      title: "Tem a certeza que pertende eliminar esta Empresa?",
      confirmButtonText: "Eliminar",
      denyButtonText: "Cancelar",
      showDenyButton: true,
      width: "800px",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCompany(userId)).then((res) => {
          if (!res.error) {
            dispatch(getCompanies());
          }
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getCompanies()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (search.length !== 0) {
      setData(
        _.filter(companies, (item) => {
          return (
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.emailExtension?.toLowerCase().includes(search.toLowerCase())
          );
        })
      );
    } else {
      setData(companies);
    }
  }, [companies, search]);

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              Empresas
            </h1>
            <nav className="flex-sm-00-auto ms-sm-3 mt-3 mt-sm-0">
              {userHasPermission(
                LoginUser.profile.permissions,
                PermissionsList.USER_CREATE
              ) && (
                <Link to="/company/new" className="btn btn-primary">
                  Criar nova Empresa
                </Link>
              )}
            </nav>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        <Table
          header={header}
          data={{ data, setData }}
          loading={{ loading, setLoading }}
          search={{ search, setSearch }}
          renderTableBodyData={renderTableBodyData}
          serachLabel="Procure por uma empresa..."
        />
      </div>
    </React.Fragment>
  );
}

export default withReducer("companyModule", reducer)(CompanyPage);
