import React, { useEffect, useState } from "react";
import { Persona } from "@fluentui/react-persona";
import { Link } from "react-router-dom";
import _ from "@lodash";
import withReducer from "store/withReducer";
import reducer from "./store";
import moment from "moment";
import "moment/locale/pt";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovalsSent,
  setApprovalsSearchText,
} from "./store/approvalsSlice";
import { userHasPermission, PermissionsList } from "config/permissions";
import { deleteApproval } from "./store/approvalSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Badge } from "@fluentui/react-components";
import ApprovalStatusBadge from "utils/components/ApprovalStatusBadge";
import TableBodyLoading from "pages/components/Table/TableBodyLoading";
import { APPROVAL_LINK_TYPE, getApprovalLink } from "utils/link-generator";

import { filterArchived } from "pages/approvals/components/ArchiveFilter"


const ApprovalsSentPage = () => {
  const dispatch = useDispatch();
  const approvals = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalsReducer.approvals;
  });
  const searchText = useSelector(
    ({ approvalsModule }) => approvalsModule.approvalsReducer.searchText
  );

  const LoginUser = useSelector((root) => {
    return root.userReducer.user;
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(approvals);
  const [archivedData, setArchivedData] = useState([]);
  const [selectFilter, setSelectFilter] = useState(null)

  useEffect(() => {
    dispatch(getApprovalsSent()).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    const { active, archived } = filterArchived(approvals);
    setData(active)
    setArchivedData(archived)
  }, [approvals])


  // useEffect(() => {
  //   if (searchText.length !== 0) {
  //     setData(
  //       _.filter(approvals, (item) =>
  //         item.title.toLowerCase().includes(searchText.toLowerCase())
  //       )
  //     );
  //   } else {
  //     setData(approvals);
  //   }
  // }, [approvals, searchText]);



  const renderTableBody = (data) => {
    if (loading) {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6} className="p-0">
              <div className="bg-body-light animated fadeIn">
                <div className="content content-full">
                  <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                    <h2 className="flex-sm-fill fw-normal mt-2 mb-0">
                      <i className="fa fa-circle-notch fa-spin text-primary"></i>{" "}
                      A obter dados do servidor...
                    </h2>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <TableBodyLoading rows={5} columns={6} />
        </React.Fragment>
      );
    } else if (!data || (data && data.length === 0)) {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6} className="p-0">
              <div className="bg-body-light animated fadeIn">
                <div className="content content-full">
                  <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                    <h4 className="flex-sm-fill fw-normal mt-2 mb-0">
                      Sem resultados
                    </h4>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </React.Fragment>
      );
    } else {
      return data
        .filter(approval => selectFilter === null ?  approval : approval.status === selectFilter )
        .filter(approval => approval.title.toLowerCase().includes(searchText.toLowerCase()))
        .map((approval) => {
          return (
            <tr key={approval.id}>
              <td className="text-center">{approval.id}</td>
              <td className="fw-semibold">
                <Link to={getApprovalLink(APPROVAL_LINK_TYPE.VIEW, approval.id)}>
                  {approval.title}
                </Link>
              </td>
              <td>
                <ApprovalStatusBadge status={approval.status} />
              </td>
              <td className="text-center">
                {moment(approval.createdAt).calendar()}
              </td>
              <td>
                <Persona
                  textAlignment={"center"}
                  avatar={{
                    image: {
                      src: approval?.user?.profilePhoto,
                    },
                  }}
                  secondaryText={approval?.user?.email}
                  name={approval?.user?.name}
                  size={"medium"}
                />
              </td>
              <td className="d-flex flex-column flex-md-row">
                {userHasPermission(
                  LoginUser.profile.permissions,
                  PermissionsList.APPROVAL_VIEW
                ) && (
                    <Link
                      className="btn btn-sm btn-primary m-1"
                      to={getApprovalLink(APPROVAL_LINK_TYPE.VIEW, approval.id)}
                    >
                      <i className="fa fa-eye"></i> Ver
                    </Link>
                  )}

                {approval.status === "DRAFT" &&
                  userHasPermission(
                    LoginUser.profile.permissions,
                    PermissionsList.APPROVAL_EDIT
                  ) && (
                    <Link
                      className="btn btn-sm btn-warning m-1"
                      to={getApprovalLink(APPROVAL_LINK_TYPE.EDIT, approval.id)}
                    >
                      <i className="fa fa-edit"></i> Editar
                    </Link>
                  )}

                {approval.status === "DRAFT" &&
                  userHasPermission(
                    LoginUser.profile.permissions,
                    PermissionsList.APPROVAL_DELETE
                  ) && (
                    <button
                      className="btn btn-sm btn-danger m-1"
                      onClick={() => {
                        confirmDelete(approval.id);
                      }}
                    >
                      <i className="fa fa-fw fa-trash"></i> Eliminar
                    </button>
                  )}
              </td>
            </tr>
          );
        });
    }
  };

  const confirmDelete = (id) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "info",
      title: "Tem a certeza que pertende eliminar esta Aprovação?",
      confirmButtonText: "Eliminar",
      denyButtonText: "Cancelar",
      showDenyButton: true,
      width: "800px",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteApproval(id)).then((res) => {
          if (!res.error) {
            dispatch(getApprovalsSent());
          }
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h2 className="flex-sm-fill mt-2 mb-0 mb-sm-2">
              Aprovações Enviadas
            </h2>
            <nav className="flex-sm-00-auto ms-sm-3 mt-3 mt-sm-0">
              {userHasPermission(
                LoginUser.profile.permissions,
                PermissionsList.APPROVAL_CREATE
              ) && (
                  <Link
                    to={getApprovalLink(APPROVAL_LINK_TYPE.CREATE)}
                    className="btn btn-primary"
                  >
                    Criar nova aprovação
                  </Link>
                )}
            </nav>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        <h2 className="content-heading">Todos os pedidos</h2>

        <div className="form-group row items-push mb-0">
          <div className="col-sm-6 col-xl-3">
            <div className="input-group">
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>

              <input
                type="text"
                className="form-control border-start-0"
                onChange={(e) => {
                  dispatch(setApprovalsSearchText(e));
                }}
                value={searchText}
                placeholder="Procure pelo um título..."
              />

            </div>
          </div>

          <div className="col-sm-6 col-xl-4 offset-xl-5">
            <div className="input-group">
              <span className="input-group-text">
                <i className="fa-solid fa-filter"></i>
              </span>


              <select className="form-select" onChange={e => {
                if(e.target.value === "ALL")
                  setSelectFilter(null)
                else 
                  setSelectFilter(e.target.value)
              }}>
                <option value="ALL" disabled selected>Filtrar por um estado</option>
                <option value="ALL">Todas</option>
                <option value="RUNNING">A decorrer</option>
                <option value="DRAFT">Rascunhos</option>
                <option value="APPROVED">Aprovadas</option>
                <option value="REJECTED">Rejeitadas</option>
                <option value="CANCELED">Canceladas</option>
              </select>

            </div>
          </div>
        </div>


        <div className="block block-rounded">
          <div className="block-content p-0">
            <div className="table-responsive">
              <table className="table table-striped table-vcenter m-0">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Título</th>
                    <th>Estado</th>
                    <th className="text-center">Data</th>
                    <th>Pedido por</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>{renderTableBody(data)}</tbody>
              </table>
            </div>
          </div>
        </div>



        {/* Archived Accordion */}
        <div className="accordion block" id="ArchivedAccordion">
          <div className="block block-rounded mb-1 ">

            <div className="accordion-item block-header-default">
              <button className="accordion-button  fw-semibold collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ backgroundColor: "transparent", color: "initial" }}>
                Concluídas há mais de 30 dias ({archivedData
                  .filter(approval => selectFilter === null ?  approval : approval.status === selectFilter )
                  .filter(approval => approval.title.toLowerCase().includes(searchText.toLowerCase()))
                  .length})
              </button>

              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-parent="#ArchivedAccordion">
                <div className="accordion-body block-content bg-white pb-0 px-1 pt-3">

                  <div className="block block-rounded mb-0">
                    <div className="block-content p-0">
                      <div className="table-responsive">
                        <table className="table table-striped table-vcenter m-0">
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Título</th>
                              <th>Estado</th>
                              <th className="text-center">Data</th>
                              <th>Pedido por</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>{renderTableBody(archivedData)}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </React.Fragment>
  );
};

export default withReducer("approvalsModule", reducer)(ApprovalsSentPage);
