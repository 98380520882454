import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, useLocation, withRouter, matchPath, Redirect } from 'react-router-dom';
import App from './App';
import LoginPage from './pages/LoginPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import Page404 from './pages/Page404';
import GuestApprovalDetailPage from "./pages/approvals/GuestApprovalDetailPage"

import { updateUserTags } from "store/userSlice";
 

const AppWrapper = () => {

    let location = useLocation();
    const dispatch = useDispatch();

    // update user tags on page load
    useEffect(() => {
        dispatch(updateUserTags());
    }, [])
    

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    const user = useSelector((root) => {
        return root.userReducer;
    });

    if(user.isLoggedIn == false) {
        const guestApproval = matchPath(location.pathname,{path:"/approvals/:approvalId/view", exact: true});
        if(guestApproval){
            const token = new URLSearchParams(location.search).get('token')
            if(token) 
                return <GuestApprovalDetailPage id={guestApproval.params?.approvalId} token={token}/>
        }

        switch (location.pathname) {
            case '/404':
                return <Route path="/404" component={Page404} />
            case '/resetPassword': 
                return <ResetPasswordPage />
            default:
                return <LoginPage />
        }
    }

    switch (location.pathname) {
        case '/error':
            return <h1>ERROR</h1>//<Route path="/error" component={Error} />
        case '/404':
            return <Route path="/404" component={Page404}  />
        default:
            return <App />;
    }
}

export default withRouter(AppWrapper);
