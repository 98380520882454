import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function InfoPage() {
  const MySwal = withReactContent(Swal);

  const [licenseData, setLicenseData] = useState(null);

  const getData = async () => {
    const response = await axios.get(`/license`).catch((err) => {
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: "<p>Não foi possível obter os dados</p>",
        width: "800px",
      });
      throw err;
    });

    if (response.status == 200) {
      setLicenseData(response.data.LICENSE);
    }
  };

  useEffect(() => {
    getData();
    return () => setLicenseData(null);
  }, []);

  if (!licenseData) {
    return (
      <React.Fragment>
        <div className="bg-body-light animated fadeIn">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h2 className="flex-sm-fill fw-normal mt-2 mb-0 mb-sm-2">
                <i className="fa fa-circle-notch fa-spin text-primary"></i> A
                obter dados do servidor...
              </h2>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h2 className="flex-sm-fill fw-normal mt-2 mb-0 mb-sm-2">
              Informações
            </h2>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn mt-2">
        <div className="row">
          <div className="col-12">
            <div className="block block-rounded block-bordered">
              <div className="block-header block-header-default">
                <h3 className="block-title">Licença</h3>
              </div>

              <div className="block-content">
                <label>Licenciado para</label>
                <p>{licenseData.LICENSE_EMITED_TO}</p>

                <label>Número Máximo de Utilizadores</label>
                <p>
                  {licenseData.IGNORE_USER_MAX ? (
                    // ? <b style={{fontSize:24}}>{String.fromCharCode(8734)}</b>
                    <i className="fa fa-2x fa-solid fa-infinity fa-flip"></i>
                  ) : (
                    licenseData.USER_MAX
                  )}
                </p>

                {Object.keys(licenseData).filter((x) => x.startsWith("module"))
                  .length > 0 && <label>Modulos Ativos</label>}
                <p>
                  {licenseData.moduleAzureEnabled && " Sincronização com Azure"}
                </p>
                <p>
                  {licenseData.moduleOutlookAddinEnabled && " Outlook Addin"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <pre> {JSON.stringify(licenseData,null,2)} </pre> */}
    </React.Fragment>
  );
}

export default InfoPage;
