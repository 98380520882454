import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Input,
  RadioGroup,
  Radio,
  makeStyles,
  Label,
  Tag,
  TagGroup,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  formItem: {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
  },
  tagGroup: {
    marginTop: "10px",
    flexWrap: "wrap",
    flexDirection: "row",
    rowGap: "10px",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  inputWithButton: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: "10px",
  },
});

const FieldModal = forwardRef(({ onAddField, isOpen, closeModal }, ref) => {
  const styles = useStyles();
  const [fieldIndex, setFieldIndex] = useState(null);
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState("text");
  const [fieldValue, setFieldValue] = useState("");
  const [fieldValues, setFieldValues] = useState([]);

  useImperativeHandle(ref, () => ({
    openModal() {
      setFieldIndex(null);
      setFieldName("");
      setFieldType("text");
      setFieldValue("");
      setFieldValues([]);
    },
    openModalEdit(fieldIndex, name, type, values) {
      setFieldIndex(fieldIndex);
      setFieldName(name);
      setFieldType(type);
      setFieldValue("");
      setFieldValues(values ? values.split("|") : []);
    },
  }));

  useEffect(() => {
    if (fieldType !== "radio" && fieldType !== "checkbox") {
      setFieldValues([]);
    }
  }, [fieldType]);

  const handleAddField = () => {
    onAddField(
      fieldIndex,
      fieldName,
      fieldType,
      fieldValues.length > 0 ? fieldValues.join("|") : null
    );
  };

  const handleAddValue = () => {
    if (fieldValue && !fieldValues.includes(fieldValue)) {
      setFieldValues([...fieldValues, fieldValue]);
      setFieldValue("");
    }
  };

  const handleRemoveValue = (valueToRemove) => {
    setFieldValues(fieldValues.filter((value) => value !== valueToRemove));
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Adicionar Novo Campo</DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <div className={styles.formItem}>
              <Label required>Nome do Campo</Label>
              <Input
                placeholder="Nome do Campo"
                value={fieldName}
                onChange={(e, data) => setFieldName(data.value)}
                required
              />
            </div>
            <div className={styles.formItem}>
              <Label required>Tipo do Campo</Label>
              <RadioGroup
                value={fieldType}
                onChange={(e, data) => setFieldType(data.value)}
              >
                <Radio label="Texto Curto" value="text" />
                <Radio label="Texto Longo" value="textarea" />
                <Radio label="Seleção" value="radio" />
                <Radio label="Seleção Multipla" value="checkbox" />
              </RadioGroup>
            </div>
            {fieldType === "radio" || fieldType === "checkbox" ? (
              <div className={styles.formItem}>
                <Label>Valores para o Campo</Label>
                <div className={styles.inputWithButton}>
                  <Input
                    placeholder="Adicionar valor"
                    value={fieldValue}
                    onChange={(e, data) => setFieldValue(data.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleAddValue()}
                  />
                  <Button className={styles.button} onClick={handleAddValue}>
                    Adicionar
                  </Button>
                </div>
                <TagGroup className={styles.tagGroup} onDismiss={(e, { value }) => handleRemoveValue(value)}>
                  {fieldValues.map((value, index) => (
                    <Tag
                      className={styles.tag}
                      key={value}
                      value={value}
                      dismissible
                    >
                      {value}
                    </Tag>
                  ))}
                </TagGroup>
              </div>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeModal()}>Cancelar</Button>
            <Button onClick={handleAddField} appearance="primary">
              Inserir campo
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});

export default FieldModal;
