import { useEffect } from "react"
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import history from "./@history";

import Header from "./layout/Header";
import Sidebar from "./layout/Sidebar";
import Footer from "./layout/Footer";

import authHeader from "utils/auth-header";
import { apiUrl } from "environmentConfig";
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from "@azure/msal-react";
import { generateRoutes } from "./config/routes";
import { interceptPermissionsChanged } from "./config/interceptor";
import WhatsNew from "pages/components/WhatsNew";
import { SideOverlayProvider } from "layout/SideOverlay";


function App() {
  axios.defaults.baseURL = apiUrl;

  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  axios.defaults.headers.common = authHeader();

  const user = useSelector((root) => {
    return root.userReducer;
  });
  const routes = generateRoutes(user);


  const dispatch = useDispatch();
  const { instance } = useMsal();
  interceptPermissionsChanged.getInstance(dispatch, instance)


  useEffect(() => {
    // Ensure jQuery and Dashmix are available globally
    if (window.jQuery && window.Dashmix) {
      window.Dashmix.init();
    } else {
      console.error("jQuery or Dashmix not found.");
    }
  }, []);

  return (
    <div
      id="page-container"
      className="sidebar-o sidebar-dark side-scroll page-header-fixed main-content-narrow bg-light enable-page-overlay"
    >

      {/* sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed page-header-scroll side-trans-enabled */}


      <SideOverlayProvider>


        <Sidebar routes={routes} />

        <Header />

        <WhatsNew />

        <main id="main-container">
          <Switch history={history}>
            {routes.map((router, index) => {
              if (router.exact) {
                return (
                  <Route
                    key={`router${index}`}
                    path={router.path}
                    exact
                    component={router.component}
                  />
                );
              }

              return (
                <Route
                  key={`router${index}`}
                  path={router.path}
                  component={router.component}
                />
              );
            })}
            <Redirect to='/404' />
          </Switch>
        </main>

        <Footer />

      </SideOverlayProvider>
    </div>
  );
}

export default withRouter(App);
