import {createSlice,createAsyncThunk,} from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const getApprovalsReceived = createAsyncThunk(
  "approvals/getApprovals/Received",
  async () => {
    const response = await axios.get("/approval/received");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsSent = createAsyncThunk(
  "approvals/getApprovals/Sent",
  async () => {
    const response = await axios.get("/approval/sent");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsKnowledge = createAsyncThunk(
  "approvals/getApprovals/knowledge",
  async () => {
    const response = await axios.get("/approval/connoisseur");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsNeededAction = createAsyncThunk(
  "approvals/getApprovals/neededAction",
  async () => {
    const response = await axios.get("/approval/received/neededAction");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsNeededKnowledge = createAsyncThunk(
  "approvals/getApprovals/neededKnowledge",
  async () => {
    const response = await axios.get("/approval/connoisseur/neededKnowledge");
    const data = await response.data.approvals;

    return data;
  }
);

export const getApprovalsByTag = createAsyncThunk(
  "approvals/getApprovals/tag",
  async (tagId) => {
    const response = await axios.get(`/tags/${tagId}`);
    const data = await response.data;

    return data;
  }
);

export const addTagsToApproval = createAsyncThunk("approvals/addTags", async (data) => {
  const response =  await axios.post(`/tags/approval/${data.id}/addTagsToApproval`, data.tags)
  return response.data;
})

const approvalsSlice = createSlice({
  name: "approvals",
  initialState: {
    searchText: "",
    approvals: [],
    approvalsConnoisseur: [],
    appovalsNeededAction: [],
    appovalsNeededKnowledge: [],
    approvalsByTag: [],
  },
  reducers: {
    setApprovalsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getApprovalsReceived.fulfilled]: (state, action) => {
      state.approvals = action.payload;
    },
    [getApprovalsReceived.rejected]: (state, action) =>{
      state.approvals = null;
      error(action.error.message)
    },

    [getApprovalsSent.fulfilled]: (state, action) => {
      state.approvals = action.payload;
    },
    [getApprovalsSent.rejected]: (state, action) =>{
      state.approvals = null;
      error(action.error.message)
    },

    [getApprovalsKnowledge.fulfilled]: (state, action) => {
      state.approvalsConnoisseur = action.payload;
    },
    [getApprovalsKnowledge.rejected]: (state, action) =>{
      state.approvalsConnoisseur = null;
      error(action.error.message)
    },

    [getApprovalsNeededAction.fulfilled]: (state, action) => {
      state.approvalsNeededAction = action.payload;
    },
    [getApprovalsNeededAction.rejected]: (state, action) =>{
      state.approvalsNeededAction = null;
      error(action.error.message)
    },

    [getApprovalsNeededKnowledge.fulfilled]: (state, action) => {
      state.approvalsNeededKnowledge = action.payload;
    },
    [getApprovalsNeededKnowledge.rejected]: (state, action) =>{
      state.approvalsNeededKnowledge = null;
      error(action.error.message)
    },

    [getApprovalsByTag.fulfilled]: (state, action) => {
      state.approvalsByTag = action.payload;
    },
    [getApprovalsByTag.rejected]: (state, action) =>{
      state.approvalsByTag = null;
      error(action.error.message)
    },

  },
});

export const { setApprovalsSearchText } = approvalsSlice.actions;
  
export default approvalsSlice.reducer;


const error = (message) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
      icon: "error",
      title: "Ocorreram um ou mais erros",
      html: message,
      width: "800px",
  })
}