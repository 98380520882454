import React, { useEffect, useRef, useState } from "react";
import { animateScroll } from "react-scroll";
import { SpinButton } from "@fluentui/react";
import { spinButtonStyle } from "config/templateTheme";


import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


import PeoplePicker from "../PeoplePicker";

import { APPROVAL_SCALE_BUINESSDAYS } from "environmentConfig";

import { StepFieldModal } from "pages/approval-templates/components/FieldModal"

import { useSideOverlay } from "layout/SideOverlay"

import axios from "axios";

import DetailStepUser from "pages/approvals/components/DetailStepUser"


import { makeStyles } from "@fluentui/react-components";
import { Persona } from "@fluentui/react-persona";
import { Icon } from "@fluentui/react"





import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "@fluentui/react-components";
import fieldTypeToHuman from "utils/typesToString";

import FieldModal from "pages/approval-templates/components/FieldModal";


const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "auto",
        height: "auto",
        boxSizing: "border-box",
        "> *": {
            textOverflow: "ellipsis",
        },
        "> :not(:first-child)": {
            marginTop: "0px",
        },
        "> *:not(.ms-StackItem)": {
            flexShrink: 1,
        },
        '> :not(:last-child)': {
            marginRight: '10px',
        }
    },
    item: {
        height: "auto",
        width: "auto",
        flexShrink: 1,
    },
});


// old timeline
export const TimelineTemplateEdit = ({ form, setForm }) => {

    /* Start Steps Management */
    const addStep = () => {
        let templateSteps = form.templateSteps.map((step) => {
            return { ...step };
        });

        templateSteps.push({
            index: 0,
            name: "",
            approvers: [],
            approversOptional: [],
            neededApprovers: 0,
        });

        templateSteps = templateSteps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, templateSteps: templateSteps });

        animateScroll.scrollToBottom();
    };

    const removeStep = (step, index) => {
        let templateSteps = [...form.templateSteps];

        templateSteps = templateSteps
            .slice(0, index)
            .concat(templateSteps.slice(index + 1));

        templateSteps = templateSteps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, templateSteps: templateSteps });
    };

    const handleStepNeededApprovers = (e, index) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                if (Number(e) > 100 || Number(e) < 0 || isNaN(+e)) {
                    return { ...step, neededApprovers: 0 };
                } else {
                    return { ...step, neededApprovers: e };
                }
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };

    const handleStepNameChange = (e, index) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                return { ...step, name: e.target.value };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };

    /* // End Steps Management // */

    /* Start Approvers Management */

    const handleSelectedMandatoryApprovers = (index, items) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                let approvers = step.approvers.filter((x) => x.mandatory === "0");
                Array.prototype.push.apply(
                    approvers,
                    items.map((x) => ({ ...x, mandatory: "1" }))
                );
                return { ...step, approvers };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };

    const handleSelectedApprovers = (index, items) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                let approvers = step.approvers.filter((x) => x.mandatory === "1");
                Array.prototype.push.apply(
                    approvers,
                    items.map((x) => ({ ...x, mandatory: "0" }))
                );
                return { ...step, approvers };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };
    /* // End Approvers Management */


    return (
        <div className="row push">
            <div className="col-12">
                <ul className="timeline">
                    {form.templateSteps.map((step, index) =>
                        <li key={`step${index}`} className="timeline-event">

                            <div className="timeline-event-icon bg-gray-dark">
                                <i className="fa fa-check"></i>
                            </div>

                            <div className="timeline-event-block block block-rounded">
                                <div className="block-header block-header-default">
                                    <h3 className="block-title">
                                        <input
                                            key={`stepName${index}`}
                                            className="form-control"
                                            placeholder={`Etapa ${step.index + 1}`}
                                            onChange={(e) => {
                                                handleStepNameChange(e, index);
                                            }}
                                            value={step.name}
                                        />
                                    </h3>
                                    <div className="block-options">
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => removeStep(step, index)}
                                        >
                                            <i className="fa fa-fw fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="block-content pb-5">
                                    <div className="row">
                                        <div className="form-group col-6 mb-3">
                                            <label className="form-label">
                                                Aprovadores obrigatórios
                                            </label>
                                            <PeoplePicker
                                                selectedItems={step.approvers.filter(
                                                    (x) => x.mandatory === "1"
                                                )}
                                                index={index}
                                                handleSelectedItems={(items) => {
                                                    handleSelectedMandatoryApprovers(index, items);
                                                }}
                                                exclude={step.approvers.filter(
                                                    (x) => x.mandatory === "0"
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6 mb-3">
                                            <label className="form-label">Aprovadores</label>
                                            <PeoplePicker
                                                selectedItems={step.approvers.filter(
                                                    (x) => x.mandatory === "0"
                                                )}
                                                handleSelectedItems={(items) => {
                                                    handleSelectedApprovers(index, items);
                                                }}
                                                exclude={step.approvers.filter(
                                                    (x) => x.mandatory === "1"
                                                )}
                                            />
                                        </div>
                                        <div className="form-group col-6 mb-4">
                                            <label className="form-label">
                                                Aprovadores necessários
                                            </label>
                                            <SpinButton
                                                min={0}
                                                max={step.neededApprovers}
                                                value={step.neededApprovers}
                                                onValidate={(value) =>
                                                    handleStepNeededApprovers(
                                                        isNaN(value) ? 0 : parseInt(value),
                                                        index
                                                    )
                                                }
                                                onIncrement={() =>
                                                    handleStepNeededApprovers(
                                                        step.neededApprovers + 1,
                                                        index
                                                    )
                                                }
                                                onDecrement={() =>
                                                    handleStepNeededApprovers(
                                                        step.neededApprovers - 1,
                                                        index
                                                    )
                                                }
                                                className="form-control"
                                                styles={spinButtonStyle}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}


                    <li className="timeline-event">
                        <button
                            type="button"
                            className="btn btn-hero btn-success me-1 mb-3"
                            onClick={(e) => {
                                addStep();
                            }}
                        >
                            <i className="fa fa-fw fa-plus me-1"></i> Adicionar Etapa
                        </button>
                    </li>

                </ul>

            </div>
        </div>
    )
}


export const TimelineTemplateEditDragable = ({ form, setForm }) => {

    const onDragEnd = (result) => {
        if (!result.destination) return;

        let reorderedSteps = Array.from(form.templateSteps);
        const [movedStep] = reorderedSteps.splice(result.source.index, 1);
        reorderedSteps.splice(result.destination.index, 0, movedStep);

        reorderedSteps = reorderedSteps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({
            ...form,
            templateSteps: reorderedSteps
        });
    };

    /* Start Steps Management */
    const addStep = (isFillableStep = false) => {
        let templateSteps = form.templateSteps.map((step) => {
            return { ...step };
        });

        if (isFillableStep) {
            templateSteps.push({
                index: 0,
                name: "",
                approvers: [],
                approversOptional: [],
                neededApprovers: 1,
                type: "FILLABLE",
                approvalTemplateStepField: []
            });
        }
        else {
            templateSteps.push({
                index: 0,
                name: "",
                approvers: [],
                approversOptional: [],
                neededApprovers: 0,
                type: "NORMAL"
            });
        }

        templateSteps = templateSteps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, templateSteps: templateSteps });

        animateScroll.scrollToBottom();
    };

    const removeStep = (step, index) => {
        let templateSteps = [...form.templateSteps];

        templateSteps = templateSteps
            .slice(0, index)
            .concat(templateSteps.slice(index + 1));

        templateSteps = templateSteps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, templateSteps: templateSteps });
    };

    const handleStepNeededApprovers = (e, index) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                if (Number(e) > 100 || Number(e) < 0 || isNaN(+e)) {
                    return { ...step, neededApprovers: 0 };
                } else {
                    return { ...step, neededApprovers: e };
                }
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };

    const handleStepNameChange = (e, index) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                return { ...step, name: e.target.value };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };


    const handleStepChange = (index, field, value) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                return {
                    ...step,
                    [field]: value
                }
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };

    /* // End Steps Management // */

    /* Start Approvers Management */

    const handleSelectedMandatoryApprovers = (index, items) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                let approvers = step.approvers.filter((x) => x.mandatory === "0");
                Array.prototype.push.apply(
                    approvers,
                    items.map((x) => ({ ...x, mandatory: "1" }))
                );
                return { ...step, approvers };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };

    const handleSelectedApprovers = (index, items) => {
        let templateSteps = form.templateSteps.map((step, i) => {
            if (index === i) {
                let approvers = step.approvers.filter((x) => x.mandatory === "1");
                Array.prototype.push.apply(
                    approvers,
                    items.map((x) => ({ ...x, mandatory: "0" }))
                );
                return { ...step, approvers };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, templateSteps: templateSteps });
    };
    /* // End Approvers Management */


    return (
        <div className="row push">
            <div className="col-12">

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="timeline">
                        {(provided) => (

                            <ul className="timeline" {...provided.droppableProps} ref={provided.innerRef}>
                                {form.templateSteps.map((step, index) =>
                                    <Draggable key={`step${index}`} draggableId={`step${index}`} index={index}>
                                        {(provided) => (

                                            <li key={`step${index}_li`} className="timeline-event" ref={provided.innerRef} {...provided.draggableProps}>

                                                <div className="timeline-event-icon bg-gray-dark"  {...provided.dragHandleProps}>
                                                    <i className="fa fa-check"></i>
                                                </div>

                                                <div className="timeline-event-block block block-rounded">
                                                    <div className="block-header block-header-default">
                                                        <h3 className="block-title">
                                                            <input
                                                                key={`stepName${index}`}
                                                                className="form-control"
                                                                placeholder={`Etapa ${step.index + 1}`}
                                                                onChange={(e) => {
                                                                    handleStepNameChange(e, index);
                                                                }}
                                                                value={step.name}
                                                            />
                                                        </h3>
                                                        <div className="block-options">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={(e) => removeStep(step, index)}
                                                            >
                                                                <i className="fa fa-fw fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {(step.type === "FILLABLE" && step.approvalTemplateStepField && Array.isArray(step.approvalTemplateStepField))
                                                        ? <FieldStepEditor step={step} stepIndex={index} handleSelectedApprovers={handleSelectedApprovers} handleStepChange={handleStepChange} />
                                                        : <NormalStepEditor step={step} index={index}
                                                            handleSelectedMandatoryApprovers={handleSelectedMandatoryApprovers}
                                                            handleSelectedApprovers={handleSelectedApprovers}
                                                            handleStepNeededApprovers={handleStepNeededApprovers} />
                                                    }
                                                </div>
                                            </li>

                                        )}

                                    </Draggable>
                                )}

                                {provided.placeholder}


                                <li className="timeline-event">
                                    <button
                                        type="button"
                                        className="btn btn-hero btn-success me-1 mb-3"
                                        onClick={(e) => { addStep(); }}
                                    >
                                        <i className="fa fa-fw fa-plus me-1"></i> Adicionar Etapa
                                    </button>



                                    <button
                                        type="button"
                                        className="btn btn-hero btn-info me-1 mb-3"
                                        onClick={(e) => { addStep(true); }}
                                    >
                                        {/* <i className="fas fa-plus-square"></i> */}
                                        {/* <i className="fas fa-list"></i> */}
                                        {/* <i className="fas fa-clipboard-list"></i> */}
                                        {/* <i className="fas fa-pencil-alt"></i> */}
                                        <i className="fa fa-fw fa-tasks me-1"></i> Adicionar Etapa Com Campos
                                    </button>
                                </li>

                            </ul>


                        )}
                    </Droppable>
                </DragDropContext>

            </div>
        </div>
    )


}


const NormalStepEditor = ({ step, index, handleSelectedMandatoryApprovers, handleSelectedApprovers, handleStepNeededApprovers, allowGuest=false }) => {
    return <div className="block-content pb-5">
        <div className="row">
            <div className="form-group col-6 mb-3">
                <label className="form-label">
                    Aprovadores obrigatórios
                </label>
                <PeoplePicker
                    selectedItems={step.approvers.filter(
                        (x) => x.mandatory === "1"
                    )}
                    index={index}
                    handleSelectedItems={(items) => {
                        handleSelectedMandatoryApprovers(index, items);
                    }}
                    exclude={step.approvers.filter(
                        (x) => x.mandatory === "0"
                    )}
                    allowGuest={allowGuest}
                />
            </div>
        </div>
        <div className="row">
            <div className="form-group col-6 mb-3">
                <label className="form-label">Aprovadores</label>
                <PeoplePicker
                    selectedItems={step.approvers.filter(
                        (x) => x.mandatory === "0"
                    )}
                    handleSelectedItems={(items) => {
                        handleSelectedApprovers(index, items);
                    }}
                    exclude={step.approvers.filter(
                        (x) => x.mandatory === "1"
                    )}
                    allowGuest={allowGuest}
                />
            </div>
            <div className="form-group col-6 mb-4">
                <label className="form-label">
                    Aprovadores necessários
                </label>
                <SpinButton
                    min={0}
                    max={step.neededApprovers}
                    value={step.neededApprovers}
                    onValidate={(value) =>
                        handleStepNeededApprovers(
                            isNaN(value) ? 0 : parseInt(value),
                            index
                        )
                    }
                    onIncrement={() =>
                        handleStepNeededApprovers(
                            step.neededApprovers + 1,
                            index
                        )
                    }
                    onDecrement={() =>
                        handleStepNeededApprovers(
                            step.neededApprovers - 1,
                            index
                        )
                    }
                    className="form-control"
                    styles={spinButtonStyle}
                />
            </div>
        </div>
    </div>
}


const FieldStepEditor = ({ step, stepIndex, handleSelectedApprovers, handleStepChange }) => {


    /* Start Custom Fields */

    const [isModalOpen, setIsModalOpen] = useState(false);
    const fieldModalRef = useRef(null);
    const openModal = (field, index) => {
        if (fieldModalRef.current) {
            if (field) {
                fieldModalRef.current.openModalEdit(
                    index,
                    field.name,
                    field.type,
                    field.values
                );
            } else {
                fieldModalRef.current.openModal();
            }
            setIsModalOpen(true);
        }
    };

    const addCustomField = async (index, fieldName, fieldType, fieldValues) => {
        if (fieldName) {
            if (index != null) {
                let fieldList = step.approvalTemplateStepField.map((field, i) => {
                    if (index === i) {
                        return {
                            ...field,
                            name: fieldName,
                            type: fieldType,
                            values: fieldValues,
                        };
                    } else {
                        return { ...field };
                    }
                });

                handleStepChange(stepIndex, "approvalTemplateStepField", fieldList)
            } else {
                let fieldList = [...step.approvalTemplateStepField];

                fieldList.push({
                    name: fieldName,
                    type: fieldType,
                    values: fieldValues,
                    index: 0,
                });

                fieldList = fieldList.map((field, index) => {
                    return { ...field, index: index };
                });

                handleStepChange(stepIndex, "approvalTemplateStepField", fieldList)

            }
            closeModal();
        }
    };

    // Method to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const removeCustomField = (field, index) => {
        let fieldList = [...step.approvalTemplateStepField];

        fieldList = fieldList
            .slice(0, index)
            .concat(fieldList.slice(index + 1));

        fieldList = fieldList.map((field, index) => {
            return { ...field, index: index };
        });

        handleStepChange(stepIndex, "approvalTemplateStepField", fieldList)
    };

    const moveCustomField = (oldIndex, newIndex) => {
        let fieldList = [...step.approvalTemplateStepField];

        const element = fieldList[oldIndex];
        fieldList = fieldList
            .slice(0, oldIndex)
            .concat(fieldList.slice(oldIndex + 1));

        fieldList = fieldList
            .slice(0, newIndex)
            .concat([element])
            .concat(fieldList.slice(newIndex));

        fieldList = fieldList.map((field, index) => {
            return { ...field, index: index };
        });

        handleStepChange(stepIndex, "approvalTemplateStepField", fieldList)

    };

    /* // End Custom Fields // */



    return <div className="block-content pb-5">
        <div className="row">
            <div className="form-group col-6 mb-3">
                <label className="form-label">Aprovadores</label>
                <PeoplePicker
                    selectedItems={step.approvers.filter(
                        (x) => x.mandatory === "0"
                    )}
                    handleSelectedItems={(items) => {
                        handleSelectedApprovers(stepIndex, items);
                    }}
                    exclude={step.approvers.filter(
                        (x) => x.mandatory === "1"
                    )}
                />
            </div>
            <div className="form-group col-6 mb-4">
                <label className="form-label">
                    Aprovadores necessários
                </label>

                <SpinButton
                    value={step.neededApprovers}
                    className="form-control"
                    styles={spinButtonStyle}
                    disabled={true}
                />
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                {step.approvalTemplateStepField.length > 0 && (
                    <Table noNativeElements>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Nome</TableHeaderCell>
                                <TableHeaderCell>Tipo</TableHeaderCell>
                                <TableHeaderCell>Valores</TableHeaderCell>
                                <TableHeaderCell>Ações</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {step.approvalTemplateStepField.map((item, index) => (
                                <TableRow
                                    key={item.name}
                                    onDragStart={(e) => {
                                        console.log("dragStart", item.index);
                                        e.dataTransfer.setData("text/plain", item.index);
                                    }}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                    }}
                                    onDrop={(e) => {
                                        const oldIndex = Number(
                                            e.dataTransfer.getData("text/plain")
                                        );
                                        moveCustomField(oldIndex, item.index);
                                    }}
                                    draggable
                                >
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>
                                        <TableCellLayout>
                                            {fieldTypeToHuman(item.type)}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>{item.values}</TableCell>
                                    <TableCell>
                                        <button
                                            type="button"
                                            className="btn btn-warning me-1"
                                            onClick={(e) => openModal(item, index)}
                                        >
                                            <i className="fa fa-fw fa-edit"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger me-1"
                                            onClick={(e) => removeCustomField(item, index)}
                                        >
                                            <i className="fa fa-fw fa-trash"></i>
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <Button
                    className="mt-2"
                    appearance="primary"
                    onClick={() => openModal(null, null)}
                >
                    Adicionar Campo
                </Button>
                <FieldModal
                    ref={fieldModalRef}
                    onAddField={addCustomField}
                    isOpen={isModalOpen}
                    closeModal={closeModal}
                />
            </div>
        </div>
    </div>
}



const FieldStep = ({ step, stepIndex, handleSelectedApprovers, allowGuest = false}) => {
    return <div className="block-content pb-5">
        <div className="row">
            <div className="form-group col-6 mb-3">
                <label className="form-label">Aprovadores</label>
                <PeoplePicker
                    selectedItems={step.approvers.filter(
                        (x) => x.mandatory === "0"
                    )}
                    handleSelectedItems={(items) => {
                        handleSelectedApprovers(stepIndex, items);
                    }}
                    exclude={step.approvers.filter(
                        (x) => x.mandatory === "1"
                    )}
                    allowGuest={allowGuest}
                />
            </div>
            <div className="form-group col-6 mb-4">
                <label className="form-label">
                    Aprovadores necessários
                </label>
                <SpinButton
                    value={step.neededApprovers}
                    className="form-control"
                    styles={spinButtonStyle}
                    disabled={true}
                />
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-12">
                <label className="form-label">
                    Campos de Etapa
                </label>
                {step.approvalStepField?.length > 0 ? (
                    <Table noNativeElements>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Nome</TableHeaderCell>
                                <TableHeaderCell>Tipo</TableHeaderCell>
                                <TableHeaderCell>Valores</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {step.approvalStepField?.map((item, index) => (
                                <TableRow key={item.name}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>
                                        <TableCellLayout>
                                            {fieldTypeToHuman(item.type)}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>{item.values}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )

                    : <span className="d-block text-warning">Sem campos definidos</span>
                }
            </div>
        </div>
    </div>
}










/////////////////////////////////////
// Existem algumas features entre timeline, por isso estão separadas
// pode-se vir a junto e adicionar FLAGS/Callbacks
/////////////////////////////////////

export const TimelineApprovalEdit = ({ form, setForm }) => {

    /* Start Steps Management */
    const addStep = () => {
        let steps = form.steps.map((step) => {
            return { ...step };
        });

        steps.push({
            index: 0,
            name: "",
            approvers: [],
            approversOptional: [],
            neededApprovers: 0,
        });

        steps = steps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, steps: steps });

        animateScroll.scrollToBottom();
    };

    const removeStep = (step, index) => {
        let steps = [...form.steps];

        steps = steps.slice(0, index).concat(steps.slice(index + 1));

        steps = steps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, steps: steps });
    };

    const handleStepNeededApprovers = (e, index) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                if (Number(e) > 100 || Number(e) < 0 || isNaN(+e)) {
                    return { ...step, neededApprovers: 0 };
                } else {
                    return { ...step, neededApprovers: e };
                }
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const handleStepNameChange = (e, index) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                return { ...step, name: e.target.value };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const handleStepExpiredDate = (e, index) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                return { ...step, daysToResolve: e };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    /* // End Steps Management // */

    /* Start Approvers Management */

    const handleSelectedMandatoryApprovers = (index, items) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                let approvers = step.approvers.filter((x) => x.mandatory === "0");
                Array.prototype.push.apply(
                    approvers,
                    items.map((x) => ({ ...x, mandatory: "1" }))
                );
                return { ...step, approvers };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const handleSelectedApprovers = (index, items) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                let approvers = step.approvers.filter((x) => x.mandatory === "1");
                Array.prototype.push.apply(
                    approvers,
                    items.map((x) => ({ ...x, mandatory: "0" }))
                );
                return { ...step, approvers };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const checkStepMinimumOptionalApprovers = (step) => {
        const count = step.approvers.filter((x) => x.mandatory === "0").length ?? 0;
        return count >= step.neededApprovers;
    };
    /* // End Approvers Management */



    return (
        <div className="row push">
            <div className="col-12">
                <ul className="timeline">
                    {form.steps.map((step, index) => {
                        return (
                            <li key={`step${index}`} className="timeline-event">
                                <div className="timeline-event-icon bg-gray-dark">
                                    <i className="fas fa-check"></i>
                                </div>
                                <div className="timeline-event-block block block-rounded">
                                    <div className="block-header block-header-default">
                                        <h3 className="block-title">
                                            <input
                                                key={`stepName${index}`}
                                                className="form-control"
                                                placeholder={`Etapa ${step.index + 1}`}
                                                onChange={(e) => {
                                                    handleStepNameChange(e, index);
                                                }}
                                                value={step.name}
                                            />
                                        </h3>
                                        <div className="block-options">
                                            <DropdownTimer
                                                step={step}
                                                handleStepExpiredDate={handleStepExpiredDate}
                                                index={index}
                                            />

                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={(e) => removeStep(step, index)}
                                            >
                                                <i className="fa fa-fw fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="block-content pb-5">
                                        <div className="row">
                                            <div className="form-group col-6 mb-4">
                                                <label className="form-label">
                                                    Aprovadores obrigatórios
                                                </label>
                                                <PeoplePicker
                                                    selectedItems={step.approvers.filter(
                                                        (x) => x.mandatory === "1"
                                                    )}
                                                    index={index}
                                                    handleSelectedItems={(items) => {
                                                        handleSelectedMandatoryApprovers(index, items);
                                                    }}
                                                    exclude={step.approvers.filter(
                                                        (x) => x.mandatory === "0"
                                                    )}
                                                    allowGuest={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6 mb-4">
                                                <label className="form-label">Aprovadores</label>
                                                <PeoplePicker
                                                    selectedItems={step.approvers.filter(
                                                        (x) => x.mandatory === "0"
                                                    )}
                                                    handleSelectedItems={(items) => {
                                                        handleSelectedApprovers(index, items);
                                                    }}
                                                    exclude={step.approvers.filter(
                                                        (x) => x.mandatory === "1"
                                                    )}
                                                    allowGuest={true}
                                                />
                                            </div>
                                            <div className="form-group col-6 mb-4">
                                                <label className="form-label">
                                                    Aprovadores necessários
                                                </label>
                                                <SpinButton
                                                    min={0}
                                                    max={step.neededApprovers}
                                                    value={step.neededApprovers}
                                                    onValidate={(value) =>
                                                        handleStepNeededApprovers(
                                                            isNaN(value) ? 0 : parseInt(value),
                                                            index
                                                        )
                                                    }
                                                    onIncrement={() =>
                                                        handleStepNeededApprovers(
                                                            step.neededApprovers + 1,
                                                            index
                                                        )
                                                    }
                                                    onDecrement={() =>
                                                        handleStepNeededApprovers(
                                                            step.neededApprovers - 1,
                                                            index
                                                        )
                                                    }
                                                    className="form-control"
                                                    styles={spinButtonStyle}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {!checkStepMinimumOptionalApprovers(step) && (
                                                <div
                                                    className="mx-2 col-12 alert bg-warning d-flex align-items-center flex-fill text-white"
                                                    role="alert"
                                                >
                                                    Selecione mais aprovadores opcionais
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })}

                    <li className="timeline-event">
                        <button
                            type="button"
                            className="btn btn-hero btn-success me-1 mb-3"
                            onClick={(e) => {
                                addStep();
                            }}
                        >
                            <i className="fa fa-fw fa-plus me-1"></i> Adicionar Etapa
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )

}



export const TimelineApprovalEditDragable = ({ form, setForm }) => {


    const onDragEnd = (result) => {
        if (!result.destination) return;

        let reorderedSteps = Array.from(form.steps);
        const [movedStep] = reorderedSteps.splice(result.source.index, 1);
        reorderedSteps.splice(result.destination.index, 0, movedStep);

        // reorderedSteps.forEach( (obj, index) => {
        //     obj.index = index;
        // });

        reorderedSteps = reorderedSteps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({
            ...form,
            steps: reorderedSteps
        });
    };


    /* Start Steps Management */
    const addStep = () => {
        let steps = form.steps.map((step) => {
            return { ...step };
        });

        steps.push({
            index: 0,
            name: "",
            approvers: [],
            approversOptional: [],
            neededApprovers: 0,
        });

        steps = steps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, steps: steps });

        animateScroll.scrollToBottom();
    };

    const removeStep = (step, index) => {
        let steps = [...form.steps];

        steps = steps.slice(0, index).concat(steps.slice(index + 1));

        steps = steps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, steps: steps });
    };

    const handleStepNeededApprovers = (e, index) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                if (Number(e) > 100 || Number(e) < 0 || isNaN(+e)) {
                    return { ...step, neededApprovers: 0 };
                } else {
                    return { ...step, neededApprovers: e };
                }
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const handleStepNameChange = (e, index) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                return { ...step, name: e.target.value };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const handleStepExpiredDate = (e, index) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                return { ...step, daysToResolve: e };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const handleRestoreTemplateStep = (templateStep) => {
        let steps = form.steps.map((step) => {
            return { ...step };
        });

        for (const ts of templateStep) {
            steps.push(ts)
        }

        steps = steps.map((step, index) => {
            return { ...step, index: index };
        });

        setForm({ ...form, steps: steps });

        closeOverlay()
        animateScroll.scrollToBottom();
    }


    // const handleStepChange = (index, field, value) => {
    //     let steps = form.templateSteps.map((step, i) => {
    //         if (index === i) {
    //             return {
    //                 ...step,
    //                 [field]: value
    //             }
    //         } else {
    //             return { ...step };
    //         }
    //     });

    //     setForm({ ...form, steps: steps });
    // };

    /* // End Steps Management // */

    /* Start Approvers Management */

    const handleSelectedMandatoryApprovers = (index, items) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                let approvers = step.approvers.filter((x) => x.mandatory === "0");
                Array.prototype.push.apply(
                    approvers,
                    items.map((x) => ({ ...x, mandatory: "1" }))
                );
                return { ...step, approvers };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const handleSelectedApprovers = (index, items) => {
        let steps = form.steps.map((step, i) => {
            if (index === i) {
                let approvers = step.approvers.filter((x) => x.mandatory === "1");
                Array.prototype.push.apply(
                    approvers,
                    items.map((x) => ({ ...x, mandatory: "0" }))
                );
                return { ...step, approvers };
            } else {
                return { ...step };
            }
        });

        setForm({ ...form, steps: steps });
    };

    const checkStepMinimumOptionalApprovers = (step) => {
        const count = step.approvers.filter((x) => x.mandatory === "0").length ?? 0;
        return count >= step.neededApprovers;
    };
    /* // End Approvers Management */


    const { openOverlay, closeOverlay } = useSideOverlay();



    return (
        <div className="row push">
            <div className="col-12">

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="timeline">
                        {(provided) => (

                            <ul className="timeline" {...provided.droppableProps} ref={provided.innerRef}>
                                {form.steps.map((step, index) =>
                                    <Draggable key={`step${index}`} draggableId={`step${index}`} index={index}>
                                        {(provided) => (

                                            <li key={`step${index}_li`} className="timeline-event" ref={provided.innerRef} {...provided.draggableProps}>

                                                <div className="timeline-event-icon bg-gray-dark"  {...provided.dragHandleProps}>
                                                    <i className="fas fa-check"></i>
                                                </div>

                                                <div className="timeline-event-block block block-rounded">
                                                    <div className="block-header block-header-default">
                                                        <h3 className="block-title">
                                                            <input
                                                                key={`stepName${index}`}
                                                                className="form-control"
                                                                placeholder={`Etapa ${step.index + 1}`}
                                                                onChange={(e) => {
                                                                    handleStepNameChange(e, index);
                                                                }}
                                                                value={step.name}
                                                            />
                                                        </h3>
                                                        <div className="block-options">
                                                            <DropdownTimer
                                                                step={step}
                                                                handleStepExpiredDate={handleStepExpiredDate}
                                                                index={index}
                                                            />

                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={(e) => removeStep(step, index)}
                                                            >
                                                                <i className="fa fa-fw fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {/* <div className="block-content pb-5">
                                                        <div className="row">
                                                            <div className="form-group col-6 mb-4">
                                                                <label className="form-label">
                                                                    Aprovadores obrigatórios
                                                                </label>
                                                                <PeoplePicker
                                                                    selectedItems={step.approvers.filter(
                                                                        (x) => x.mandatory === "1"
                                                                    )}
                                                                    index={index}
                                                                    handleSelectedItems={(items) => {
                                                                        handleSelectedMandatoryApprovers(index, items);
                                                                    }}
                                                                    exclude={step.approvers.filter(
                                                                        (x) => x.mandatory === "0"
                                                                    )}
                                                                    allowGuest={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-6 mb-4">
                                                                <label className="form-label">Aprovadores</label>
                                                                <PeoplePicker
                                                                    selectedItems={step.approvers.filter(
                                                                        (x) => x.mandatory === "0"
                                                                    )}
                                                                    handleSelectedItems={(items) => {
                                                                        handleSelectedApprovers(index, items);
                                                                    }}
                                                                    exclude={step.approvers.filter(
                                                                        (x) => x.mandatory === "1"
                                                                    )}
                                                                    allowGuest={true}
                                                                />
                                                            </div>
                                                            <div className="form-group col-6 mb-4">
                                                                <label className="form-label">
                                                                    Aprovadores necessários
                                                                </label>
                                                                <SpinButton
                                                                    min={0}
                                                                    max={step.neededApprovers}
                                                                    value={step.neededApprovers}
                                                                    onValidate={(value) =>
                                                                        handleStepNeededApprovers(
                                                                            isNaN(value) ? 0 : parseInt(value),
                                                                            index
                                                                        )
                                                                    }
                                                                    onIncrement={() =>
                                                                        handleStepNeededApprovers(
                                                                            step.neededApprovers + 1,
                                                                            index
                                                                        )
                                                                    }
                                                                    onDecrement={() =>
                                                                        handleStepNeededApprovers(
                                                                            step.neededApprovers - 1,
                                                                            index
                                                                        )
                                                                    }
                                                                    className="form-control"
                                                                    styles={spinButtonStyle}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            {!checkStepMinimumOptionalApprovers(step) && (
                                                                <div
                                                                    className="mx-2 col-12 alert bg-warning d-flex align-items-center flex-fill text-white"
                                                                    role="alert"
                                                                >
                                                                    Selecione mais aprovadores opcionais
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div> */}

                                                    {step.type === "FILLABLE"
                                                        ? <FieldStep step={step} stepIndex={index} handleSelectedApprovers={handleSelectedApprovers} allowGuest={true} />
                                                        : <NormalStepEditor step={step} index={index}
                                                            handleSelectedMandatoryApprovers={handleSelectedMandatoryApprovers}
                                                            handleSelectedApprovers={handleSelectedApprovers}
                                                            handleStepNeededApprovers={handleStepNeededApprovers} allowGuest={true}/>
                                                    }
                                                </div>
                                            </li>

                                        )}
                                    </Draggable>

                                )}


                                {provided.placeholder}


                                <li className="timeline-event">
                                    <button
                                        type="button"
                                        className="btn btn-hero btn-success me-1 mb-3"
                                        onClick={(e) => {
                                            addStep();
                                        }}
                                    >
                                        <i className="fa fa-fw fa-plus me-1"></i> Adicionar Etapa
                                    </button>


                                    {(form.templateId || form.templateUsed) &&
                                        <button
                                            type="button"
                                            className="btn btn-hero btn-secondary me-1 mb-3"
                                            onClick={(e) => {
                                                openOverlay(
                                                    <TemplateTimelineRestore id={form.templateId ?? form.templateUsed.id} addFunction={handleRestoreTemplateStep} />,
                                                    <h2 className="text-white m-0 p-0">Modelo</h2>
                                                );
                                            }}
                                        >
                                            <i className="fa fa-fw fa-plus me-1"></i> Etapas Modelo
                                        </button>
                                    }
                                </li>
                            </ul>

                        )}
                    </Droppable>
                </DragDropContext>

            </div>
        </div>
    )

}






/////////////////////////////////////
// Dropdown for Adding time to Approval.
/////////////////////////////////////

const DropdownTimer = ({ step, handleStepExpiredDate, index }) => {
    const dropdownRef = useRef();
    const inputRef = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (!isOpen) return;

        inputRef.current.focus();

        // Add event listener to close the dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        window.addEventListener("click", handleClickOutside);

        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [isOpen]);

    // value
    const [inputValue, setInputValue] = useState(step.daysToResolve ?? 0);

    const validateInput = (val) => {
        if (String(val).trim() === "") {
            setInputValue(undefined);
        } else if (Number(val) < 0 || isNaN(+val)) {
            setInputValue(0);
        } else {
            setInputValue(parseInt(val));
        }
    };

    useEffect(() => {
        handleStepExpiredDate(inputValue, index);
    }, [inputValue]);

    const menuClass = `dropdown-menu end-0 p-0 shadow-lg ${isOpen ? "show" : ""}`;

    return (
        <div className="dropdown d-inline-block px-2" ref={dropdownRef}>
            <button
                className="btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                aria-haspopup="true"
                onClick={toggleOpen}
            >
                {/* TODO : ver se o icon pode ficar este! */}
                {/* <i className="fa fa-fw fa-clock"></i> */}
                <i className="fa fa-fw fa-hourglass-half"></i>
            </button>

            <div className={menuClass} aria-labelledby="dropdownMenuButton">
                <div className="p-3 mb-3">
                    <label className="form-label" htmlFor="dropdown-content-form-email">
                        Dias {APPROVAL_SCALE_BUINESSDAYS && "úteis"} até a etapa expirar
                    </label>

                    <SpinButton
                        componentRef={inputRef}
                        min={0}
                        value={inputValue}
                        onValidate={validateInput}
                        onIncrement={(value) => validateInput(value + 1)}
                        onDecrement={(value) => validateInput(value - 1)}
                        className="form-control"
                        styles={spinButtonStyle}
                    />
                </div>
            </div>
        </div>
    );
};



const TemplateTimelineRestore = ({ id, addFunction }) => {

    const styles = useStyles()

    const [data, setData] = useState(null)

    const [selected, setselected] = useState([])


    const addStepToApproval = () => {
        const sorted = selected.sort((a, b) => a - b)
        let steps = []
        for (const index of sorted) {
            console.log(index)
            let step = data.templateSteps[index];

            if (step.approvalTemplateStepField && Array.isArray(step.approvalTemplateStepField)) {
                // step.approvalStepField = [...step.approvalTemplateStepField]
                step.approvalStepField = step.approvalTemplateStepField.map(element => {
                    delete element.id
                    return element
                })

                delete step.approvalTemplateStepField
            }

            steps.push(step)
        }

        addFunction(steps)
    }

    const toggleSelected = (index) => {
        setselected((prev) => {
            if (prev.includes(index)) {
                return prev.filter(i => i !== index);
            } else {
                return [...prev, index];
            }
        });
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`/approval-template/${id}`)
            setData(response.data.approvalTemplate)
        } catch (error) {
            setData("error")
        }
    }

    useEffect(() => {
        fetchData()
        return () => {
            setData(null)
            setselected([])
        }
    }, [])


    if (data === null)
        return <h2>Loading...</h2>


    if (data === "error")
        return <p className="text-danger p-2">Ocurreu um erro a obter o template, por favor feche e volte a tentar</p>

    return <div>
        <h1 className="px-2">{data.templateTitle}</h1>

        {/* <p>{JSON.stringify(selected)}</p> */}

        <ul className="timeline">
            {data.templateSteps.map((step, index) => {
                let mandatories = step.approvers.filter(
                    // eslint-disable-next-line
                    (x) => x.mandatory == true
                );
                let optionals = step.approvers.filter(
                    // eslint-disable-next-line
                    (x) => x.mandatory == false
                );

                return <li key={`step${index}`} className="timeline-event" onClick={() => toggleSelected(index)}>
                    <div className="block block-rounded shadow bg-white rounded mx-2">
                        <h3 className={`block-title text-white text-wrap text-break p-2 ${selected.includes(index) ? "bg-success" : "bg-secondary"}`}>
                            {selected.includes(index) ? <i className="fa-solid fa-check"></i> : <i className="fa-solid fa-xmark"></i>} {" "} {step.name || `Etapa ${index + 1}`}
                        </h3>

                        {step.type === "FILLABLE"
                            // ? <StepApproverFillable approval={approval} step={step} optionals={optionals} />
                            ? <div className="block-content p-3">
                                <label>Aprovadores</label>
                                {optionals.length !== 0
                                    ? (
                                        <React.Fragment>
                                            <div className={styles.root}>
                                                {optionals.map((approver) => (
                                                    <div key={approver.id} className={styles.item}>
                                                        <Persona
                                                            className="mt-2"
                                                            onRenderPrimaryText={approver?.user?.name ?? approver?.guest?.email}
                                                            tertiaryText={approver.decisionText && <Icon iconName="message" />}
                                                            textAlignment={"center"}
                                                            name={approver?.user?.name || approver?.guest?.email}
                                                            avatar={{
                                                                image: {
                                                                    src: approver?.user?.profilePhoto,
                                                                },
                                                            }}
                                                            size={"small"}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <small> É necessária apenas 1 aprovação</small>
                                        </React.Fragment>
                                    )
                                    : <small className="d-block">Sem Aprovadores definidos</small>
                                }

                                <p className="mb-0 mt-1">
                                    {step.approvalTemplateStepField?.length > 0
                                        ? <>
                                            Nº de Campos Preenchíveis: {step.approvalTemplateStepField.length}
                                            <ul className="m-1 px-4">
                                                {step.approvalTemplateStepField?.map((item, index) => <li>{item.name}</li>)}

                                            </ul>
                                        </>
                                        : <span className="d-block text-warning">Sem campos definidos</span>
                                    }
                                </p>


                            </div>
                            : <div className="block-content p-3">
                                <label>Aprovadores</label>
                                {mandatories.length !== 0
                                    ? (
                                        <React.Fragment>
                                            <div className={styles.root}>
                                                {mandatories.map((approver) => (
                                                    <div key={approver.id} className={styles.item}>
                                                        <Persona
                                                            className="mt-2"
                                                            onRenderPrimaryText={approver?.user?.name ?? approver?.guest?.email}
                                                            tertiaryText={approver.decisionText && <Icon iconName="message" />}
                                                            textAlignment={"center"}
                                                            name={approver?.user?.name || approver?.guest?.email}
                                                            avatar={{
                                                                image: {
                                                                    src: approver?.user?.profilePhoto,
                                                                },
                                                            }}
                                                            size={"small"}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    )
                                    : <small className="d-block">Sem Aprovadores definidos</small>
                                }
                                <br />
                                <label>Aprovadores Adicionais</label>
                                {optionals.length !== 0
                                    ? (
                                        <React.Fragment>
                                            <div className={styles.root}>
                                                {optionals.map((approver) => (
                                                    <div key={approver.id} className={styles.item}>
                                                        <Persona
                                                            className="mt-2"
                                                            onRenderPrimaryText={approver?.user?.name ?? approver?.guest?.email}
                                                            tertiaryText={approver.decisionText && <Icon iconName="message" />}
                                                            textAlignment={"center"}
                                                            name={approver?.user?.name || approver?.guest?.email}
                                                            avatar={{
                                                                image: {
                                                                    src: approver?.user?.profilePhoto,
                                                                },
                                                            }}
                                                            size={"small"}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <small>
                                                {step.neededApprovers > 1
                                                    ? `São necessárias apenas ${step.neededApprovers} aprovações`
                                                    : `É necessária apenas ${step.neededApprovers} aprovação`}
                                                {mandatories.length !== 0 && " além das obrigatórias"}
                                            </small>
                                        </React.Fragment>
                                    )
                                    : <small className="d-block">Sem Aprovadores Adicionais definidos</small>
                                }
                            </div>
                        }
                    </div>
                </li>
            }
            )}



        </ul>

        <div className="block-content">

            <button
                type="button"
                className="btn btn-success w-100"
                onClick={addStepToApproval}
            >
                <i className="fa fa-fw fa-plus me-1"></i> Adicionar Etapas Selecionadas
            </button>
        </div>

        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
}