import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from "@fluentui/react-components";

export const useDialog = ({ inicialTitle = null, inicialContent= null, inicialBottomActions = null }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openDialog = () => setIsOpen(true);
    const closeDialog = () => {
        setIsOpen(false)

    };


    const [_title, _setTitle] = useState(inicialTitle || <></>)
    const [_content, _setContent] = useState(inicialContent || <></>)
    const [_bottomActions, _setBottomActions] = useState(inicialBottomActions || <></>)

    const setValues = ({
        title,
        content,
        bottomActions
    }) => {
        title != null && _setTitle(title);
        content != null && _setContent(content);
        bottomActions != null && _setBottomActions(bottomActions);
    }


    useEffect(() => {
        _setTitle(inicialTitle);
        _setContent(inicialContent);
        _setBottomActions(inicialBottomActions);
    }, [])




    const DialogComponent = (
        <Dialog open={isOpen} onOpenChange={(_, { open }) => setIsOpen(open)} modalType="alert">
            <DialogSurface>
                <DialogBody>
                    {_title !== null && <DialogTitle>{_title}</DialogTitle>}
                    
                    {_content !== null && <DialogContent>{_content}</DialogContent>}
                    
                    {_bottomActions !== null && <DialogActions> {_bottomActions}</DialogActions>
                    }
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );

    //   const DialogComponent = (
    //     <Dialog open={isOpen} onOpenChange={(_, { open }) => setIsOpen(open)}>
    //       <DialogSurface>
    //         <DialogBody>
    //           <DialogTitle>{title}</DialogTitle>
    //           <DialogContent>{content}</DialogContent>
    //           <DialogActions>

    //             <Button appearance="primary" onClick={primaryAction}>
    //               Do Something
    //             </Button>

    //             <Button appearance="secondary" onClick={closeDialog}>
    //               {secondaryAction || "Close"}
    //             </Button>

    //           </DialogActions>
    //         </DialogBody>
    //       </DialogSurface>
    //     </Dialog>
    //   );

    return [DialogComponent, setValues, openDialog, closeDialog,];
};

