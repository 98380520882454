import { Popover, PopoverSurface, PopoverTrigger } from "@fluentui/react-components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getApproval, getApprovalGuest, sendStepResponse, sendStepResponseGuest } from "../store/approvalSlice";
import DetailStepUser from "./DetailStepUser";


import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,


  Combobox,
  makeStyles,
  Option,
  Radio,
  RadioGroup,
  shorthands,
  Textarea,
  tokens,

  Badge

} from "@fluentui/react-components";
import fieldTypeToHuman from "utils/typesToString";

import { useDialog } from "hooks/useDialog"


import moment from "moment";
import "moment/locale/pt";
import { APPROVAL_SCALE_BUINESSDAYS } from "environmentConfig";
import { useForm } from "hooks";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
    '> :not(:last-child)': {
      marginRight: '10px',
    }
  },
  item: {
    height: "auto",
    width: "auto",
    flexShrink: 1,
  },


  // custom fields styiles
  combo: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    gridGap: tokens.spacingHorizontalXXS,
  },
});

export default function DetailStep({
  index,
  approval,
  step,
  user,
  mandatories,
  optionals,
  guestToken
}) {

  const [btnDisable, setBtnDisable] = useState(false)

  const styles = useStyles();

  /* REDUX */
  const dispatch = useDispatch();

  /* HELPERS */
  const getStepStatusObject = (status) => {
    switch (status) {
      case "PENDING":
        return {
          title: "Pendente",
          backgroundClass: "bg-secondary",
          textClass: "text-secondary",
          alertClass: "alert-secondary",
          icon: "fas fa-clock",
        };
      case "RUNNING":
        return {
          title: "A Decorrer",
          backgroundClass: "bg-warning",
          textClass: "text-warning",
          alertClass: "alert-warning",
          icon: "fas fa-spinner fa-spin",
        };
      case "APPROVED":
        return {
          title: "Aprovada",
          backgroundClass: "bg-success",
          textClass: "text-success",
          alertClass: "alert-success",
          icon: "fas fa-check",
        };
      case "REJECTED":
        return {
          title: "Rejeitada",
          backgroundClass: "bg-danger",
          textClass: "text-danger",
          alertClass: "alert-danger",
          icon: "fas fa-times",
        };
      default:
        return {
          title: "Outro",
          backgroundClass: "bg-secondary",
          textClass: "text-secondary",
          alertClass: "alert-secondary",
          icon: "fas fa-clock",
        };
    }
  };


  // const { DialogComponent: ResponseDialog, openDialog, setContent } = useDialog({
  const [ResponseDialog, setResponseDialogValues, openDialog, closeDialog] = useDialog({
    inicialTitle: null,
    inicialContent: (<></>),
    inicialBottomActions: null
  });

  const handleApprovalResponseDialog = async (step, response) => {
    setBtnDisable(true)

    const content = <ApprovalResponseModalContent approval={approval} step={step} response={response} closeDialog={closeDialog} guestToken={guestToken} />
    setResponseDialogValues({ title: null, content })

    openDialog()
    setBtnDisable(false)
  };


  /* RENDER SECTION */

  return (
    <>
      {ResponseDialog}

      <li className="timeline-event">
        <div
          className={`timeline-event-icon ${getStepStatusObject(step.status).backgroundClass
            }`}
        >
          <i className={`${getStepStatusObject(step.status).icon}`}></i>
        </div>
        <div className="timeline-event-block block block-rounded shadow bg-white rounded">
          <div
            className={`block-header block-header-default ${getStepStatusObject(step.status).backgroundClass
              } d-flex flex-wrap justify-content-center align-items-stretch`}
          >
            <h3 className="block-title text-white text-wrap text-break">
              {"#" + (index + 1) + " " + step.name}{" "}
            </h3>

            {step.approvers.some((x) => {
              return (
                x?.user?.id === user?.user?.id &&
                x.decision === null &&
                step.status === "RUNNING"
              );
            }) && (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-success m-1 px-3"
                    onClick={() => {
                      handleApprovalResponseDialog(step, 1);
                    }}
                    disabled={btnDisable}
                  >
                    <i className="fa fa-check"></i>
                  </button>

                  <button
                    type="button"
                    className="btn btn-sm btn-danger m-1 px-3"
                    onClick={() => {
                      handleApprovalResponseDialog(step, 0);
                    }}
                    disabled={btnDisable}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              )}
          </div>

          <DaysToResolveComponent approval={approval} step={step} />

          {step.type === "FILLABLE"
            ? <StepApproverFillable approval={approval} step={step} optionals={optionals} />
            : <div className="block-content p-3">
              {mandatories.length !== 0 && (
                <React.Fragment>
                  <label>Aprovadores Obrigatórios</label>

                  <div className={styles.root}>
                    {mandatories.map((approver) => (
                      <div key={approver.id} className={styles.item}>
                        <DetailStepUser approver={approver} status={approval.status} />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )}

              {optionals.length !== 0 && (
                <React.Fragment>
                  <label>Aprovadores</label>
                  <div className={styles.root}>
                    {optionals.map((approver) => (
                      <div key={approver.id} className={styles.item}>
                        <DetailStepUser approver={approver} status={approval.status} />
                      </div>
                    ))}
                  </div>
                  <small>
                    {step.neededApprovers > 1
                      ? `São necessárias apenas ${step.neededApprovers} aprovações`
                      : `É necessária apenas ${step.neededApprovers} aprovação`}
                    {mandatories.length !== 0 && " além das obrigatórias"}
                  </small>
                </React.Fragment>
              )}
            </div>
          }
        </div>
      </li>
    </>
  );
}






const DaysToResolveComponent = ({ approval, step }) => {

  if (step.daysToResolve <= 0) {
    return (<></>)
  }

  if (step.status === "APPROVED" || step.status === "REJECTED")
    return (<></>)


  function calculateEndDate(initDate, numDays, buinessDays) {
    let endDate = new Date(initDate)

    if (buinessDays == false) {
      endDate.setDate(endDate.getDate() + numDays);
    }
    else {

      let count = 0;
      while (count < numDays) {
        endDate.setDate(endDate.getDate() + 1);
        if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
          count++;
        }
      }
    }

    return endDate
  }



  if (approval.status === "PENDING" || step.status === "PENDING") {
    return (
      <>
        <div
          className={`py-1 px-3 text-white bg-info`}
          role="alert"
        >
          <small>Vai ter <b>{step.daysToResolve} {step.daysToResolve == 1 ? "dia" : "dias"} {APPROVAL_SCALE_BUINESSDAYS && "úteis"}</b> para resolver esta etapa</small>
        </div>
      </>
    )
  }

  if (approval.status === "RUNNING" || step.status === "RUNNING") {

    // {moment(approver.knowledgeDate).format("LLL")}
    // {moment(user.createdAt).calendar()}
    const endDate = calculateEndDate(step.startDate, step.daysToResolve, APPROVAL_SCALE_BUINESSDAYS);

    // ja exiprou, mas tem sempre ate ao final da hora
    const nearEndDate = new Date() >= endDate


    return (
      <>
        <div
          className={`py-1 px-3 text-white ${nearEndDate ? "bg-danger" : "bg-warning"}`}
          role="alert"
        >

          {/* <small>Tém até <b>{moment(endDate).calendar()}</b> para resolver esta etapa</small> */}

          {nearEndDate
            ? <small>A etapa está a <b>expirar</b></small>
            : <small>Tém <b>{moment(endDate).fromNow(true)}</b> para resolver esta etapa</small>
          }

        </div>

      </>
    )
  }




  return (<></>)


}



const StepApproverFillable = ({ approval, step, optionals }) => {
  const styles = useStyles();


  const [Dialog, setDialogValues, openDialog, closeDialog] = useDialog({});

  const openFieldsModal = () => {

    const stepApproved = step.status == "APPROVED";

    const content = <>

      <div className="col-12">
        {step.approvalStepField?.length > 0 ? (
          <Table noNativeElements size="small"
          >
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Nome</TableHeaderCell>
                {!stepApproved && <TableHeaderCell>Tipo</TableHeaderCell>}
                {stepApproved && <TableHeaderCell>Valor</TableHeaderCell>}
                {/* <TableHeaderCell>Valores</TableHeaderCell> */}
              </TableRow>
            </TableHeader>
            <TableBody>
              {step.approvalStepField?.map((item, index) => (
                <TableRow key={item.name}>
                  <TableCell>{item.name}</TableCell>
                  {!stepApproved && <TableCell>{fieldTypeToHuman(item.type)}</TableCell>}
                  {stepApproved && <TableCell className="text-break text-wrap">{item.value ? item.value : "N/D"}</TableCell>}
                  {/* <TableCell>{item.values}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )

          : <span className="d-block text-warning">Sem campos definidos</span>
        }
      </div>

    </>

    const title = <h3>Campos de Etapa</h3>

    const bottomActions = <div className="mt-4 d-flex justify-content-center">
      <button className="mx-1 btn btn-secondary" onClick={closeDialog}>Fechar</button>
    </div>

    setDialogValues({ title, content, bottomActions })

    openDialog()
  }

  return <div className="block-content p-3">

    {Dialog}

    <label>Aprovadores</label>
    <div className={styles.root}>
      {optionals.map((approver) => (
        <div key={approver.id} className={styles.item}>
          <DetailStepUser approver={approver} status={approval.status} />
        </div>
      ))}
    </div>
    <small> É necessária apenas 1 aprovação</small>

    {/* <p className="mb-0 mt-1">
      Nº de Campos Preenchíveis: {step.approvalStepField.length}

      <Popover withArrow openOnHover size="small" positioning="below">
        <PopoverTrigger>
          <i className="mx-1 fa-solid fa-circle-info text-info"></i>
        </PopoverTrigger>

        <PopoverSurface className="bg-light">
          {step.approvalStepField?.length > 0
            ? <ul className="mb-0 px-4">
              {step.approvalStepField?.map((item, index) => <li>{item.name}</li>)}

            </ul>
            : <span className="d-block text-warning">Sem campos definidos</span>
          }
        </PopoverSurface>
      </Popover>


      <i className="mx-1 fa-solid fa-circle-info text-info"
        onClick={openFieldsModal}
      ></i>
    </p> */}


    <button className="btn btn-sm btn-alt-primary w-100 mt-3" style={{ fontSize: 12 }} onClick={openFieldsModal}>Ver Campos Preenchíveis</button>


  </div>
}





const ApprovalResponseModalContent = ({ step, response, closeDialog, guestToken, approval }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null)




  const { form, setForm } = useForm({
    observations: "",
    fields: [...step.approvalStepField],
  })

  const approved = response == 1;


  const handleApprovalResponse2 = async () => {


    setDisabled(true)
    setError(null)

    // if (guestToken) {
    //   dispatch(
    //     sendStepResponseGuest({
    //       stepId: step.id,
    //       responseValue: response,
    //       responseText: form.observations,
    //       responseFields: form.fields,
    //       approval,
    //       guestToken
    //     })
    //   ).then(() => {
    //     dispatch(getApprovalGuest({ approvalId: approval.id, guestToken}));
    //   });
    // }

    // else {
    //   dispatch(
    //     sendStepResponse({
    //       stepId : step.id,
    //       responseValue: response,
    //       responseText: form.observations,
    //       responseFields: form.fields,
    //     })
    //   ).then(() => {
    //     dispatch(getApproval(approval.id));
    //   });
    // }





    const res = guestToken
      ? await dispatch(sendStepResponseGuest({
        stepId: step.id,
        responseValue: response,
        responseText: form.observations,
        responseFields: form.fields,
        approval,
        guestToken
      }))

      : await dispatch(sendStepResponse({
        stepId: step.id,
        responseValue: response,
        responseText: form.observations,
        responseFields: form.fields,
      }))


    if (res.error) {
      console.log(res)
      setDisabled(false)
      setError(res.error.message ?? res.error.name ?? "Unknown error")
    }
    else {
      closeDialog()
      guestToken ? dispatch(getApprovalGuest({ approvalId: approval.id, guestToken })) : dispatch(getApproval(approval.id))
    }
  }
  const handleFieldValueChange = (value, index) => {
    let fields = form.fields.map((field, i) => {
      if (index === field.index) {
        return { ...field, value: value };
      } else {
        return { ...field };
      }
    });

    setForm({ ...form, fields: fields });
  };

  const renderCustomField = (field, disabled) => {
    if (field.type === "checkbox") {
      /*return (
        <select className="form-control" value={field.value} onChange={(e) => handleFieldValueChange(e, field.index)}>
          {field.values.split('|').map((value, index) => {
            return <option key={index} value={value}>{value}</option>
          })}
        </select>
      )*/
      return (
        <div className={styles.combo}>
          <label>{field.label}</label>
          <ul className={styles.tagsList}>
            {field.value !== '' &&
              field.value?.split("; ").map((option, i) => (
                <li key={option}>
                  <Button
                    size="small"
                    shape="circular"
                    appearance="primary"
                    iconPosition="after"
                  //onClick={() => onTagClick(option, i)}
                  >
                    {option}
                  </Button>
                </li>
              ))}
          </ul>
          <Combobox
            placeholder="Selecione uma opção"
            multiselect
            selectedOptions={
              field.value
                ? field.value?.split("; ")
                : []
            }
            onOptionSelect={(e, data) =>
              handleFieldValueChange(
                data.selectedOptions ? data.selectedOptions.join("; ") : "",
                field.index
              )
            }

            disabled={disabled}
          >
            {field.values?.split("|").map((value) => {
              return (
                <Option key={value} value={value}>
                  {value}
                </Option>
              );
            })}
          </Combobox>
        </div>
      );
    } else if (field.type === "text") {
      return (
        <input
          className="form-control"
          value={field.value}
          onChange={(e) => handleFieldValueChange(e.target.value, field.index)}
          disabled={disabled}
        />
      );
    } else if (field.type === "textarea") {
      return (
        <Textarea
          resize="vertical"
          className="form-control"
          rows={6}
          value={field.value}
          onChange={(e) => handleFieldValueChange(e.target.value, field.index)}
          disabled={disabled}

        />
      );
    } else if (field.type === "radio") {
      return (
        <RadioGroup
          layout="horizontal"
          value={field.value}
          className="flex flex-row flex-wrap"
          onChange={(_, data) =>
            handleFieldValueChange(data.value, field.index)
          }
          disabled={disabled}
        >
          {field.values.split("|").map((value, index) => {
            return <Radio key={index} label={value} value={value} />;
          })}
        </RadioGroup>
      );
    }
  };

  return <>
    <h2 className="text-center">{approved ? "Aceitar Etapa" : "Rejeitar Etapa"}</h2>

    {/* <p className="fw-bold mb-2">Ao responder a este fluxo, está responsabilizar-se pelo conteúdo do mesmo</p> */}

    <div className="form-group mb-4">
      <label className="form-label" >Pode adicionar observações à sua resposta</label>
      <textarea className="form-control" disabled={disabled} onChange={(e) => setForm({ ...form, observations: e.currentTarget.value })}></textarea>
    </div>

    {approved && step.type === "FILLABLE" &&
      <>
        <h4>Campos Preenchíveis</h4>
        <div className="">
          {form.fields.length > 0 && form.fields
            .sort((a, b) => a.index - b.index)
            .map((field, index) => {
              return (
                <div key={`field${index}`} className="form-group mb-4">
                  <label className="form-label">{field.name}</label>
                  <div className="input-group bg-white">
                    {renderCustomField(field, disabled)}
                  </div>
                </div>
              );
            })}
        </div>
      </>
    }

    {error !== null &&
      <div className="mt-2 ">
        <p className="text-danger">Ocorreu um erro a enviar os dados, Por favor tente novamente  </p>
      </div>
    }


    <div className="mt-4 d-flex justify-content-center">
      <button className={`mx-1 btn ${approved ? "btn-success" : "btn-danger"}`}
        onClick={handleApprovalResponse2} disabled={disabled}>{approved ? "Aceitar" : "Rejeitar"}</button>
      <button className="mx-1 btn btn-secondary" onClick={closeDialog} disabled={disabled}>Cancelar</button>
    </div>


    {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}
  </>
}