import React, { useState, useEffect, useRef } from 'react'

import { Avatar, Tooltip, } from "@fluentui/react-components";
import { Persona } from "@fluentui/react-persona";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiUrl } from "environmentConfig";
import {getAccessToken} from "utils/auth-header";

import {toggleLoading} from "layout/templateFunctions"

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css';

import moment from "moment";
import "moment/locale/pt";





function Chat({ approvalId, approvalStatus }) {

    const isDraft = approvalStatus === "DRAFT"
    const isRunning = approvalStatus === "RUNNING"
    const chatId = `chat_${approvalId}`

    const user = useSelector((root) => {
        return root.userReducer.user;
    });


    const [chatData, setChatData] = useState(null)
    const [lastId, setLastId] = useState(null)
    const [error, setError] = useState(null)

    const contentRef = useRef(null);
    const [currentScrollTop, setCurrentScrollTop] = useState(0)
    const [currentScrollHeight, setCurrentScrollHeight] = useState(0)

    useEffect(() => {
        if (isDraft)
            return

        if (contentRef.current) {
            if (messageSent === false) {
                const newScrollHeight = contentRef.current.scrollHeight;
                contentRef.current.scrollTop = newScrollHeight - currentScrollHeight + currentScrollTop;
            }
            else {
                contentRef.current.scrollTo({ top: contentRef.current.scrollHeight, behavior: "smooth" });
                setMessageSent(false)
            }
        }
    }, [chatData]);

    const [text, setText] = useState("")

    const getData = async () => {
        if (isDraft) return

        toggleLoading("state_loading", chatId)

        try {
            setCurrentScrollTop(contentRef.current.scrollTop);
            setCurrentScrollHeight(contentRef.current.scrollHeight);

            const response = await axios.get(`/approval/${approvalId}/conversation?lastId=${lastId}`)
            console.log(response)

            const recivedChatMessages = response.data.sort((a, b) => a.id - b.id);

            if (chatData == null) {
                setChatData(recivedChatMessages)
            }
            else {
                setChatData(prev => [...recivedChatMessages, ...prev])
            }

            if (recivedChatMessages.length > 0)
                setLastId(recivedChatMessages[0].id)
            else
                setLastId(-1)


            // Adjust scroll position to maintain the user's relative position

        } catch (error) {
            console.error(error)
            setError("error")
        }

        toggleLoading("state_normal", chatId)
    }

    useEffect(() => {
        if (isDraft) {
            return
        }

        getData()

        const eventSource = new EventSource(`${apiUrl}/approval/${approvalId}/conversation/events?token=${getAccessToken()}`);
        eventSource.onmessage = (event) => {
            const newMessage = JSON.parse(event.data);
            setChatData(prev => [...prev, newMessage])
        };

        return () => {
            eventSource.close();
        }

    }, [approvalStatus])


    const [sendingMessage, setSendingMessage] = useState(false)
    const [messageSent, setMessageSent] = useState(false)
    const [sendingMessageError, setSendingMessageError] = useState(null)
    const postMessage = async () => {
        if (isContentEmpty(text) == true)
            return

        setSendingMessage(true)
        setSendingMessageError(null)

        try {
            const response = await axios.post(`/approval/${approvalId}/conversation`, { text: text })
            console.log(response)
            if (response.status === 201) {
                setText("")
                // setChatData(prev => [...prev, response.data])
                setMessageSent(true)
            }

        } catch (error) {
            console.error(error)
            setSendingMessageError(error)
        }

        setSendingMessage(false)
    }


    const isContentEmpty = (text) => {
        // Create a new div element and set its innerHTML to the editor's content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = text;

        // Use innerText to get the text content without tags
        const textContent = tempDiv.innerText || tempDiv.textContent || '';

        // Check if the text content is only whitespace
        return !textContent.trim().length;
    };


    const renderSelf = (message) => {
        return <div className="d-flex justify-content-end">

            <div className="fs-sm d-inline-block fw-medium animated fadeIn bg-body-light border-3 px-3 py-2 me-2 mb-2 shadow-sm mw-100 border-end border-dark rounded-start" dangerouslySetInnerHTML={{ __html: message?.text }}>

            </div>

            <Tooltip
                content={
                    <Persona
                        textAlignment={"center"}
                        name={user?.name}
                        size={"medium"}
                        avatar={{
                            image: {
                                src: user?.profilePhoto,
                            },
                        }}
                        secondaryText={user?.email}
                        tertiaryText={<small className='text-muted'>{moment(message.createdAt).calendar()}</small>}
                    />
                }
            >
                <Avatar
                    size={"32"}
                    name={user?.name}
                    image={{ src: user?.profilePhoto }}
                />
            </Tooltip>


        </div>
    }
    const renderOthers = (message) => {
        return <div className="d-flex">
            <Tooltip
                content={
                    <Persona
                        textAlignment={"center"}
                        name={message?.user?.name}
                        size={"medium"}
                        avatar={{
                            image: {
                                src: message?.user?.profilePhoto,
                            },
                        }}
                        secondaryText={message?.user?.email}
                        tertiaryText={<small className='text-muted'>{moment(message.createdAt).calendar()}</small>}
                    />
                }
            >
                <Avatar
                    size={"32"}
                    name={message?.user?.name}
                    image={{ src: message?.user?.profilePhoto }}
                />
            </Tooltip>
            <div className="fs-sm d-inline-block fw-medium animated fadeIn bg-body-light border-3 px-3 py-2 ms-2 mb-2 shadow-sm mw-100 border-start border-dark rounded-end" dangerouslySetInnerHTML={{ __html: message?.text }}>

            </div>
        </div>
    }


    const renderLoadMore = () => {
        if (lastId === null)
            return <></>
        if (lastId === -1)
            return <small className='d-block text-center text-muted pb-2'>Não exitem mais comentários</small>

        return <small className='d-block text-center text-primary pb-2' role='button' onClick={getData}>Ver anteriores</small>
    }

    if (isDraft)
        return <></>
    // return <div className="block block-rounded block-bordered" id={chatId}>
    //     <div className="block-header block-header-default">
    //         <h3 className="block-title">Conversa</h3>
    //     </div>
    //     <div className="block-content ">
    //         <p className='text-info'>Aprovação ainda se encontra em rascunho</p>
    //     </div>

    // </div>



    return (
        <div className="block block-rounded block-bordered" id={chatId}>
            <div className="block-header block-header-default">
                <h3 className="block-title">Comentários</h3>
            </div>
            <div className="block-content" ref={contentRef} style={{ maxHeight: "30vh", overflowY: "auto", }}>
                {error != null &&
                    <p className='text-danger'>Ocurreu um erro a obter dados</p>
                }

                {error == null && chatData == null && <>
                    A Carregar ...
                </>}

                {chatData != null && chatData.length <= 0 && <p className='text-center'>
                    Não existem comentários
                </p>}

                {chatData != null && chatData.length > 0 && <>
                    {/* {renderLoadMore()} */}

                    {chatData.map(message => {
                        return <div id={`chat_${message.id}`} key={`chat_${message.id}`}>{message?.user?.id === user.id ? renderSelf(message) : renderOthers(message)}</div>
                    })}

                </>}
            </div>


            <div className="block-content p-0 pt-3">
                <div className="input-group">
                    <ReactQuill
                        theme='bubble'
                        bounds={`#main-container`}
                        className={`form-control ${sendingMessageError ? "is-invalid" : ""}`}
                        value={text}
                        placeholder="Escreva um mensagem ..."
                        modules={{
                            toolbar: [
                                [{ font: [] }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [{ color: [] }, { background: [] }],
                                [{ align: [] }],
                                // [("blockquote", "code-block")],

                                [{ list: "ordered" }, { list: "bullet" }],
                                // [{ script: "sub" }, { script: "super" }],
                                [{ indent: "-1" }, { indent: "+1" }],

                                [
                                    "link",
                                    // "image"
                                ],

                                // ["clean"],
                            ],
                        }}
                        onChange={(e) => { setText(e) }}
                    />

                    <button type="button" className="btn btn-secondary" disabled={sendingMessage} onClick={postMessage}>Enviar</button>
                </div>
            </div>

            {sendingMessageError &&
                <div className="block-content block-content-full block-content-sm bg-body-light fs-sm p-0">
                    <small className='d-block text-center text-danger'>Ocurreu um erro a enviar a Mensagem</small>
                </div>
            }


        </div>

    )
}

export default Chat