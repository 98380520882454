import { useEffect, useState } from "react";
import UserSwitcher from "./components/UserSwitcher";

const Header = (props) => {

  const [isOffline, setIsOffline] = useState(false)

  useEffect(() => {

    const handleOnline = () => { setIsOffline(false)};
    const handleOffline = () => { setIsOffline(true) };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);



  return (
    <header id="page-header">

      {isOffline &&
      <h4 className="bg-danger text-white text-center p-0 m-0">
        Offline
      </h4>
      }

      <div className="content-header">
        <div>
          <button
            type="button"
            className="btn btn-dual"
            data-toggle="layout"
            data-action="sidebar_toggle"
          >
            <i className="fa fa-fw fa-bars"></i>
          </button>
        </div>
        <UserSwitcher />
        {/* <div>
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn btn-alt-secondary"
              id="page-header-user-dropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-fw fa-user d-sm-none"></i>
              <span className="d-none d-sm-inline-block">
                <Persona
                  textAlignment={"center"}
                  avatar={{
                    image: {
                      src: user?.user?.profilePhoto,
                    },
                  }}
                  secondaryText={user?.user?.email}
                  name={user?.user?.name}
                  size={"medium"}
                />
              </span>
              <i className="fa fa-fw fa-angle-down ms-1 d-none d-sm-inline-block"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right p-0"
              aria-labelledby="page-header-user-dropdown"
            >
              <div className="bg-gd-primary fw-semibold text-white text-center p-3 d-flex flex-column">
                {user.user.name}
                <small className="text-white-50">{user.user.email}</small>
              </div>
              <div className="p-2">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    dispatch(userLogout()).then((x) => {
                      if (accounts.length) {
                        instance.logoutRedirect();
                      }
                    });
                  }}
                >
                  <i className="fa fa-fw fa-arrow-alt-circle-left me-1"></i>{" "}
                  Terminar Sessão
                </button>
              </div>
            </div>
          </div>
        </div>*/}
      </div>
      <div id="page-header-loader" className="overlay-header bg-header-dark">
        <div className="bg-white-10">
          <div className="content-header">
            <div className="w-100 text-center">
              <i className="fa fa-fw fa-sun fa-spin text-white"></i>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
