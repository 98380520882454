export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.access_token) {
    return { Authorization: "Bearer " + user.access_token };
  } else {
    return {};
  }
}


export function getAccessToken (){
  const user = JSON.parse(localStorage.getItem("user"));
  return (user && user.access_token) ? user.access_token : null
}