import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getApprovalsNeededAction } from "./approvals/store/approvalsSlice";
import { userHasPermission, PermissionsList } from "config/permissions";
import CardApprovalActionNeed from "./components/CardApprovalActionNeed";

const DashboardPage = () => {
  const dispatch = useDispatch();

  const [dashboardData, setDashboardData] = useState({});

  const user = useSelector((root) => {
    return root.userReducer;
  });

  const approvalsNeededAction = useSelector(({ approvalsModule }) => {
    return approvalsModule.approvalsReducer.approvalsNeededAction;
  });

  const [loadingNeededAction, setLoadingNeededAction] = useState(true);
  const [dataNeededAction, setDataNeededAction] = useState(
    approvalsNeededAction
  );

  useEffect(() => {
    axios.get("/reports/dashboard").then((e) => {
      setDashboardData(e.data);
    });
  }, []);

  useEffect(() => {
    dispatch(getApprovalsNeededAction()).then(() =>
      setLoadingNeededAction(false)
    );
  }, [dispatch]);

  useEffect(() => {
    setDataNeededAction(dataNeededAction);
  }, [approvalsNeededAction, dataNeededAction]);

  return (
    <React.Fragment>
      <div className="bg-body-light animated fadeIn">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              Bem-vindo, {user?.user?.name || "Utilizador"}
            </h1>
            <nav className="flex-sm-00-auto ml-sm-3">
              O que vamos aprovar hoje?
            </nav>
          </div>
        </div>
      </div>

      <div className="content animated fadeIn">
        {userHasPermission(
          user.user.profile.permissions,
          PermissionsList.APPROVAL_CREATE
        ) && (
          <Link to="/approvals/new" className="btn btn-primary mb-4">
            Criar nova aprovação
          </Link>
        )}
        <div className="row">
          <div className="col-md-6 col-xl-3">
            <div className="block block-rounded block-link-pop bg-gd-primary text-white">
              <div className="block-content block-content-full">
                <div className="d-flex align-items-center justify-content-between p-1">
                  <p className="mb-0">Aprovações Recebidas</p>
                  <p className="fs-lg fw-semibold mb-0">
                    {dashboardData.approvals_received}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3">
          <div className="block block-rounded block-link-pop bg-gd-secondary">
              <div className="block-content block-content-full">
                <div className="d-flex align-items-center justify-content-between p-1">
                  <p className=" mb-0">Aprovações Enviadas</p>
                  <p className="fs-lg fw-semibold mb-0">
                    {dashboardData.approvals_sent}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3">
          <div className="block block-rounded block-link-pop bg-success text-white">
              <div className="block-content block-content-full">
                <div className="d-flex align-items-center justify-content-between p-1">
                  <p className="mb-0">Aprovações Aprovadas</p>
                  <p className="fs-lg fw-semibold mb-0">
                    {dashboardData.approvals_approved}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xl-3">
          <div className="block block-rounded block-link-pop bg-danger text-white">
              <div className="block-content block-content-full">
                <div className="d-flex align-items-center justify-content-between p-1">
                  <p className="mb-0">Aprovações Rejeitadas</p>
                  <p className="fs-lg fw-semibold mb-0">
                    {dashboardData.approvals_rejected}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!loadingNeededAction &&
          approvalsNeededAction &&
          approvalsNeededAction.length !== 0 && (
            <React.Fragment>
              <h2 className="content-heading animated fadeIn">
                Necessitam da sua decisão
              </h2>

              <div className="row row-deck animated fadeIn">
                {approvalsNeededAction.map((approval) => {
                  return (
                    <div className="col-md-6 col-xl-4" key={approval.id}>
                      <CardApprovalActionNeed approval={approval} />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;
