import React from 'react'
import { TooltipHost, DirectionalHint, TooltipDelay } from "@fluentui/react";



function ScalableTooltip() {
    return (
        <TooltipHost
            content={"Um fluxo escalável é concluído quando uma etapa termina com sucesso. No caso de uma etapa ser rejeitada, a proxima etapa passar a decorrer."}
            delay={TooltipDelay.zero}
            closeDelay={500}
            directionalHint={DirectionalHint.rightCenter}
        >
            <i className="fa fa-lg fa-fw fa-info-circle m-1 text-muted"></i>
        </TooltipHost>
    )
}

export default ScalableTooltip