import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const getApprovalTemplate = createAsyncThunk(
  "approvals/templates/getTemplate",
  async (approvalTemplateId) => {
    const response = await axios.get(`/approval-template/${approvalTemplateId}`);
    const data = await response.data.approvalTemplate;

    return data === undefined ? null : data;
  }
);

export const deleteApprovalTemplate = createAsyncThunk(
  "approvals/templates/deleteTemplate",
  async (approvalTemplateId) => {
    const response = await axios.delete(`/approval-template/${approvalTemplateId}`);
    const data = await response.data;

    return data === undefined ? null : data;
  }
);

export const saveApprovalTemplate = createAsyncThunk(
  "approvals/templates/saveTemplate",
  async (approvalTemplateData) => {
    try {
      console.log(approvalTemplateData);
        const response = !approvalTemplateData.id 
        ? await axios.post(`/approval-template`, approvalTemplateData)
        : await axios.put(`/approval-template/${approvalTemplateData.id}`,approvalTemplateData);
        console.log(response);
        const data = await response.data;
        return data;
    } catch (error) {
      throw error;
    }
  }
);

const approvalTemplateSlice = createSlice({
  name: "approvalTemplate",
  initialState: null,
  reducers: {
    resetApprovalTemplate: () => null,
    newApprovalTemplate: {
      reducer: (state, action) => action.payload,
      prepare: (event) => ({
        payload: {
          templateTitle: "",
          public: false,
          templateSteps: [],
          templateFields: [],
          templateConnoisseurs: [],
          sharedUsers: [],
          user: {},
          companies: []
        },
      }),
    },
  },
  extraReducers: {
    [getApprovalTemplate.fulfilled]: (state, action) => action.payload,
    [getApprovalTemplate.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
    [deleteApprovalTemplate.fulfilled]: (state, action) => {

    },
    [deleteApprovalTemplate.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
    [saveApprovalTemplate.fulfilled]: (state, action) => action.payload,
    [saveApprovalTemplate.rejected]: (state, action) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: action.error.message,
        width: "800px",
      });
    },
  },
});

export const { newApprovalTemplate, resetApprovalTemplate } = approvalTemplateSlice.actions;

export default approvalTemplateSlice.reducer;
